import styled from '@emotion/styled'
import { isNumber } from 'lodash'
import { useGetUserCoin, useGetUserCoinBenefitYear } from '../services/user/user-query'
import Card from './common/Card'
import Image from './common/Image'
import Text from './common/Text'
import iconCoin from '../assets/images/icons-coin-green-32-px.svg'
import { numberWithCommas, useRouter } from '../utils/helper'
import color from '../constant/colors'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const CardPoint = styled(Card)`
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 16px;
`
const FlexRowBaseLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
`

const ImageStyle = styled(Image)`
  object-fit: none;
  margin-left: 8px;
  align-self: center;
`

const TextPaddingLeft = styled(Text)`
  padding-left: 8px;
`

const TextCenter = styled(Text)`
  text-align: center;
`
type PointCardProps = {
  benefitYearId?: number
}

export const PointCardBenefit = (props: PointCardProps) => {
  // const { query } = useRouter()
  // const { benefitYearId = query?.benefityearId } = props
  // const { data: userCoinById } = useGetUserCoinBenefitYear(benefitYearId)
  const { t } = useTranslation()
  const { data: userCoinById } = useGetUserCoin()

  return (
    <CardPoint>
      <FlexRowBaseLine>
        <ImageStyle width={32} height={32} imageName={'iconCoin'} />

        <TextPaddingLeft type="Bold" size={32} color={color.PRIMARY}>
          {' '}
          {userCoinById && isNumber(userCoinById) ? numberWithCommas(Number(userCoinById)) : 0}{' '}
        </TextPaddingLeft>
        <TextPaddingLeft type="SemiBold" size={18}>
          {t('app.coin')}
        </TextPaddingLeft>
      </FlexRowBaseLine>
      <TextCenter type="SemiBold" size={18}>
        ({t('app.ConvertedToMoney')}{' '}
        {userCoinById && isNumber(userCoinById) ? numberWithCommas(Number(userCoinById)) : 0} ฿)
      </TextCenter>
    </CardPoint>
  )
}

export const PointCard = (props: PointCardProps) => {
  // const { data: enrollmentPeriod } = useGetEnrollmentPeriod()
  // const { benefitYearId = 0 } = enrollmentPeriod || {}
  // const { data: userCoin } = useGetUserCoinBenefitYear(benefitYearId)
  const { t } = useTranslation()
  const { data: userCoin } = useGetUserCoin()

  return (
    <CardPoint>
      <FlexRowBaseLine>
        <ImageStyle width={32} height={32} imageName={'iconCoin'} />

        <TextPaddingLeft type="Bold" size={32} color={color.PRIMARY}>
          {' '}
          {userCoin && isNumber(userCoin) ? numberWithCommas(Number(userCoin)) : 0}{' '}
        </TextPaddingLeft>
        <TextPaddingLeft type="SemiBold" size={18}>
          {t('app.coin')}
        </TextPaddingLeft>
      </FlexRowBaseLine>
      <TextCenter type="SemiBold" size={18}>
        (คิดเป็นเงิน {userCoin && isNumber(userCoin) ? numberWithCommas(Number(userCoin)) : 0} ฿)
      </TextCenter>
    </CardPoint>
  )
}
