import { useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { useRouter } from '../../utils/helper'
import { mobile, iphoneXLandscape } from '../../utils/responsive-helper'
import { setToken } from '../../utils/helper'
import { useGetUser, useGetToken } from '../../services/user/user-query'
import Loading from '../../components/common/Loading'

const Layout = styled.div`
  display: flex;
  height: 100vh;

  ${mobile}, ${iphoneXLandscape} {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`

const AuthCallback = (props: any) => {
  const { data: user } = useGetUser()
  const { query } = useRouter<{ samlAuth?: any }>()
  const { mutate } = useGetToken()

  useEffect(() => {
    if (query.samlAuth === 'true') {
      mutate({
        onSuccess: (data: any) => {
        }
      }, {
        onSuccess: (data: any) => {
          setToken(data).then((res) => {
            window.location.reload()
          })
        }
      })
    }
    // handleSetToken(query.token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.samlAuth])

  // const getUser = useCallback(() => {
  //   if (user === undefined) {
  //     window.location.reload()
  //   }
  // }, [user])

  return (
    <Layout>
      <Loading loading={true} >
        isLoading
      </Loading>
    </Layout>
  )
}

export default AuthCallback
