import { useCallback, useState } from 'react'

export const useVisibility = () => {
  const [visible, setVisible] = useState(false)

  const show = useCallback(() => {
    setVisible(true)
  }, [])
  const hide = useCallback(() => {
    setVisible(false)
  }, [])

  const action = {
    show,
    hide,
  }
  return [visible, action] as [boolean, typeof action]
}
