import React, { CSSProperties } from 'react'
import styled from '@emotion/styled'
import color from '../../constant/colors'
import Text from '../../components/common/Text'

const LayoutTag = styled.div<{ backgroundColor: string }>`
  width: 111px;
  height: 25px;
  text-align: center;
  padding: 4px 16px 2px 18px;
  border-radius: 4px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`

type TagProps = {
  label: string
  // type: 'corporate' | 'digital'
  type: 'NORMAL' | 'INFORM' | 'QUIZ' | 'VOTE' | 'REGISTER'
  fontSize: number
  style?: CSSProperties
}

const Tag = (props: TagProps) => {
  const { label, type, fontSize, ...restProps } = props
  // const backgroundImage =
  //   type === 'NORMAL'
  //     ? 'linear-gradient(282deg, #0e2f91 5%, #0c76d4 98%)'
  //     : 'linear-gradient(103deg, #f52984 5%, #9227a7 98%)'

  let backgroundColor = '#363640'
  if (type === 'INFORM') {
    backgroundColor = '#b1c800'
  } else if (type === 'QUIZ') {
    backgroundColor = '#f52984'
  } else if (type === 'VOTE') {
    backgroundColor = '#f0202f'
  } else if (type === 'REGISTER') {
    backgroundColor = '#005da7'
  }

  return (
    <LayoutTag backgroundColor={backgroundColor} {...restProps}>
      <Text size={fontSize} type={'Bold'} color={color.WHITE}>
        {label}
      </Text>
    </LayoutTag>
  )
}

export default Tag
