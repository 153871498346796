import styled from '@emotion/styled'
import color from '../constant/colors'

const DivStyled = styled.div`
  width: 100%;
  white-space: normal;
  word-wrap: break-word;
  font-family: NotoSansThai-Medium;
  font-size: 20px;
  font-weight: 300;
  color: #183028;

  * {
    width: inherit !important;
  }

  /* .table-bordered {
    th,
    td {
      border: 1px solid $gray-300 !important;
    }
  }

  .table-bordered {
    border: 1px solid ${color.FONT_BLACK};

    th,
    td {
      border: 1px solid ${color.FONT_BLACK};
      padding: 8px;
    }

    thead {
      th,
      td {
        border-bottom-width: (2 * 1px);
      }
    }
  } */
`

type HtmlWithStyledProps = {
  html: string
  className?: string
}
const HtmlWithStyled = (props: HtmlWithStyledProps) => {
  const { html, className } = props
  return (
    <DivStyled className={`html-styled ${className}`} dangerouslySetInnerHTML={{ __html: html }} />
  )
}
export default HtmlWithStyled
