import { ReactNode } from 'react'
import styled from '@emotion/styled'
import Text from '../../../components/common/Text'
import Image, { ImageNameKeys } from '../../../components/common/Image'
import Card from '../../../components/common/Card'
import color from '../../../constant/colors'
import { mobile } from '../../../utils/responsive-helper'
import { isScg } from '../../../utils/helper'

const Row = styled.div`
  display: flex;
  flex-flow: row;
`

const Column = styled.div`
  display: flex;
  flex-flow: column;
`

const LayoutCard = styled(Card)`
  padding: 32px;
  margin-bottom: 24px;
  ${mobile} {
    padding: 8px;
  }
  .icon-enroll {
    margin-right: 16px;
  }
`

const LayoutContentHeader = styled(Row)`
  margin-bottom: 16px;
`

const LayoutContentFamily = styled(Row)`
  margin-top: 16px;
`

type BenefitsCardProps = {
  icon?: ImageNameKeys
  title?: string
  desc?: string
  isDesc?: boolean
  children: ReactNode
  widthIcon?: string
  heightIcon?: string
}

const BenefitsCard = (props: BenefitsCardProps) => {
  const { icon, title, desc, children, isDesc = true, widthIcon, heightIcon } = props
  // const { data: benefit, isLoading } = useGetBenefitsByCategory(query.benefitsCategoryId)

  return (
    <LayoutCard style={{ backgroundColor: isScg === true ? color.PRIMARY : '' }}>
      <LayoutContentHeader>
        {title && (
          <>
            <div className="icon-enroll">
              {icon && (
                <Image
                  imageName={icon}
                  width={widthIcon || 32}
                  height={heightIcon || 32}
                  style={{ objectFit: isScg ? 'none' : 'cover', fill: color.WHITE }}
                />
              )}
            </div>
            <Column>
              <Text size={24} type={'Bold'} color={isScg === true ? color.WHITE : color.FONT_BLACK}>
                {title}
              </Text>
              {isDesc && (
                <Text
                  size={22}
                  type={'Medium'}
                  color={isScg === true ? color.WHITE : color.FONT_LIGHT_GRAY}
                >
                  {desc}
                </Text>
              )}
            </Column>
          </>
        )}
      </LayoutContentHeader>

      {children}
    </LayoutCard>
  )
}

export default BenefitsCard
