import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'

import Text from '../../components/common/Text'
import Comment from '../../components/Comment'
import ImageSlider from '../../components/common/ImageSlider'
import {
  useGetNewsDetail,
  useReadNewsDetail,
  useSentQuizAnswer,
  useVote,
} from '../../services/digital-news/digital-news-query'
import color from '../../constant/colors'
import Tag from '../../components/common/Tag'
import Image from '../../components/common/Image'
import coinsIcon from '../../assets/images/icon-coins.svg'
import Button from '../../components/common/Button'
import { useScreen, mobile } from '../../utils/responsive-helper'
import FooterContent from '../../components/FooterContent'
import { useGetUser } from '../../services/user/user-query'
import { useRouter } from '../../utils/helper'
import ImageInfographicCoinSuccess from '../../assets/images/base/infographic-coin-success.svg'
import ImageInfographicQuizFalse from '../../assets/images/base/infographic-quiz-false.svg'
import Modal from '../../components/common/Modal'
import { useTranslation } from 'react-i18next'
import Radio from '../../components/common/Radio'
import Breadcrumb, { ItemProps } from '../../components/common/Breadcrumb'
import Loading from '../../components/common/Loading'
import config from '../../config'
import { TypeNews } from '../../services/digital-news/digital-news-types'
import NewsItems from './NewsItems'

dayjs.locale('th')

const HeaderText = styled(Text)`
  margin-top: 24px;
  margin-bottom: 16px;
`
const BodyLayout = styled.div<{ screen: any }>`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  ${mobile} {
    iframe {
      width: 100%;
    }
  }
`
const ImageBanner = styled(Image)`
  width: 100%;
  height: 500px;

  ${mobile} {
    height: 300px;
  }
`
const CommentLayout = styled.div`
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  padding: 32px;
`
const CommentContent = styled.div`
  background: white;
  padding-top: 32px;
  max-width: 752px;
  margin: 0 auto;
`
const NewsLayout = styled.div`
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  .image-slider {
    width: 100%;
    height: 420px;
    object-fit: contain;
  }
`
const NewsContent = styled.div`
  background: white;
  padding-top: 32px;
  border-radius: 8px 8px 0px 0px;
  transform: translateY(-40px);
  .width-content {
    max-width: 752px;
    margin: 0 auto;
  }

  ${mobile} {
    padding: 32px 16px 0px;
  }
`
const UpperContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  padding-bottom: 20px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${color.BORDER};
`
const CalendarGroup = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`
const IconImage = styled(Image)`
  width: 16px;
  height: 15px;
  margin-left: 16px;
`
const ReadButton = styled(Button)`
  width: 267px;
`
const CoinSuccessImagePanel = styled.div`
  text-align: center;
  margin-bottom: 64px;
`
const ModalButton = styled(Button)`
  margin: 32px;
`
const ModalButtonPanel = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
`
const CenteredText = styled(Text)`
  text-align: center;
`
const ResponseModal = styled(Modal)`
  min-width: calc(40vw);
  max-width: calc(90vw);
`

const ModalContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ButtonStyle = styled(Button)`
  width: 100%;
`

const TitleItem = styled.div`
  font-weight: bold;
  padding-left: 10px;
`
const ContntTextSuccessStyle = styled.div`
  display: block;
  text-align: center;
  padding-top: 25px;
`

const NewsDetail = () => {
  const [radioValue, setRadioValue] = useState('')
  const [radioArrValue, setRadioArrValue] = useState([] as any)
  const { data: user } = useGetUser()
  const { userBudget } = user || {}
  const { userId } = userBudget || {}
  const { query } = useRouter<{ newsId: string }>()
  const { data, isLoading } = useGetNewsDetail({ id: query.newsId, userId: userId })
  const { digitalNews, isInform } = data || {}
  const screen = useScreen()
  const { t } = useTranslation()
  const { isMobile } = screen
  const thaiNewsDate = useMemo(() => {
    return `${dayjs(data?.digitalNews?.createDate).format('DD MMM')} ${Number(dayjs(data?.digitalNews?.createDate).format(`YYYY`)) + 543
      }`
  }, [data?.digitalNews?.createDate])
  const NewsDate = useMemo(() => {
    return `${dayjs(data?.digitalNews?.createDate).format('DD MMM')} ${Number(dayjs(data?.digitalNews?.createDate).format(`YYYY`))}`
  }, [data?.digitalNews?.createDate])
  const [modalVisible, setModalVisible] = useState(false)
  const [isSuccess, setIsSuccess] = useState(true)
  const [modalVisibleFaild, setModalVisibleFaild] = useState(false)
  const [modalJoin, setModalJoin] = useState(false)
  const [isInputComment, setIsInputComment] = useState(false)

  const responseModalData = useMemo(() => {
    if (data?.digitalNews.type === TypeNews.QUIZ || data?.digitalNews.type === TypeNews.VOTE) {
      return {
        image: ImageInfographicCoinSuccess,
        text: t('newsDetail.modalTextAnswer'),
        buttonText: t('newsDetail.modalButtonText'),
      }
    } else {
      return {
        image: ImageInfographicCoinSuccess,
        text: t('newsDetail.modalTextNoCoin'),
        buttonText: t('newsDetail.modalButtonText'),
      }
    }
  }, [t, data?.digitalNews.type, isSuccess])

  const responseModalDataFaild = useMemo(() => {
    if (data?.digitalNews.type === TypeNews.QUIZ) {
      return {
        image: ImageInfographicQuizFalse,
        title: t('newsDetail.modalTextFaildQuiz'),
        description: '',
        buttonText: t('newsDetail.modalButtonText'),
      }
    } else {
      return {
        image: ImageInfographicQuizFalse,
        title: t('newsDetail.modalTextFaildVote'),
        description: t('newsDetail.modalTextFaildVoteSecond'),
        buttonText: t('newsDetail.modalButtonText'),
      }
    }
  }, [t, data?.digitalNews.type, isSuccess])

  const onModalOk = useCallback(() => {
    setModalVisible(false)
  }, [])

  useEffect(() => {
    setRadioValue('')
  }, [])

  // useEffect(() => {
  //   if (data?.digitalNews.type === 'INFORM') {
  //     setTimeout(function () {
  //       if (data.isInform === false) {
  //         onActionClick()
  //         setModalVisible(true)
  //       }
  //     }, 3000)
  //   }
  // }, [data])

  const { mutate: read } = useReadNewsDetail()
  const { mutate: answerQuiz } = useSentQuizAnswer()
  const { mutate: vote } = useVote()

  const canAction = useMemo(() => {
    return !isInform
  }, [isInform])

  const onSuccess = useCallback(
    (response) => {
      setIsSuccess(true)
      setModalVisible(true)
    },
    [data],
  )

  const overideBreadCrumb = useCallback(
    (paths: ItemProps[]) => {
      paths[1] = { ...paths[1], label: digitalNews?.type || '' }
      return paths
    },
    [digitalNews],
  )
  const onHandleInputComment = useCallback((isShow: boolean) => {
    setIsInputComment(isShow)
  }, [])
  const onActionClick = useCallback(() => {
    const { type, digitalNewsQuizList, digitalNewsVoteList } = digitalNews || {}
    if (type === TypeNews.QUIZ) {
      if (radioArrValue.filter(Boolean).length === digitalNewsQuizList.length && digitalNews) {
        answerQuiz(
          {
            userId: userId,
            digitalNewsId: digitalNews.id.toString(),
            id: radioArrValue,
          },
          { onSuccess },
        )
      } else {
        setModalVisibleFaild(true)
      }
    } else if (type === TypeNews.VOTE) {
      if (radioArrValue.filter(Boolean).length === digitalNewsVoteList.length && digitalNews) {
        vote(
          {
            digitalNewsId: digitalNews.id.toString(),
            userId: userId,
            voteIds: radioArrValue,
          },
          { onSuccess },
        )
      }
      else {
        setModalVisibleFaild(true)
      }
    } else if (type === TypeNews.INFORM) {
      digitalNews &&
        read(
          {
            userId: userId,
            digitalNewsId: digitalNews.id.toString(),
            subType: digitalNews.subType,
          },
          { onSuccess },
        )
    } else if (type === TypeNews.REGISTER) {
      if (digitalNews) {
        digitalNews &&
          read(
            {
              userId: userId,
              digitalNewsId: digitalNews.id.toString(),
              subType: digitalNews.subType,
            },
            { onSuccess },
          )
        setModalJoin(false)
      } else {
        setModalJoin(true)
      }
    }
  }, [digitalNews, answerQuiz, userId, radioValue, onSuccess, vote, read, radioArrValue])

  // const imagesForSlider = useMemo(() => {
  //   return digitalNews?.digitalNewsHeaderImagesList?.map((obj: any) => ({
  //     imagePath: `${config.apiHost}${obj.imagePath}`,
  //   }))
  // }, [digitalNews])

  // const radioContent = useMemo(() => {
  //   const { type, digitalNewsQuizList = [], digitalNewsVoteList = [] } = digitalNews || {}
  //   let options: BaseOptionType[] = []
  //   if (type === 'QUIZ') {
  //     options = digitalNewsQuizList.map((obj: { id: number; quizOption: string }) => ({
  //       label: obj.quizOption,
  //       value: obj.id,
  //     }))
  //     return <Radio options={options} value={radioValue} setValue={setRadioValue} />

  //   }
  // }, [digitalNews, radioValue])

  const buttonText = useMemo(() => {
    const { type } = digitalNews || {}
    if (type === 'QUIZ') {
      return t('newsDetail.sentAnswer')
    } else if (type === 'VOTE') {
      return t('newsDetail.vote')
    } else if (type === 'INFORM') {
      return t('newsDetail.readButton')
    } else if (type === 'REGISTER') {
      return t('newsDetail.register')
    }
  }, [digitalNews, t])

  // useEffect(() => {
  //   if (data?.digitalNews.type === 'INFORM') {
  //     setTimeout(function () {
  //       if (data.isInform === false) {
  //         onActionClick()
  //         setModalVisible(true)
  //       }
  //     }, 3000)
  //   }
  // }, [data, onActionClick])

  function handleSelectRadio(value: any, index: any) {
    let selectedCopy: any[] = []
    selectedCopy = [...radioArrValue];
    selectedCopy[index] = value;
    setRadioArrValue(selectedCopy);
  }

  return (
    <Loading loading={isLoading}>
      {/* <Breadcrumb style={{ marginTop: 24 }} overide={overideBreadCrumb} /> */}
      {/* <HeaderText size={32} type="Bold">
        {digitalNews?.title || '-'}
      </HeaderText> */}
      {digitalNews && (
        <BodyLayout screen={screen}>
          <NewsLayout>
            {/* <ImageSlider
              images={imagesForSlider}
              status="Detail"
            /> */}
            <ImageBanner src={digitalNews?.thumbnailPath?.indexOf('http') > -1 ? digitalNews?.thumbnailPath : `${config.apiHost}${digitalNews?.thumbnailPath}`} />
            <NewsContent>
              <div className='width-content'>
                <UpperContent>
                  {/* <Tag label={digitalNews.type} type={digitalNews.type} fontSize={11.6} /> */}
                  {/* <IconImage imageName="iconCoinsGold" /> */}
                  {/* <FlexChild /> */}
                  <Text size={24} type="Bold">{digitalNews?.title}</Text>
                  <CalendarGroup>
                    <Image imageName='calendarIcon' width={24} height={24} />
                    <Text size={16} color={color.FONT_LIGHT_GRAY}>
                      {NewsDate}
                    </Text>
                  </CalendarGroup>
                </UpperContent>
                <div dangerouslySetInnerHTML={{ __html: digitalNews.content }} />
                {/* {radioContent} */}
                {
                  digitalNews?.digitalNewsVoteList?.map((obj: any, index: any) => {
                    let content = obj?.content
                    let options: BaseOptionType[] = []
                    options = obj?.digitalNewsChoiceVote?.map((obj: { id: number, voteOption: string, voteScore: number }) => ({
                      label: obj.voteOption,
                      value: obj.id,
                      score: obj.voteScore
                    }))

                    return (
                      <div style={{ paddingBottom: 8 }}>
                        {!isInform && <TitleItem dangerouslySetInnerHTML={{ __html: content }} />}
                        {
                          isInform ? <div>
                            <ContntTextSuccessStyle>
                              <Text size={isMobile ? 18 : 22} type='Bold'>{t('newsDetail.answerSuccessNews')}</Text>
                            </ContntTextSuccessStyle>
                            {/* {
                              options.map((obj: any) => {
                                return <NewsItems quizItems={obj} />
                              })
                            } */}
                          </div> : <Radio options={options} value={radioArrValue[index]} setValue={e => handleSelectRadio(e, index)} />
                        }

                      </div>
                    )
                  })
                }

                {
                  digitalNews?.digitalNewsQuizList?.map((obj: any, index: any) => {
                    let content = obj?.content
                    let options: BaseOptionType[] = []
                    options = obj?.digitalNewsQuizItems?.map((obj: { id: number, quizOption: string, quizScore: number }) => ({
                      label: obj.quizOption,
                      value: obj.id,
                      score: obj.quizScore
                    }))

                    return (
                      <div>
                        {!isInform && <TitleItem dangerouslySetInnerHTML={{ __html: content }} />}
                        {
                          isInform ? <div>
                            <ContntTextSuccessStyle>
                              <Text size={isMobile ? 18 : 22} type='Bold'>{t('newsDetail.answerSuccessNews')}</Text>
                            </ContntTextSuccessStyle>
                            {/* {
                              options.map((obj: any) => {
                                return <NewsItems quizItems={obj} />
                              })
                            } */}
                          </div> : <Radio options={options} value={radioArrValue[index]} setValue={e => handleSelectRadio(e, index)} />
                        }

                      </div>
                    )
                  })
                }


              </div>
            </NewsContent>
          </NewsLayout>
          {!isInform ? (
            <CommentLayout>
              <CommentContent>
                {/* <Comment userId={userId!!} digitalNewsId={digitalNews.id} isInputComment={isInputComment} /> */}
                {digitalNews?.type === 'REGISTER' ? (
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    columnGap: 16,
                    padding: '16px 24px',
                    width: '100%'
                  }} >
                    <ReadButton onClick={() => setModalJoin(true)} disabled={!canAction}>
                      {buttonText}
                    </ReadButton>
                    {!isInputComment && (<ReadButton onClick={() => onHandleInputComment(true)} style={{ width: '100%' }}>
                      {t('comment.submitButton')}
                    </ReadButton>)}
                  </div>

                ) : (
                  !isInputComment && (
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      columnGap: 16,
                      padding: '16px 24px',
                      width: '100%'
                    }} >
                      {
                        <ReadButton background={color.WHITE} disabled={isInform} fontColor={color.PRIMARY} onClick={onActionClick} style={{ borderColor: color.PRIMARY, width: '100%' }}>
                          {buttonText}
                        </ReadButton>
                      }

                      {/* <ReadButton onClick={() => onHandleInputComment(true)} style={{ width: '100%' }}>
                      {t('comment.submitButton')}
                    </ReadButton> */}
                    </div>
                  )
                )}
              </CommentContent>
            </CommentLayout>)
            :
            null
          }
        </BodyLayout>
      )
      }

      {/* <FooterContent
        style={{
          height: 80,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: 752,
          margin: '0 auto',
          width: '100%',
        }}
      >

      </FooterContent> */}

      <ResponseModal visible={modalVisible} onCancel={onModalOk} hideFooter>
        <div>
          <CoinSuccessImagePanel>
            <Image src={responseModalData.image} />
          </CoinSuccessImagePanel>
          <CenteredText size={30} type="Bold">
            {digitalNews?.type === 'REGISTER' ? 'คุณได้เข้าร่วมแล้ว' : responseModalData.text}
          </CenteredText>
          <ModalButtonPanel>
            <ModalButton onClick={onModalOk}>
              <Text size={18} color="white">
                {responseModalData.buttonText}
              </Text>
            </ModalButton>
          </ModalButtonPanel>
        </div>
      </ResponseModal>

      <Modal visible={modalJoin} onCancel={() => setModalJoin(false)} hideFooter>
        <ModalContainer>
          <Text> {digitalNews?.title}</Text>
          <div style={{ display: 'flex', margin: '50px 0 0 0' }}>
            <Button
              onClick={() => setModalJoin(false)}
              style={{
                background: 'transparent',
                marginRight: 8,
                color: color.PRIMARY,
                borderColor: color.PRIMARY,
              }}
            >
              <Text size={20} type="SemiBold" color={color.FONT_BLACK}>
                ยกเลิก
              </Text>
            </Button>
            <Button onClick={onActionClick}>เข้าร่วม</Button>
          </div>
        </ModalContainer>
      </Modal>

      <Modal visible={modalVisibleFaild} onCancel={() => setModalVisibleFaild(false)} hideFooter>
        <ModalContainer>
          <Text size={30} type="Bold">
            {responseModalDataFaild.title}
          </Text>
          <Text>{responseModalDataFaild.description}</Text>
          <ModalButton onClick={() => setModalVisibleFaild(false)}>ปิด</ModalButton>
        </ModalContainer>
      </Modal>
    </Loading >
  )
}

export default NewsDetail
