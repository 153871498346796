import Breadcrumb from '../../components/common/Breadcrumb'
import styled from '@emotion/styled'
import EClaimForm from './EClaimForm'
import { useRouter } from '../../utils/helper'
import { useGetEClaimById, useGetClaimById } from '../../services/e-claim/e-claim-query'
import EClaimInfo from './EClaimInfo'

import Loading from '../../components/common/Loading'
import { eClaimDetail, enrollment, home } from '../../constant/path'
import { mobile, useScreen } from '../../utils/responsive-helper'
import { useMemo } from 'react'

const BreadcrumbContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  
  ${mobile} {
    margin: 24px 16px;
  }
`

const EClaimDetail = () => {
  const { query } = useRouter<{ eClaimId: number, relativeId: number }>()
  const { isMobile } = useScreen()
  const { data: eClaimItem } = useGetEClaimById({ claimId: query.eClaimId })
  const { data: claimItem, isLoading } = useGetClaimById({ claimId: query.eClaimId })

  const initialValue = useMemo(() => {
    return {
      relativeId: '0',
      amount: claimItem?.defaultValue,
      subClaimId: claimItem?.id === 19 ? 3 : undefined
    }
  }, [claimItem])

  return (
    <Loading loading={isLoading}>
      <EClaimForm claimItem={claimItem} eClaimItem={eClaimItem} initialValues={initialValue} allpayStatus={false} relativeId={Number(query.relativeId)} />
    </Loading>
  )
}

export default EClaimDetail
