import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import CategoryGroup from '../../components/common/CategoryGroup'
import Text from '../../components/common/Text'
import Image from '../../components/common/Image'
import Modal from '../../components/common/Modal'
import Button from '../../components/common/Button'
import color from '../../constant/colors'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import MaskImage from '../../assets/images/base/mask@2x.jpg'
import { useGetEnrollmentPeriod } from '../../services/enrollment/enrollment-query'
import { tablet } from '../../utils/responsive-helper'
import { useGetClaimType } from '../../services/e-claim/e-claim-query'
import { useAcceptConsent, useGetUser } from '../../services/user/user-query'

import config from '../../config'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  border: 0.5px solid #DDDDDD;
  background: white;
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom:16px;
  user-select: none;
  :hover {
    cursor: pointer;
  }

  ${tablet} {
    width: 100%;
  }
`
const CustomImage = styled(Image)`
  width: 22px;
  height: 22px;
`

const FooterSubmitInsuranceModal = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  padding: 24px;
`

const BenefitSelectButton = styled(Button)`
  width: min-content;
  border-color: ${color.PRIMARY};
  min-width: 165px;
`

const ContentSubmitInsuranceModal = styled.div`
  padding: 60px 0px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`

type ItemProps = {
  src: string
  label: string
  url: string
  type?: string
  description: string
  isSetPrivacy: boolean
}

const Item = (props: ItemProps) => {
  const { src, label, url, type, isSetPrivacy, description, ...rest } = props
  const history = useHistory()
  const { data, isLoading } = useGetUser()
  const { mutate } = useAcceptConsent()
  const [showPrivacy, setShowPrivacy] = useState(false)
  const { t, i18n } = useTranslation()

  const onClickItems = useCallback(() => {
    mutate({
      onSuccess: (data: any) => {
      }
    }, {
      onSuccess: (data: any) => {
        setShowPrivacy(false)
        history.push(url)
      }
    })
  }, [])

  const onCancelClick = useCallback(() => {

  }, [])

  const onClickItem = useCallback(() => {
    if (isSetPrivacy) {
      if (data && !data?.isConsent) {
        setShowPrivacy(true)
      }
    } else {
      history.push(url)
    }
  }, [history, url, data, isSetPrivacy])

  return (
    <>
      <Modal
        visible={showPrivacy}
        hideCloseIcon
        onCancel={() => onCancelClick()}
        footerComponent={
          <FooterSubmitInsuranceModal>
            <BenefitSelectButton onClick={() => onClickItems()}>ยินยอม/Accept</BenefitSelectButton>
          </FooterSubmitInsuranceModal>
        }
      >
        <ContentSubmitInsuranceModal>
          <Text size={18} type="Bold" color='#E87722'>
            ความยินยอมเรื่องข้อมูลส่วนบุคคล (Consent for Use of Personal Data)
          </Text>
          <div>
            <Text>(1) ข้าพเจ้ารับทราบถึง<a style={{ textDecoration: 'underline', color: '#183028' }} href='https://app-main-1730.azurewebsites.net/files/FWD_Privacy_Policy_for_Employees_TH_final.pdf' target='_blank'>นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>ของ บริษัท เอฟดับบลิวดี ประกันชีวิต จำกัด (มหาชน) (“FWD”) และยินยอมให้ FWD เก็บรวบรวม ใช้ ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่มีความอ่อนไหวของข้าพเจ้า เพื่อวัตถุประสงค์ในการดำเนินการตรวจสอบรายการเบิกค่าใช้จ่ายเกี่ยวกับผลประโยชน์และสวัสดิการที่ข้าพเจ้ามีสิทธิได้รับตามนโยบายของ FWD</Text>
            <Text color='#E87722'>I acknowledge the <a style={{ textDecoration: 'underline', color: '#E87722' }} href='https://app-main-1730.azurewebsites.net/files/FWD_Privacy_Policy_for_Employees_EN_final.pdf' target='_blank'>privacy policy</a> of FWD Life Assurance Public Company Limited (“FWD”) and grant
              consent to FWD to collect, use my personal data and sensitive personal data for the purpose of conducting
              a reimbursement audit of the employee benefits to which I am entitled under FWD&#39;s policies.</Text>

            <Text>(2) ข้าพเจ้ารับรองและรับประกันว่า ข้าพเจ้าได้รับความยินยอมหรือสามารถอาศัยฐานทางกฎหมายอื่นสำหรับการเก็บรวบรวม ใช้ ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่มีความอ่อนไหวของสมาชิกครอบครัวข้าพเจ้าตามกฎหมายที่ใช้บังคับ</Text>
            <Text color='#E87722'>I certify and guarantee that I grant consent or can rely on other legal basis for the collection, use personal
              data and sensitive personal data of my family members in accordance with applicable law.</Text>
            <Text>(3) ในกรณีที่มีการเก็บรวบรวม ใช้ ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่มีความอ่อนไหวของสมาชิกครอบครัวข้าพเจ้า ข้าพเจ้ารับรองและรับประกันว่า ข้าพเจ้าได้แจ้ง<a style={{ textDecoration: 'underline', color: '#183028' }} href='https://app-main-1730.azurewebsites.net/files/FWD_Privacy_Policy_for_Employees_TH_final.pdf' target='_blank'>นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>ของ FWD แก่สมาชิกครอบครัวข้าพเจ้าแล้ว ซึ่งมีการแจ้งวัตถุประสงค์ในการเก็บรวบรวม ใช้ข้อมูลส่วนบุคคลสำหรับพนักงาน</Text>
            <Text color='#E87722'>In case of collection, use personal data and sensitive personal data of my family members I certify and
              guarantee that I have informed FWD&#39;s <a style={{ textDecoration: 'underline', color: '#E87722' }} href='https://app-main-1730.azurewebsites.net/files/FWD_Privacy_Policy_for_Employees_EN_final.pdf' target='_blank'>privacy policy</a> to my family members by informing the purpose of
              collection and use personal information for employees.</Text>

            <br />
            <Text>ข้าพเจ้ายินยอมและรับรองให้ FWD เก็บรวบรวม ใช้ ข้อมูลส่วนบุคคลอ่อนไหวของข้าพเจ้ารวมถึงสมาชิกครอบครัวข้าพเจ้าเพื่อวัตถุประสงค์ข้างต้น</Text>
            <Text color='#E87722'>I grant consent and guarantee to FWD to collect and use my sensitive personal data including my family members
              for the above purposes.</Text>

          </div>
        </ContentSubmitInsuranceModal>
      </Modal>
      <Layout {...rest}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: 8,
        }} onClick={() => onClickItem()}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 8,
          }}>
            <CustomImage src={src || MaskImage} />
            <Text size={16} type="Bold" style={{ textAlign: 'center', marginTop: 8, whiteSpace: 'nowrap', color: '#E87722' }}>
              {label}
            </Text>
          </div>
          <Text size={12}>{description}</Text>
        </div>
      </Layout>
    </>
  )
}

const MyApp = () => {
  const { t, i18n } = useTranslation()
  const { data: period } = useGetEnrollmentPeriod()
  const { benefitYearId } = period || {}
  const { data: eClaimType } = useGetClaimType()
  const { data, isLoading } = useGetUser()
  const { mutate } = useAcceptConsent()
  const [showPrivacy, setShowPrivacy] = useState(false)
  const [isSetPrivacy, setIsSetPrivacy] = useState(false)

  const onClickItem = useCallback(() => {
    mutate({
      onSuccess: (data: any) => {
      }
    }, {
      onSuccess: (data: any) => {
        setShowPrivacy(false)
        setIsSetPrivacy(false)
      }
    })
  }, [])

  const onCancelClick = useCallback(() => {

  }, [])

  useEffect(() => {
    if (data && !data?.isConsent) {
      setShowPrivacy(true)
      setIsSetPrivacy(true)
    }
  }, [data, isLoading])

  return (
    <>
      <Modal
        visible={showPrivacy}
        hideCloseIcon
        onCancel={() => onCancelClick()}
        footerComponent={
          <FooterSubmitInsuranceModal>
            <BenefitSelectButton onClick={() => onClickItem()}>ยินยอม/Accept</BenefitSelectButton>
          </FooterSubmitInsuranceModal>
        }
      >
        <ContentSubmitInsuranceModal>
          <Text size={18} type="Bold" color='#E87722'>
            ความยินยอมเรื่องข้อมูลส่วนบุคคล (Consent for Use of Personal Data)
          </Text>
          <div>
            <Text>(1) ข้าพเจ้ารับทราบถึง<a style={{ textDecoration: 'underline', color: '#183028' }} href='https://app-main-1730.azurewebsites.net/files/FWD_Privacy_Policy_for_Employees_TH_final.pdf' target='_blank'>นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>ของ บริษัท เอฟดับบลิวดี ประกันชีวิต จำกัด (มหาชน) (“FWD”) และยินยอมให้ FWD เก็บรวบรวม ใช้ ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่มีความอ่อนไหวของข้าพเจ้า เพื่อวัตถุประสงค์ในการดำเนินการตรวจสอบรายการเบิกค่าใช้จ่ายเกี่ยวกับผลประโยชน์และสวัสดิการที่ข้าพเจ้ามีสิทธิได้รับตามนโยบายของ FWD</Text>
            <Text color='#E87722'>I acknowledge the <a style={{ textDecoration: 'underline', color: '#E87722' }} href='https://app-main-1730.azurewebsites.net/files/FWD_Privacy_Policy_for_Employees_EN_final.pdf' target='_blank'>privacy policy</a> of FWD Life Assurance Public Company Limited (“FWD”) and grant
              consent to FWD to collect, use my personal data and sensitive personal data for the purpose of conducting
              a reimbursement audit of the employee benefits to which I am entitled under FWD&#39;s policies.</Text>

            <Text>(2) ข้าพเจ้ารับรองและรับประกันว่า ข้าพเจ้าได้รับความยินยอมหรือสามารถอาศัยฐานทางกฎหมายอื่นสำหรับการเก็บรวบรวม ใช้ ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่มีความอ่อนไหวของสมาชิกครอบครัวข้าพเจ้าตามกฎหมายที่ใช้บังคับ</Text>
            <Text color='#E87722'>I certify and guarantee that I grant consent or can rely on other legal basis for the collection, use personal
              data and sensitive personal data of my family members in accordance with applicable law.</Text>
            <Text>(3) ในกรณีที่มีการเก็บรวบรวม ใช้ ข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่มีความอ่อนไหวของสมาชิกครอบครัวข้าพเจ้า ข้าพเจ้ารับรองและรับประกันว่า ข้าพเจ้าได้แจ้ง<a style={{ textDecoration: 'underline', color: '#183028' }} href='https://app-main-1730.azurewebsites.net/files/FWD_Privacy_Policy_for_Employees_TH_final.pdf' target='_blank'>นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>ของ FWD แก่สมาชิกครอบครัวข้าพเจ้าแล้ว ซึ่งมีการแจ้งวัตถุประสงค์ในการเก็บรวบรวม ใช้ข้อมูลส่วนบุคคลสำหรับพนักงาน</Text>
            <Text color='#E87722'>In case of collection, use personal data and sensitive personal data of my family members I certify and
              guarantee that I have informed FWD&#39;s <a style={{ textDecoration: 'underline', color: '#E87722' }} href='https://app-main-1730.azurewebsites.net/files/FWD_Privacy_Policy_for_Employees_EN_final.pdf' target='_blank'>privacy policy</a> to my family members by informing the purpose of
              collection and use personal information for employees.</Text>

            <br />
            <Text>ข้าพเจ้ายินยอมและรับรองให้ FWD เก็บรวบรวม ใช้ ข้อมูลส่วนบุคคลอ่อนไหวของข้าพเจ้ารวมถึงสมาชิกครอบครัวข้าพเจ้าเพื่อวัตถุประสงค์ข้างต้น</Text>
            <Text color='#E87722'>I grant consent and guarantee to FWD to collect and use my sensitive personal data including my family members
              for the above purposes.</Text>

          </div>
        </ContentSubmitInsuranceModal>
      </Modal>
      <CategoryGroup label={t('main.title')} style={{ marginBottom: 16 }}>
        {
          eClaimType?.map((list: any, index: number) => {
            return (
              <Item
                isSetPrivacy={isSetPrivacy}
                key={index}
                src={`${config.apiHost}/files${list.icon}`}
                label={i18n.language === 'en' ? list.nameEn : list.name}
                url={
                  `${list.id === 1 ? '/home' : list.id === 2 ? `/enrollment/${list.id}` : `/e_claim_d`}`
                }
                type={'0'}
                description={i18n.language === 'en' ? list.descriptionEn : list.description}
              />
            )
          }
          )
        }
      </CategoryGroup>
    </>
  )
}

export default MyApp
