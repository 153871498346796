import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import Loading from '../../components/common/Loading'
import { useRouter } from '../../utils/helper'
import { useGetUserClaimById } from '../../services/e-claim/e-claim-query'
import EClaimInfo from './EClaimInfo'
import { useMemo } from 'react'
import Headbar from '../../components/common/HeadBar'
import * as paths from '../../constant/path'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

const BreadcrumbContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`

const EClaimHistoryDetail = () => {
  const { query } = useRouter<{ id: number }>()
  const { i18n } = useTranslation()
  // const { mutate: cancelEClaim } = useCancelEClaim()
  const { data: eClaimItem, isLoading } = useGetUserClaimById({ userClaimId: query.id })
  const {
    exchangeCoin,
    id,
    claim
  } = eClaimItem || {}

  const initialValue = useMemo(() => {
    return {
      id,
      exchangeCoin,
      claim
    }
  }, [id, exchangeCoin])

  // const disable = useMemo(() => {
  //   if (!accept && (accept !== null)) {
  //     return false
  //   } else {
  //     return true
  //   }
  // }, [accept])

  // const onCreateNewEClaim = useCallback(async () => {
  //   if (eClaimItem?.id === undefined) return
  //   dispatch(
  //     setClaimInfo({
  //       name: claimName,
  //       exchangeCoin: exchangeCoin || 0,
  //       userClaimPrice: userClaimPrice || 0,
  //       optionalFileUrl: optionalFileUrl || '',
  //       receiptFile: receiptFile || '',
  //       userRemark: userRemark || '',
  //       relativeId: relativeId || '0',
  //     }),
  //   )

  //   cancelEClaim(eClaimItem?.id, {
  //     onSuccess: () => {
  //       history.push(eClaimDetail({
  //         routeParam: {
  //           eClaimId: eClaimItem?.claimId
  //         },
  //         queryParam: {
  //           claimTypeId: eClaimItem?.claimTypeId.toString(),
  //           isCreateNew: 'true'
  //         }
  //       }))
  //     }
  //   })
  // }, [eClaimItem, history, cancelEClaim, claimName, exchangeCoin, userClaimPrice, optionalFileUrl, receiptFile, userRemark, relativeId, dispatch])

  return (
    <Loading loading={isLoading}>
      <Headbar title={i18n.language === 'en' ? claim?.nameEn || '' : claim?.name || ''} backWard={paths.eClaimHistory()} />
      <EClaimInfo id={query.id} eClaimItem={eClaimItem} />
    </Loading>
  )
}

export default EClaimHistoryDetail
