import { useMemo } from 'react'
import { useHistory } from 'react-router'
import defaultNews from '../../assets/images/base/default_news.jpg'
import ImageSlider, { ImageProps } from '../../components/common/ImageSlider'
import config from '../../config'
import * as paths from '../../constant/path'
import { useGetHilightNews } from '../../services/digital-news/digital-news-query'
import { NewsType } from '../../services/digital-news/digital-news-types'
import { useGetUser } from '../../services/user/user-query'

const NewsSlide = () => {
  const history = useHistory()
  const { data: user } = useGetUser()
  const { userId } = user || {}
  const { data } = useGetHilightNews({ userId: 1 })

  const images = useMemo(() => {
    const tempImage: ImageProps[] = []
    data?.forEach((news: NewsType) => {
      let img
      if (!news?.digitalNews?.thumbnailPath)
        img = defaultNews
      else if (news?.digitalNews?.thumbnailPath?.indexOf('http') > -1)
        img = news?.digitalNews?.thumbnailPath
      else
        img = `${config.apiHost}${news?.digitalNews?.thumbnailPath}`

      tempImage.push({
        imagePath: img,
        title: news.digitalNews.title,
        onClick: () =>
          history.push(paths.newsDetails({ routeParam: { newsId: news.digitalNews.id } })),
      })
    })

    return tempImage
  }, [data, history])

  return (
    <ImageSlider images={images} />
  )
}

export default NewsSlide
