import styled from '@emotion/styled'
import color from '../../../constant/colors'
import { isScg, useRouter } from '../../../utils/helper'
import Menu from './Menu'
import * as paths from '../../../constant/path'
import Text from '../../common/Text'
import LogoImg from '../../../assets/images/base/logo-icons.svg'
import { useScreen, mobile } from '../../../utils/responsive-helper'
import { Link } from 'react-router-dom'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import Input from '../../common/Input'
import CoinFormatter from '../../CoinFormatter'
import { useGetUserCoin } from '../../../services/user/user-query'
import { Button } from 'antd'
import { useHistory } from 'react-router'
import ScgIcon from '../../../assets/images/base/scg-icon.svg'

const Layout = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${color.WHITE};
  height: 77px;
  border-bottom: 1px solid ${color.BORDER};
  ${mobile} {
    height: 45px;
    border: none;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1600px;
  padding: 0 16px;
`

const MenuIconGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`

const MenuGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`

const CoinButton = styled(Button)`
  height: auto;
  border: none;
  border-radius: 100px;
  background: linear-gradient(270deg, #ffb292 0%, #fe7339 100%, #fe7339 100%);
  padding: 8px 16px;
  margin-left: 8px;

  :hover,
  :active,
  :focus {
    background: linear-gradient(270deg, #ffb292 0%, #fe7339 100%, #fe7339 100%);
    border-color: none;
  }
`

const Logo = styled.img`
  ${mobile} {
    width: 28px;
  }
`

const ShopMenu = () => {
  const history = useHistory()
  const { pathname } = useRouter()
  const { isMobile } = useScreen()
  const isHiddenShopMenuList =
    pathname.startsWith(paths.promotionCategory()) ||
    pathname.startsWith(paths.coupon()) ||
    pathname.startsWith(`${paths.product()}/`) ||
    pathname.startsWith(paths.shopUser())

  const { data: userCoin } = useGetUserCoin()
  const currentLogo = isScg === true ? ScgIcon : LogoImg

  const shopMenuList = [
    { title: 'Home', path: paths.shop(), isActive: pathname.startsWith(paths.shop()) },
    {
      title: 'Promotion',
      path: paths.promotion(),
      isActive: pathname.startsWith(paths.promotion()),
    },
    {
      title: 'Product',
      path: paths.products(),
      isActive: pathname.startsWith(paths.product()),
    },
    {
      title: 'Order',
      path: paths.order(),
      isActive: pathname.startsWith(paths.order()),
    },
  ]

  const handleUserCoinNavigation = () => {
    history.push(paths.shopUserCoin())
  }

  return (
    <>
      {isMobile ? (
        !!!isHiddenShopMenuList ? (
          <>
            <Layout>
              <Header>
                <Link to={paths.home()}>
                  <Logo
                    src={currentLogo}
                    style={{
                      height: '70px',
                      width: isScg === true && isMobile === true ? '100px' : '',
                    }}
                  />
                </Link>
                <Link to={paths.shopCart()}>
                  <Text size={20} style={{ lineHeight: '20px' }}>
                    <AiOutlineShoppingCart />
                  </Text>
                </Link>
              </Header>
            </Layout>
            <Layout>
              <MenuGroup>
                {shopMenuList.map((menu, index) => (
                  <Menu key={index} path={menu.path} isActive={menu.isActive}>
                    <Text size={18} type="Bold">
                      {menu.title}
                    </Text>
                  </Menu>
                ))}
              </MenuGroup>
            </Layout>
          </>
        ) : (
          <></>
        )
      ) : (
        <Layout>
          <Header>
            <Link to={paths.home()}>
              <Logo
                src={currentLogo}
                style={{
                  height: '70px',
                  width: isScg === true ? '100px' : '',
                }}
              />
            </Link>
            <MenuIconGroup>
              {shopMenuList.map((menu, index) => (
                <Menu key={index} path={menu.path} isActive={menu.isActive}>
                  {menu.title}
                </Menu>
              ))}
              <Input
                style={{ marginLeft: 4, marginRight: 4 }}
                inputStyle={{ borderRadius: 100, backgroundColor: color.BORDER }}
                placeholder="ค้นหาสินค้าที่ต้องการ"
              />
              <CoinButton onClick={handleUserCoinNavigation}>
                <CoinFormatter textColor={color.WHITE} value={userCoin || 0} />
              </CoinButton>
              <Menu path={paths.shopCart()} isActive={false}>
                <Text size={24} style={{ lineHeight: '24px' }}>
                  <AiOutlineShoppingCart />
                </Text>
              </Menu>
            </MenuIconGroup>
          </Header>
        </Layout>
      )}
    </>
  )
}

export default ShopMenu
