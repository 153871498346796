import { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import Text from '../../components/common/Text'
import { eClaimDetail } from '../../constant/path'
import styled from '@emotion/styled'
import { mobile, useScreen, mobileVerySmall } from '../../utils/responsive-helper'
import { useTranslation } from 'react-i18next'

import { Collapse } from 'antd'
import CollapseWellbeing from './CollapeseWellbeing'
import { useGetClaimTypeById } from '../../services/e-claim/e-claim-query'
import WellBeingCardScg from '../../components/WellBeingCardScg'
import { tablet } from '../../utils/responsive-helper'
import Image from '../../components/common/Image'
import { IconInsurance, IconError } from '../../assets/images/images-scg'
import { hideScrollStyle } from '../../utils/responsive-helper'
import Modal from '../../components/common/Modal'
import { useVisibility } from '../../utils/custom-hook'
import Button from '../../components/common/Button'
import color from '../../constant/colors'
import FooterContent from '../../components/FooterContent'
import { useHistory } from 'react-router'


import config from '../../config'

const WellBeingContainer = styled.div<{ isMobile: boolean }>`
  /* border: 1px solid #000000; */
  margin-left: 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  // justify-content: ${(isMobile) => (isMobile ? 'center' : 'flex-start')};
  margin-bottom: 15px;
  ${mobile} {
    grid-template-columns: 50% 50%;
    grid-gap: 8px;
  }
  ${mobileVerySmall} {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`

const TextModal = styled(Text)`
  text-align: center;
`

const WellBeingContainerSub = styled.div<{ isMobile: boolean }>`
  /* border: 1px solid #000000; */
  margin-left: 0px;
  display: grid;
  grid-gap: 24px;
  // justify-content: ${(isMobile) => (isMobile ? 'center' : 'flex-start')};
  margin-bottom: 15px;
  ${mobile} {
    grid-template-columns: 50% 50%;
    grid-gap: 8px;
  }
  ${mobileVerySmall} {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`

const BackButton = styled(Button)`
  width: 267px;
  margin-top:18px;
  margin-bottom:18px;
`

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  border: 0.5px solid #DDDDDD;
  border-radius: 8px;
  padding: 8px 16px;
  width: 100%;
  user-select: none;
  :hover {
    cursor: pointer;
  }

  ${tablet} {
    width: 100%;
  }
`
const CustomImage = styled(Image)`
  width: 22px;
  height: 22px;
`

const ErrorIcon = styled(Image)`
object-fit: contain;
width: 72px;
height: 72px;
`

const ContentLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  overflow-y: hidden;
  overflow-x: scroll;
  /* > *:not(:first-of-type) {
    margin-left: 24px;
  } */
  > {
    background-color: red;
  }
  ${hideScrollStyle}
`
const ButtonStyle = styled(Button)`
  width: 100%;
  max-width: 100%;
`

type ItemProps = {
  typeId: number
  relativeId?: number
}

const EClaimListComponentScg = (props: ItemProps) => {
  const { typeId } = props
  const [visible, modalAction] = useVisibility()
  const [content, setContent] = useState<string>()
  const [id, setId] = useState<number>(0)
  const { Panel } = Collapse
  const { isMobile } = useScreen()
  const { t, i18n } = useTranslation()
  const history = useHistory()

  const { data: eClaimTypeDetail } = useGetClaimTypeById({ claimId: typeId })

  function handleModal(id: number, content: string) {
    setId(id)
    modalAction.show()
    setContent(content)
  }
  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          modalAction.hide()
        }}
        hideFooter
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          rowGap: 24
        }}>
          <ErrorIcon src={IconError} />
          <TextModal size={24} type="Bold">
            {content?.includes('<!DOCTYPE html>') ? <div dangerouslySetInnerHTML={{ __html: content }}></div> : content}
          </TextModal>

          <Link
            style={{ width: '100%' }}
            to={eClaimDetail({
              routeParam: {
                eClaimId: id
              },
            })}
          >
            <ButtonStyle
              onClick={() => {
                modalAction.hide()
              }}
              style={{ background: 'transparent', color: color.PRIMARY, borderColor: color.PRIMARY }}
            >
              <Text size={20} type="SemiBold" color={color.FONT_BLACK}>
                {t('appShortcut.ok')}
              </Text>
            </ButtonStyle>
          </Link>
        </div>
      </Modal>

      <CollapseWellbeing
        style={{
          margin: isMobile ? '0px 16px 0px' : '0px 0px 0px',
        }}
      >
        <Panel
          header={
            <Layout>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                rowGap: 8,
              }}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: 8,
                }}>
                  <CustomImage src={`${config.apiHost}/files${eClaimTypeDetail?.icon}`} />
                  <Text size={16} type="Bold" style={{ textAlign: 'center', marginTop: 8, whiteSpace: 'nowrap', color: '#E87722' }}>
                    {i18n.language === 'en' ? eClaimTypeDetail?.nameEn : eClaimTypeDetail?.name || ''}
                  </Text>
                </div>
                <Text size={10}> {i18n.language === 'en' ? eClaimTypeDetail?.descriptionEn : eClaimTypeDetail?.description || ''}</Text>
              </div>
            </Layout>
          }
          key='1'
        >
          <div style={{ backgroundColor: '#ECECEC', padding: 16 }}>
            <WellBeingContainer isMobile={isMobile}>
              {
                eClaimTypeDetail?.claims.map((data: any) => {
                  return data?.showDialog ? <div style={{ cursor: 'pointer' }} >
                    <WellBeingCardScg
                      title={i18n.language === 'en' ? data.nameEn : data.name}
                      icon={`${config.apiHost}/files${data.icon}`}
                      maxClaimablePrice={5000}
                      availableAmount={2000}
                      onClick={() => handleModal(data.id, i18n.language === 'en' ? data.showDialogMessageEn : data.showDialogMessage)}
                      description={i18n.language === 'en' ? data.descriptionEn : data.description}
                    />
                  </div> : <Link
                    to={eClaimDetail({
                      routeParam: {
                        eClaimId: data.id
                      },
                      queryParam: {
                        relativeId: props.relativeId
                      }
                    })}
                  >
                    <WellBeingCardScg
                      title={i18n.language === 'en' ? data.nameEn : data.name}
                      icon={`${config.apiHost}/files${data.icon}`}
                      maxClaimablePrice={5000}
                      availableAmount={2000}
                      description={i18n.language === 'en' ? data.descriptionEn : data.description}
                    />
                  </Link>
                }

                )
              }
            </WellBeingContainer>
          </div>
        </Panel>
      </CollapseWellbeing>

      <FooterContent>
        <BackButton onClick={() => history.goBack()}>
          {t('eClaim.backButton')}
        </BackButton>
      </FooterContent>
    </>

  )
}

export default EClaimListComponentScg
