import styled from '@emotion/styled'
import { Checkbox } from 'antd'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/common/Button'
import Modal, { ModalProps } from '../../components/common/Modal'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import { isScg } from '../../utils/helper'
import { mobile } from '../../utils/responsive-helper'

const Layout = styled.div`
  padding: 48px 98px;
  background-color: ${isScg === true ? color.PRIMARY : ''};
  ${mobile} {
    width: calc(100vw - 32px);
    padding: 16px;
    padding-top: 24px;
    .header {
      padding-right: 32px;
    }
  }
`

const Header = styled.div`
  text-align: center;
  margin-bottom: 16px;
`

const Content = styled.div`
  padding: 24px;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: ${color.BACKGROUND};
  ${mobile} {
    padding: 16px;
  }
`

const ContentScroll = styled.div`
  height: calc(55vh - 48px);
  max-width: 534px;
  overflow-y: auto;
`

export const Footer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: ${color.WHITE};
  padding: ${isScg ? '16px' : ''};
  border-radius: ${isScg ? '5px' : ''};
  .check-box {
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    margin-bottom: 32px;

    .ant-checkbox {
      /* margin-top: 6px; */
      background-color: ${color.PRIMARY};
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${color.PRIMARY};
    }
    .ant-checkbox,
    .ant-checkbox-checked::after,
    .ant-checkbox-input + .ant-checkbox-inner,
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${color.PRIMARY};
      border-radius: 2px;
      width: 24px;
      height: 24px;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      top: 58%;
      left: 30%;
      transform: rotate(45deg) scale(1.5) translate(-50%, -50%);
    }
  }

  ${mobile} {
    .check-box {
      margin-bottom: 16px;
    }
  }
`

type ModalAcceptPrivacyProps = {
  onConfirmClick: () => void
  onCloseClick: () => void
} & Required<Pick<ModalProps, 'visible'>>
const ModalAcceptPrivacy = (props: ModalAcceptPrivacyProps) => {
  const { t } = useTranslation()
  const { visible, onConfirmClick, onCloseClick } = props
  const [isAccept, setIsAccept] = useState(false)
  const onCancel = useCallback(() => {
    onCloseClick()
    setIsAccept(false)
  }, [onCloseClick])

  const onConfirm = useCallback(() => {
    onConfirmClick()
    setIsAccept(false)
  }, [onConfirmClick])
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      style={{
        padding: 0,
      }}
      hideFooter
      isTermAndCondition={true}
    >
      <Layout>
        <Header>
          <Text size={30} type="Bold" color={isScg === true ? color.WHITE : color.FONT_BLACK}>
            {t('enrollment.modalAcceptPrivacy.header')}
          </Text>
        </Header>
        <Content>
          <ContentScroll>
            <Text>
              จากการเลือกปรับเปลี่ยนสวัสดิการแผนประกันของตนเอง ข้าพเจ้าขอยืนยันและตกลงตามข้อตกลงดังต่อไปนี้ <br />
              1. ข้าพเจ้าได้อ่าน ทำความเข้าใจ และยอมรับในข้อตกลงและเงื่อนไข ขั้นตอนการดำเนินการตลอดจนถึงรายละเอียดของสวัสดิการแผนประกันของตนเองที่ทางบริษัทฯ ได้จัดเตรียมให้และสื่อสารกับข้าพเจ้า โดยข้าพเจ้ามีสิทธิที่จะเข้าร่วมในโปรแกรมสวัสดิการดังกล่าว <br />
              2. โดยการกดปุ่ม “ยืนยัน” ข้าพเจ้ามีความประสงค์ที่จะใช้สวัสดิการที่ข้าพเจ้าได้เลือก สวัสดิการที่เลือกนั้นอยู่ในการใช้ดุลยพินิจ การพิจารณา และการตัดสินใจเลือกของข้าพเจ้าเอง <br />
              3. ข้าพเจ้ายินดีรับผิดชอบค่าใช้จ่ายส่วนเกินที่เกิดขึ้นจากการเลือกแผนประกันที่มีมูลค่าสูงกว่าแผนดั้งเดิม (default) ตามสิทธิ์ และยอมให้หักเงินค่าใช้จ่ายนี้ผ่านระบบเงินเดือน (payroll) <br />
              4. เมื่อการลงทะเบียนเสร็จสิ้นและทางบริษัทฯ ได้ยอมรับการลงทะเบียนดังกล่าว ข้าพเจ้ายอมรับและตกลงว่าข้าพเจ้าไม่สามารถทำการเปลี่ยนแปลงใด ๆ ในสวัสดิการที่ข้าพเจ้าเลือก จนกระทั่งรอบระยะเวลาการเลือกและลงทะเบียนรอบใหม่ตามที่บริษัทฯ กำหนด  <br />
              5. หากข้าพเจ้าไม่ได้ทำการเลือกสวัสดิการในช่วงระยะเวลาการลงทะเบียนที่บริษัทกำหนด ข้าพเจ้ารับทราบและยอมรับในข้อกำหนดของสิทธิประโยชน์ประกันกลุ่มตามแผนดั้งเดิม (default) ตามนโยบายที่บริษัทจัดให้  <br />
              6. ข้าพเจ้าเข้าใจและยอมรับว่าการเลือกสวัสดิการบางรายการจะถูกบันทึกเป็นรายได้  เพื่อนำไปคำนวณภาษีเงินได้ส่วนบุคคลในงวดเงินเดือน (payroll) ตามอัตราภาษีที่สรรพากรกำหนด <br />
              7. ข้าพเจ้าเข้าใจและยอมรับว่าการมีสิทธิในสวัสดิการต่างๆ ของบริษัทจะสิ้นสุดลง ณ วันที่สิ้นสุดสภาพการจ้างงาน <br />
              8. สิทธิประโยชน์ทางด้านประกันนั้นจะเป็นไปตามเงื่อนไขและข้อตกลงของบริษัทประกัน ในกรณีที่มีข้อขัดแย้งระหว่างเงื่อนไขและข้อตกลงของประกัน ให้อ้างอิงเงื่อนไขและข้อตกลงของบริษัทประกันเป็นหลัก <br /><br />
              ในการกดปุ่ม “ยืนยัน” ข้าพเจ้ายืนยันว่าข้าพเจ้าได้อ่าน ทำความเข้าใจ และยอมรับในข้อตกลงและเงื่อนไขตามประกาศข้างต้น
            </Text>
          </ContentScroll>
        </Content>
        <Footer>
          <Checkbox
            className="check-box"
            checked={isAccept}
            onChange={(v) => {
              setIsAccept((prev) => !prev)
            }}
            autoFocus={true}
          >
            <Text size={18}>{t('enrollment.modalAcceptPrivacy.accept')}</Text>
          </Checkbox>
          <Button onClick={onConfirm} disabled={!isAccept}>
            {t('enrollment.modalAcceptPrivacy.confirm')}
          </Button>
        </Footer>
      </Layout>
    </Modal >
  )
}

export default ModalAcceptPrivacy
