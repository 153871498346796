import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import EClaimForm from './EClaimDisasterForm'
import { RootState } from '../../redux-store'
import styled from '@emotion/styled'
import { useGetClaimById } from '../../services/e-claim/e-claim-query'
import { useScreen } from '../../utils/responsive-helper'

const BreadcrumbContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`

const EClaimSummary = () => {
  const { isMobile } = useScreen()
  const { data: claimItem, isLoading } = useGetClaimById({ claimId: 23 })

  const eClaimFormValue = useSelector((state: RootState) => state.eClaim.claimInfo)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <EClaimForm
        eClaimItem={claimItem}
        claimItem={claimItem}
        eClaimFormValue={eClaimFormValue}
        editable={false}
        isPreview={true}
      />
    </>
  )
}

export default EClaimSummary
