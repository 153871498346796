import { reduxForm, InjectedFormProps } from 'redux-form'
import styled from '@emotion/styled'

import { InputField } from '../../components/common/fields'
import Button from '../../components/common/Button'
import { UserLoginParam } from '../../services/auth/auth-types'
import { useTranslation } from 'react-i18next'

type LoginFormProps = {
  loading?: boolean
}

const FORM_NAME = 'LOGIN_FORM'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 311px;
  > *:not(img) {
    width: 100%;
  }
  input.ant-input {
    font-size: 16px;
    line-height: 18px;
  }
`

const LoginForm: React.FC<LoginFormProps & InjectedFormProps<UserLoginParam, {}, string>> = (
  props,
) => {
  const { handleSubmit, loading } = props
  const { t } = useTranslation()

  return (
    <Form onSubmit={handleSubmit}>
      {/* <Logo src={LogoImage} /> */}
      <InputField
        style={{ marginTop: 64 }}
        label={t('login.input.username.label')}
        name="username"
        placeholder={t('login.input.username.placeholder')}
        disabled={loading}
      />
      <InputField
        style={{ marginTop: 15 }}
        label={t('login.input.password.label')}
        name="password"
        placeholder={t('login.input.password.placeholder')}
        inputType="password"
        disabled={loading}
      />
      <Button loading={loading} htmlType="submit" style={{ marginTop: 30 }}>
        {t('login.button.submit')}
      </Button>
    </Form>
  )
}

export default reduxForm<UserLoginParam, LoginFormProps, string>({
  form: FORM_NAME,
  validate: (fields) => {
    const error: Partial<UserLoginParam> = {}
    if (!fields.username) {
      error.username = 'login.formError.username'
    }
    if (!fields.password) {
      error.password = 'login.formError.password'
    }
    return error
  },
})(LoginForm)
