import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useGetUser } from '../../services/user/user-query'
import { mobile, tablet, useScreen } from '../../utils/responsive-helper'
import Text from '../../components/common/Text'
import moment from 'moment'
import 'moment/locale/th'
import { toBuddhistYear } from '../../utils/dateFormat'

const ProfileLayout = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: inherit;
  margin-bottom: 32px;

  .left {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
  }
`

const Profile = () => {
  const { t, i18n } = useTranslation()
  const { data: user } = useGetUser()
  const { isMobile } = useScreen()
  return (
    <ProfileLayout>
      <div className='left'>
        <Text size={32} type="Bold">{i18n.language === 'en' ? `${user?.firstName} ${user?.lastName}` : `${user?.firstNameTH} ${user?.lastNameTH}`}</Text>
        <Text size={20} type="Bold">{t('home.subtitle')} - {t('home.validUntil')} {i18n.language === 'en' ? `${(moment(user?.userBudget?.benefitYear?.endPeriodDate).locale('en').format('DD MMMM YYYY'))}` : (toBuddhistYear(moment(user?.userBudget?.benefitYear?.endPeriodDate).locale('th'), 'DD MMMM YYYY'))}</Text>
      </div>
      <div className='right'>
        {/* <Point /> */}
      </div>
    </ProfileLayout>
  )
}

export default Profile