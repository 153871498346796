import React, { useEffect, useState, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import Breadcrumb from '../../components/common/Breadcrumb'
import color from '../../constant/colors'
import { useScreen, mobile, tablet } from '../../utils/responsive-helper'
import Card from '../../components/common/Card'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import {
  useGetUserApps,
  useGetAllApps,
  useChangeAppShortcut,
} from '../../services/app/app-shortcut-query'
import { useGetUser } from '../../services/user/user-query'
import { AppShortcut, UserAppShortcut } from '../../services/app/app-shortcut-types'
import Button from '../../components/common/Button'
import MaskImage from '../../assets/images/base/mask@2x.jpg'
import Loading from '../../components/common/Loading'
import { useTranslation } from 'react-i18next'
import Modal from '../../components/common/Modal'
import { useVisibility } from '../../utils/custom-hook'
import AddIcon from '../../assets/images/base/icons-add.svg'

const BreadcrumbLayout = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  ${mobile} {
    margin: 24px 16px;
  }
`

const AppCardContainer = styled(Card)`
  width: 267px;
  height: 369px;
  overflow: hidden;
  padding: 16px;
  ${mobile} {
    width: 164px;
    height: 274px;
  }
`

const CardContainer = styled.div`
  margin-left: 0px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 267px);
  grid-gap: 24px;
  justify-content: flex-start;
  margin-bottom: 15px;
  ${mobile} {
    justify-content: center;
    grid-template-columns: auto auto;
  }
  ${tablet} {
    justify-content: center;
  }
`

const CardText = styled(Text)`
  margin-bottom: 16px;
  margin-top: 8px;
`

const AppImage = styled(Image)`
  width: 235px;
  height: 235px;
  ${mobile} {
    width: 132px;
    height: 132px;
  }
`

const TopCenterLayout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
`

const ModalContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ModalButton = styled(Button)`
  width: 300px;
  margin-top: 50px;
`

type AppCardProps = {
  app: AppShortcut
  activeAppId: Array<number>
  setActiveAppId: Function
}

const AppCard = (props: AppCardProps) => {
  const { t } = useTranslation()
  const { isMobile } = useScreen()
  const { app, activeAppId, setActiveAppId } = props
  const [modalVisible, modalAction] = useVisibility()
  const [modalVisibleFaild, modalActionFaild] = useVisibility()
  const { mutate: change } = useChangeAppShortcut()

  const onCardButtonClick = useCallback(() => {
    modalAction.show()
  }, [modalAction])

  const onConfirm = useCallback(
    (newActiveAppId: Array<number>) => {
      const activeAppIdStr = newActiveAppId.join(',')
      change({ activeAppIdStr: activeAppIdStr })
      modalAction.hide()
    },
    [change, modalAction],
  )

  const onPinAppConfirmClick = useCallback(() => {
    const newActiveAppId = [...activeAppId]
    if (newActiveAppId.length < 5) {
      newActiveAppId.push(app.id)
      setActiveAppId(newActiveAppId.sort())
      onConfirm(newActiveAppId.sort())
    } else {
      modalActionFaild.show()
    }
  }, [app, activeAppId, setActiveAppId, onConfirm])

  const onUnPinAppConfirmClick = useCallback(() => {
    const newActiveAppId = activeAppId.filter((appId) => {
      return appId !== app.id
    })
    setActiveAppId(newActiveAppId)
    onConfirm(newActiveAppId)
  }, [app, activeAppId, setActiveAppId, onConfirm])

  const onCloseAppConfirmClick = useCallback(() => {
    modalActionFaild.hide()
    modalAction.hide()
  }, [])

  return (
    <>
      <Modal visible={modalVisible} onCancel={modalAction.hide} hideFooter>
        {activeAppId?.includes(app.id) ? (
          <ModalContainer>
            <Text size={30} type="Bold">
              {t('appShortcut.unPinModal.title')}
            </Text>
            <Text>{`${t('appShortcut.unPinModal.messageStart')}${app.name}${t(
              'appShortcut.unPinModal.messageEnd',
            )}`}</Text>
            <ModalButton onClick={onUnPinAppConfirmClick}>{t('appShortcut.confirm')}</ModalButton>
          </ModalContainer>
        ) : (
          <ModalContainer>
            <Text size={30} type="Bold">
              {t('appShortcut.pinModal.title')}
            </Text>
            <Text>{`${t('appShortcut.pinModal.messageStart')}${app.name}${t(
              'appShortcut.pinModal.messageEnd',
            )}`}</Text>
            <ModalButton onClick={onPinAppConfirmClick}>{t('appShortcut.confirm')}</ModalButton>
          </ModalContainer>
        )}
      </Modal>

      <Modal visible={modalVisibleFaild} onCancel={onCloseAppConfirmClick} hideFooter>
        <ModalContainer>
          <Text size={30} type="Bold">
            {t('appShortcut.pinShortCut')}
          </Text>
          <Text>{t('appShortcut.pinFaildShort')}</Text>
          <ModalButton onClick={onCloseAppConfirmClick}>{t('appShortcut.ok')}</ModalButton>
        </ModalContainer>
      </Modal>

      <AppCardContainer>
        <AppImage src={app.logoPath || MaskImage} />
        <CardText size={20}>{app.name}</CardText>
        {activeAppId?.includes(app.id) ? (
          <Button onClick={onCardButtonClick} style={{ backgroundColor: color.LIGHT_GRAY }}>
            {isMobile ? t('appShortcut.unPinToHomeShort') : t('appShortcut.unPinToHome')}
          </Button>
        ) : (
          <Button iconImage={AddIcon} onClick={onCardButtonClick}>
            {isMobile ? t('appShortcut.pinToHomeShort') : t('appShortcut.pinToHome')}
          </Button>
        )}
      </AppCardContainer>
    </>
  )
}

const MyApp = () => {
  const { data: user } = useGetUser()
  const { data: allApps, isLoading: isAllAppsLoading } = useGetAllApps()
  const { data: userApps, isLoading: isUserAppsLoading } = useGetUserApps({ userId: user?.userId })
  const [activeAppId, setActiveAppId] = useState<Array<number>>([])

  const userAppId = useMemo(() => {
    return userApps?.map((userApp: UserAppShortcut) => {
      return userApp.appShortcutId
    })
  }, [userApps])

  useEffect(() => {
    setActiveAppId(userAppId || [])
  }, [userAppId])

  return (
    <>
      <BreadcrumbLayout>
        <Breadcrumb />
      </BreadcrumbLayout>
      <TopCenterLayout>
        <Text size={28} type="Bold">
          App
        </Text>
      </TopCenterLayout>
      <Loading loading={isAllAppsLoading || isUserAppsLoading}>
        <CardContainer>
          {allApps?.content.map((app: AppShortcut) => (
            <AppCard
              key={app.id}
              app={app}
              activeAppId={activeAppId}
              setActiveAppId={setActiveAppId}
            />
          ))}
        </CardContainer>
      </Loading>
    </>
  )
}

export default MyApp
