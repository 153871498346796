import styled from '@emotion/styled'
import coinsIcon from '../assets/images/base/icon-coin-currency.svg'
import Text from './common/Text'
import Image from './common/Image'
import color from '../constant/colors'
import { CSSProperties } from 'react'
import { numberWithCommas } from '../utils/helper'

const CoinCurrencyIcon = styled(Image)<{
  textSize: number
}>`
  height: ${({ textSize }) => `${textSize}px`};
  padding-right: 4px;
`

type CoinFormatterProps = {
  style?: CSSProperties
  textColor?: string
  textSize?: number
  textType?: 'Bold' | 'SemiBold' | 'Medium' | 'Text' | 'Regular'
  value: number
}

const CoinFormatter = (props: CoinFormatterProps) => {
  const { style, textColor = color.DARK_GRAY, textSize = 16, textType, value } = props

  return (
    <Text
      color={textColor}
      size={textSize}
      style={style}
      type={textType}
      className="coin-formatter"
    >
      <CoinCurrencyIcon src={coinsIcon} textSize={textSize} /> {`${numberWithCommas(value)}`}
    </Text>
  )
}
export default CoinFormatter
