import { CSSProperties, ReactNode, useMemo } from 'react'
import Image, { ImageNameKeys } from '../../components/common/Image'
import { mobile, tablet, mobileVerySmall, useScreen } from '../../utils/responsive-helper'
import styled from '@emotion/styled'
import color from '../../constant/colors'
import Text from '../../components/common/Text'
import { Collapse } from 'antd'
// import { EClaimHistory, EClaimStatus } from '../../services/e-claim/e-claim-types'
import { EClaimHistory } from '../../services/e-claim/e-claim-types'
import config from '../../config'
import { numberWithCommas } from '../../utils/helper'
import { useTranslation } from 'react-i18next'

const EClaimCardContainer = styled.div`
  background: ${color.WHITE};
  border: 0.5px solid ${color.BORDER};
  box-shadow: 0px 4px 4px rgba(218, 218, 218, 0.25);
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px
`
const EClaimCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const EClaimCardProfileLayout = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding-left: 24px;
`
const EClaimCardProfileTextLayout = styled.div`
  display: flex;
  flex-direction: column;
`
const EClaimCardRightTextLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
  padding-right:24px
`
const ImageIcon = styled(Image)`
  width: 50px;
  padding: 8px;
  border-radius: 4px;
    ${mobile} {
    width: 20px;
    height: 25px;
  }
    ${mobileVerySmall} {
    width: 40px;
    height: 40px;
  }
`
const CustomCollapse = styled(Collapse)`
  background-color: ${color.WHITE};
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 8px;
  }
`
const EClaimStatusContainer = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  padding: 4px 8px;
  width: fit-content;
`

const RowBottom = styled.div`
display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 16px;
`

const LayoutContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: left;
  margin-top: 22px;
`

const LayoutBodyBottom = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 24px;

  ${mobile} {
    max-width: 150px;
  }
`
const LayoutDate = styled.div`
  margin-right: 24px;
  display: flex;
  flex-direction: column;

  ${mobile} {
    margin-top: 5px;
  }
`


type EClaimCardProps = {
  title: string
  icon: ImageNameKeys
  onClick?: () => void
  date: string
  balance: number
}
export const EClaimCard = (props: EClaimCardProps) => {
  const { title, icon, onClick, date, balance } = props
  const userCoinFormated = useMemo(() => {
    return balance.toLocaleString(undefined, { maximumFractionDigits: 2 })
  }, [balance])

  return (
    <EClaimCardContainer
      onClick={onClick}
    >
      <EClaimCardWrapper>
        <EClaimCardProfileLayout>
          <ImageIcon imageName={icon} />
          <EClaimCardProfileTextLayout>
            <Text size={16} type='Bold' color={color.PRIMARY}>{title}</Text>
            <Text size={10} type='Bold' color={color.BLACK}>{date}</Text>
          </EClaimCardProfileTextLayout>
        </EClaimCardProfileLayout>
        <EClaimCardRightTextLayout>
          <Text size={16} type='Bold' color={color.BLACK}>วงเงินที่ใช้ได้</Text>
          <Text size={18} type='Bold' color={color.PRIMARY}>{userCoinFormated} ฿</Text>
        </EClaimCardRightTextLayout>
      </EClaimCardWrapper>
    </EClaimCardContainer>
  )
}

type EClaimCardLayoutProps = {
  data: EClaimHistory
}
export const EClaimHistoryCard = (props: EClaimCardLayoutProps) => {
  const { data } = props
  const { i18n } = useTranslation()

  const statusText = useMemo(() => {
    let statusText = ''
    switch (data?.claimStatus) {
      case 'AP':
        statusText = 'Approved'
        break;
      case 'RE':
        statusText = 'Rejected'
        break;
      case 'PD':
        statusText = 'Pending'
        break;
      case 'CC':
        statusText = 'Cancelled'
        break;
      default: statusText = 'Pending'
        break;
    }
    return statusText
  }, [data?.claimStatus])

  const statusCode = useMemo(() => {
    let statusCode = ''
    switch (data?.claimStatus) {
      case 'AP':
        statusCode = '#03824F'
        break;
      case 'RE':
        statusCode = '#B30909'
        break;
      case 'PD':
        statusCode = '#FED141'
        break;
      case 'CC':
        statusCode = '#DBDFE1'
        break;
      case '':
        statusCode = '#03824F'
        break;
      default: statusCode = '#03824F'
        break;
    }
    return statusCode
  }, [data?.claimStatus])

  return (
    <EClaimCardContainer>
      <EClaimCardWrapper>
        <EClaimCardProfileLayout>
          <ImageIcon src={`${config.apiHost}/files${data.claim.icon}`} />
          <EClaimCardProfileTextLayout>
            <Text size={16} type='Bold' color={color.PRIMARY}>{data.claim.claimType.name} {data?.relative !== null && `(${i18n.language === 'en' ? `${data?.relative?.firstnameEn} ${data?.relative?.lastnameEn}` : `${data?.relative?.firstnameTh} ${data?.relative?.lastnameTh}`})`}</Text>
            <Text size={18} type='Bold' color={color.PRIMARY}>{i18n.language === 'en' ? data.claim.nameEn : data.claim.name} {data?.subClaim?.name !== undefined && `(${i18n.language === 'en' ? `${data?.subClaim?.nameEn}` : `${data?.subClaim?.name}`})`}</Text>
          </EClaimCardProfileTextLayout>
        </EClaimCardProfileLayout>
        <EClaimCardRightTextLayout>
          <EClaimStatusContainer backgroundColor={statusCode}>
            <Text size={16} type='Bold' color={'#FFF'}>{statusText}</Text>
          </EClaimStatusContainer>
          <Text size={18} type='Bold' color={color.PRIMARY}>-{numberWithCommas(data.claimApproveAmount)} ฿</Text>
        </EClaimCardRightTextLayout>
      </EClaimCardWrapper>
    </EClaimCardContainer>
  )
}

type CollapeProps = {
  children: ReactNode
  style?: CSSProperties
}
export const CollapseWellbeing = (props: CollapeProps) => {
  const { children, style } = props
  return (
    <CustomCollapse expandIconPosition={'right'} style={style}>
      {children}
    </CustomCollapse>
  )
}
