import styled from '@emotion/styled'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import FooterContent from '../../components/FooterContent'
import Button from '../../components/common/Button'
import MainPanel from '../../components/common/MainPanel'
import Text from '../../components/common/Text'
import { useGetUser } from '../../services/user/user-query'
import { mobile, tablet } from '../../utils/responsive-helper'
import EclaimList from './EclaimList'
import Profile from './Profile'

const Layout = styled.div`
  padding-bottom: 40px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  background: #FAE4D3;

  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
  }
  
  ${mobile} {
    padding: 0px 16px 40px;
  }
`

const BackButton = styled(Button)`
  width: 267px;
  margin-top:18px;
  margin-bottom:18px;
`

const BackgroundHeader = styled.div`
  width: 100%;
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 850px;
  margin: 0 auto;
  z-index: 1;
  
  ${mobile} {
    width: 100%;
  }
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 20px;
  > *:not(:first-of-type) {
    margin-top: 24px;
  }
  ${tablet} {
    max-width: calc(100% - 324px);
  }
  ${mobile} {
    max-width: unset;
  }
`
const Home = () => {
  const { t } = useTranslation()
  const { data: userClaim, isLoading: userIsLoading } = useGetUser()
  const userBudget = userClaim?.userBudget
  const budget = userBudget?.budget || 0
  const budgetRemain = userBudget?.budgetRemain || 0
  const history = useHistory()

  if (userIsLoading) return <Text>Loading...</Text>

  if (!userClaim) return <Text>Please Re-Loading...</Text>

  // const onClickBackToEnrollment = useCallback(() => {
  //   // history.push(main())
  //   history.goBack()
  // }, [history])

  return (
    <Layout>
      <BackgroundHeader />
      <FlexRow style={{ marginTop: 22 }}>
        <Profile />
        <MainPanel
          id={1}
          key={1}
          badge={true}
          imagePath={'imagePath'}
          title={t('enrollment.fsaTitle')}
          date={userClaim?.userBudget?.benefitYear?.endPeriodDate}
          used={(budgetRemain === budget ? 0 : budget - budgetRemain) || 0}
          remain={userClaim?.userBudget?.budgetRemain || 0}
          fullBudget={userClaim?.userBudget?.budget || 0}
          url={'/enrollment/1'}
        />
        <FlexColumn>
          <EclaimList userClaim={userClaim} />
        </FlexColumn>
      </FlexRow>
      <FooterContent>
        <BackButton onClick={() => history.goBack()}>
          {t('eClaim.backButton')}
        </BackButton>
      </FooterContent>
    </Layout>
  )
}

export default Home
