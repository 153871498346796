import { useMemo } from 'react'
import styled from '@emotion/styled'
import Text from '../../../components/common/Text'
import color from '../../../constant/colors'
import { isScg, numberWithCommas, useRouter } from '../../../utils/helper'
import BenefitsCategory from './BenefitsCategory'
import { useGetUser } from '../../../services/user/user-query'
import Button from '../../../components/common/Button'
import Image from '../../../components/common/Image'
import BenefitsCategoryList from './BenefitsCategoryList'
import {
  GetBenefitsResponse,
  SelfBenefitsList,
} from '../../../services/enrollment/enrollment-types'
import { editSelfBenefits } from '../../../constant/path'
import { useHistory } from 'react-router'
import { Content, ContentSpace, ContentHeader, BenefitsCategoryStyled } from './EditBenefits/shared'
import { useTranslation } from 'react-i18next'
import HtmlWithStyled from '../../../components/HtmlWithStyled'
import { mobile, tablet, mobileVerySmall } from '../../../utils/responsive-helper'

const TextNotWrap = styled(Text)`
  white-space: nowrap;
`

const FlexBetweenSpect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Row = styled.div`
  display: flex;
  flex-flow: row;
`
const ButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  padding-bottom: 32px;
`
const StyledGroup = styled.div`
  width: 100%;
  display: grid;
  column-gap: 16px;
`
const BenefitSelectLayout = styled(Row)`
  width: 100%;
  justify-content: flex-end;
  border: 1px solid ${color.BORDER};
  border-radius: 8px;
  padding: 24px;
  column-gap: 16px;
`
const BenefitSelectButton = styled(Button)`
  width: min-content;
  border-color: ${color.PRIMARY};
  min-width: 165px;
`

const DescriptionLayout = styled(Row)`
  width: 100%;
  align-items: flex-end;
  > *:not(:last-child) {
    margin-right: 8px;
  }

  .benefit-description {
    width: 100%;
    white-space: normal;
    word-wrap: break-word;
    font-family: NotoSansThai-Medium;
    font-size: 20px;
    font-weight: 300;
    color: #183028;

    * {
      width: inherit;
    }
  }

  ${mobile} {
    flex-flow: column;
  }
`

type SelfBenefitsProp = {
  selectedBenefits: GetBenefitsResponse
  canEditable: boolean
  endDate: number
  onClickShowModalInsuranceDetail: () => void
}

const SelfBenefits = (props: SelfBenefitsProp) => {
  const { data: user } = useGetUser()
  const history = useHistory()
  const { query } = useRouter()
  const { t } = useTranslation()

  const { selectedBenefits, canEditable, endDate, onClickShowModalInsuranceDetail } = props
  const { userSelectedWrapperList } = selectedBenefits

  // const { selfBenefitsWrapperList, totalSelectedCoin } = selectedBenefits
  // const selfBenefitsWrapperListSize = selfBenefitsWrapperList.length - 1
  const isAvailable = user?.lastEnrollDate > Date.now()
  const categoriesSelfBenefits = useMemo(() => {

    const onClickButton = () => {
      history.push(
        editSelfBenefits({
          routeParam: {
            benefitYearId: query.benefitYearId,
          },
        }),
      )
    }

    return (
      // <BenefitsCategory
      //   key={selfBenefitsName + index}
      //   icon={'iconEnroll'}
      //   title={selfBenefitsName}
      // >
      //   <BenefitsCategoryList userSelectedList={userSelectedWrapperList || []}>
      //     {isLastSelfBenefits === true && canEditable === true && isAvailable === true && (
      //       <Button onClick={onClickButton}>+แก้ไขแผนประกันตนเอง</Button>
      //     )}
      //   </BenefitsCategoryLi 3st>
      // </BenefitsCategory>
      <div>
        <Content>
          {userSelectedWrapperList.map((items, index) => {
            if (items.choicesId) {
              return (
                <>
                  <ContentHeader>
                    <div style={{ display: 'flex' }}>
                      <div className="icon-enroll">
                        <Image imageName="iconEnrollWhite" />
                      </div>
                      <Text
                        size={24}
                        type="Bold"
                        color={isScg === true ? color.WHITE : color.FONT_BLACK}
                      >
                        {`ประกันของคุณ: ${items.choiceName}`}
                      </Text>
                    </div>
                  </ContentHeader>
                  <BenefitsCategoryStyled>
                    <StyledGroup>
                      {items.choicesDescription && (
                        <DescriptionLayout>
                          <HtmlWithStyled className="benefit-description" html={items.choicesDescription} />
                        </DescriptionLayout>
                      )}

                      <Row className="second"></Row>
                      {/* <HtmlWithStyled className="benefit-description" html={selectedBenefits.selfBenefitsChoice.choicesDescriptionn} /> */}
                      <Row
                        style={{
                          justifyContent: 'center',
                          padding: 12,
                          columnGap: 16,
                          backgroundColor: color.LIGHT_GRAY,
                          height: 54,
                        }}
                      >
                        <Image imageName="iconBenefit" width={34} />
                        <Text color={color.PRIMARY}>{t('enrollment.selfBenefits.insuranceSelected')}</Text>
                      </Row>
                    </StyledGroup>
                  </BenefitsCategoryStyled>
                </>
              )
            } else {
              return (
                <Text size={24} type="Bold" color={isScg === true ? color.WHITE : color.FONT_BLACK}>
                  {`ไม่พบประกันของคุณ`}
                </Text>
              )
            }
          })}
          {/* <BenefitsCategoryStyled
            isTitle={false}
            style={{
              backgroundColor: color.BACKGROUND_LIGHT,
              padding: 24,
            }}
          >
            <StyledGroup>
              <HtmlWithStyled className="benefit-description" html={selectedBenefits?.selfBenefitsChoice?.choicesDescription} />

              <Row className="second"></Row>
              <DescriptionLayout>
                <HtmlWithStyled className="benefit-description" html={selectedBenefits.selfBenefitsChoice.choicesDescriptionn} />
              </DescriptionLayout>
              <Row
                style={{
                  justifyContent: 'center',
                  padding: 12,
                  columnGap: 16,
                  backgroundColor: color.LIGHT_GRAY,
                  height: 54,
                }}
              >
                <Image imageName="iconBenefit" width={34} />
                <Text color={color.PRIMARY}>แผนประกันที่คุณเลือก</Text>
              </Row>
            </StyledGroup>
          </BenefitsCategoryStyled> */}
        </Content>
        {
          isAvailable === true && (
            // <ButtonEditInsuranceLayout>
            //   <Button onClick={onClickButton}>+แก้ไขแผนประกันตนเอง</Button>
            // </ButtonEditInsuranceLayout>
            <BenefitSelectLayout>
              {/* <BenefitSelectButton
                fontColor={color.PRIMARY}
                style={{ background: color.WHITE }}
                onClick={onClickShowModalInsuranceDetail}
              >
                เลือกแผนเดิม
              </BenefitSelectButton> */}
              <BenefitSelectButton onClick={onClickButton}>{t('enrollment.selfBenefits.changeInsurance')}</BenefitSelectButton>
            </BenefitSelectLayout>
          )
        }
      </div >
    )
  }, [
    canEditable,
    history,
    query.benefitYearId,
    isAvailable,
    userSelectedWrapperList,
    t
  ])

  const categoriesSelfBenefits2 = useMemo(() => {
    const onClickButton = () => {
      history.push(
        editSelfBenefits({
          routeParam: {
            benefitYearId: query.benefitYearId,
          },
        }),
      )
    }

    return (
      <ButtonStyle style={{}}>
        <Button disabled={!canEditable} onClick={onClickButton}>
          + {t('enrollment.selfBenefits.addInsurance')}
        </Button>
      </ButtonStyle>
    )
  }, [history, query.benefitYearId, canEditable, t])

  return (
    <ContentSpace direction="vertical" size={10} style={{ marginBottom: 32 }}>
      {/* <div style={{ backgroundColor: color.WHITE, borderRadius: '5px' }}> */}
      {/* <Text
        size={20}
        type={'Medium'}
        color={color.FONT_BLACK}
        style={{ paddingLeft: 12, paddingTop: 8 }}
      >
        รายละเอียดประกัน
      </Text> */}
      {userSelectedWrapperList.length > 0 ? categoriesSelfBenefits : categoriesSelfBenefits2}
      {/* {totalSelectedCoin !== 0 && (
        <FlexBetweenSpect>
          <Text size={22} type={'Bold'} style={{ paddingLeft: 12, paddingTop: 8 }}>
            รวมใช้คะแนน
          </Text>
          <TextNotWrap
            size={22}
            type={'Bold'}
            color={color.INPUT_ERROR}
            style={{ whiteSpace: 'nowrap', paddingRight: 12, paddingBottom: 8 }}
          >
            <Image imageName="iconCoin" style={{ margin: 8 }} />-
            {numberWithCommas(totalSelectedCoin)}
          </TextNotWrap>
        </FlexBetweenSpect>
      )} */}
      {/* </div> */}
    </ContentSpace>
  )
}

export default SelfBenefits
