import { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import Breadcrumb from '../../../components/common/Breadcrumb'
import Text from '../../../components/common/Text'
import Loading from '../../../components/common/Loading'
import Button from '../../../components/common/Button'
import { mobile, useScreen } from '../../../utils/responsive-helper'
import BenefitsCard from './BenefitsCard'
import Image from '../../../components/common/Image'
import SelfBenefits from './SelfBenefits'
import RelativeBenefits from './RelativeBenefits'
import LifeStyleBenefits from './LifeStyleBenefits'
import SummaryExchangeBenefits from './SummaryExchangeBenefits'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { enrollmentInsuranceFamily, enrollmentLeave } from '../../../constant/path'
import color from '../../../constant/colors'
import { useGetUser } from '../../../services/user/user-query'
import {
  useGetAllSelectedBenefits,
  useGetEnrollmentPeriod,
} from '../../../services/enrollment/enrollment-query'

import {
  useGetGroupNumber,
} from '../../../services/e-claim/e-claim-query'
import dayjs from 'dayjs'
import { pullAt } from 'lodash'
import {
  getShowModalDownloadInsuranceDetail,
  isScg,
  setShowModalDownloadInsuranceDetail,
  useCanEditable,
} from '../../../utils/helper'
import { Modal } from '../../../components/common/Modal'
import { useVisibility } from '../../../utils/custom-hook'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { Footer } from '../ModalAcceptPrivacy'
import { useEffect } from 'react'
import { useGetEnrollmentYear } from '..'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux-store'

const Layout = styled.div`
  margin-bottom: 40px;
`

const BreadcrumbLayout = styled.div`
  margin-top: 24px;

  ${mobile} {
    margin: 24px 16px;
  }
`
const Row = styled.div`
  display: flex;
  flex-flow: row;
`

const PaddingTextTop = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  align-self: center;
`

const LayoutTitle = styled(Row)`
  justify-content: center;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 27px;
`

const LayoutContentModal = styled.div`
  width: 500px;
  padding: 12px;
  ${mobile} {
    width: auto;
  }
`

const FooterModal = styled(Footer)`
  .check-box {
    margin-bottom: 0px;
  }
`

const ButtonStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${color.WHITE};
  border-radius: 5px;
  padding: 16px 32px 16px 32px;
`

const BenefitSelectButton = styled(Button)`
  width: min-content;
  border-color: ${color.PRIMARY};
  min-width: 165px;
`
const ContentSubmitInsuranceModal = styled.div`
  padding: 60px 0px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`
const FooterSubmitInsuranceModal = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  padding: 24px;
`

const Benefits = () => {
  const { t } = useTranslation()
  const { isMobile } = useScreen()
  const history = useHistory()
  const { benefitYearId, startDate, endDate, benefitYear } = useSelector(
    (state: RootState) => state.enrollment.enrollment,
  )
  const canEditable = useCanEditable(startDate, endDate)
  const { data: user } = useGetUser()
  const { data: groupNumber } = useGetGroupNumber()
  const { data: selectedBenefits, isLoading } = useGetAllSelectedBenefits(benefitYearId)

  const age = user?.age ? `${user.age}` : '-'
  const name = `${user?.firstName} ${user?.lastName} (${age})`

  // const [initialModal, setInitialModal] = useState(true)
  const [modalInsuranceDetail, setModaInsuranceDetail] = useVisibility()
  const [hideModalInsuranceDetailAgain, setHideModalInsuranceDetailAgain] = useState(
    getShowModalDownloadInsuranceDetail() === 'hide' ? true : false,
  )
  const [modalInsuranceSubmit, setModalInsuranceSubmit] = useState(false)

  const onClickToInsuranceFamily = useCallback(() => {
    if (benefitYearId) {
      history.push(
        enrollmentInsuranceFamily({
          routeParam: { benefitYearId },
        }),
      )
    }
  }, [benefitYearId, history])

  const onClickToExchangeLeave = useCallback(() => {
    if (benefitYearId) {
      history.push(enrollmentLeave({ routeParam: { benefitYearId } }))
    }
  }, [benefitYearId, history])

  useEffect(() => {
    if (
      getShowModalDownloadInsuranceDetail() === 'show' ||
      !getShowModalDownloadInsuranceDetail()
    ) {
      setModaInsuranceDetail.show()
    }
  }, [])

  const onCloseModalInsuranceDetail = useCallback(() => {
    setModaInsuranceDetail.hide()
  }, [setModaInsuranceDetail])

  const onClickDownloadInsuranceDetail = useCallback(() => {
    window.open(`https://beliveshop.blob.core.windows.net/product/Dentsu-Insurance-${groupNumber?.insuranceGroup}.pdf`)
  }, [benefitYear, groupNumber])

  const onClickShowModalInsuranceDetail = useCallback(() => {
    setModalInsuranceSubmit(true)
  }, [])
  const onClickHideModalInsuranceDetail = useCallback(() => {
    setModalInsuranceSubmit(false)
  }, [])

  const onChangeInsurance = useCallback(() => { }, [])

  return (
    <Layout>
      <Modal visible={modalInsuranceDetail} onCancel={onCloseModalInsuranceDetail} hideFooter>
        <LayoutContentModal>
          <Text style={{ textAlign: 'center' }}>
            {t('enrollment.selfBenefits.modalInsuranceDetail.detail')}
          </Text>
          <Row style={{ marginTop: '36px', marginBottom: '18px' }}>
            <Image
              imageName="iconPDF"
              width={73}
              height={73}
              style={{ margin: 'auto', cursor: 'pointer' }}
              onClick={onClickDownloadInsuranceDetail}
            />
          </Row>
          <Text style={{ textAlign: 'center', marginBottom: '36px' }}>
            {t('enrollment.selfBenefits.modalInsuranceDetail.title')}
          </Text>

          <FooterModal>
            <Checkbox
              className="check-box"
              onChange={(v) => {
                var isHide = !hideModalInsuranceDetailAgain
                setHideModalInsuranceDetailAgain((prev) => !prev)
                if (isHide) {
                  setShowModalDownloadInsuranceDetail('hide')
                } else {
                  setShowModalDownloadInsuranceDetail('show')
                }
              }}
              defaultChecked={hideModalInsuranceDetailAgain}
              checked={hideModalInsuranceDetailAgain}
              autoFocus={true}
            >
              <Text size={18} color={color.FONT_BLACK}>
                {t('enrollment.selfBenefits.modalInsuranceDetail.notShowAgain')}
              </Text>
            </Checkbox>
          </FooterModal>
        </LayoutContentModal>
      </Modal>
      {/* {!isMobile && ( */}
      <BreadcrumbLayout>
        <Breadcrumb
          overide={(paths) => {
            paths.unshift({ label: 'Home', path: '/' })
            paths[1].label = t('app.breadcrumb.myWelFare')
            paths[2].label = t('app.breadcrumb.myInsurance')
            pullAt(paths, 4)
            return paths
          }}
        />
      </BreadcrumbLayout>

      <Loading loading={isLoading}>
        {selectedBenefits && (
          <SelfBenefits
            selectedBenefits={selectedBenefits}
            canEditable={canEditable}
            endDate={endDate}
            onClickShowModalInsuranceDetail={onClickShowModalInsuranceDetail}
          />
        )}
      </Loading>
      {canEditable === true && isScg === false && (
        <BenefitsCard
          icon={'iconUserGroupWhite'}
          title={'ประกันสุขภาพเพิ่มเติมให้ครอบครัว'}
          desc={'สำหรับ คู่สมรส บุตร บิดา มารดา เท่านั้น'}
          isDesc={true}
        >
          <ButtonStyle>
            <Button onClick={onClickToInsuranceFamily}>+แก้ไขประกันให้ครอบครัว</Button>
          </ButtonStyle>
        </BenefitsCard>
      )}

      <Row style={{ marginBottom: 16 }}>

      </Row>
      <SummaryExchangeBenefits
        totalRemainingCoins={selectedBenefits?.totalCost}
      />

      <Modal
        visible={modalInsuranceSubmit}
        onCancel={onClickHideModalInsuranceDetail}
        footerComponent={
          <FooterSubmitInsuranceModal>
            <BenefitSelectButton
              fontColor={color.PRIMARY}
              style={{ background: color.WHITE }}
              onClick={onClickHideModalInsuranceDetail}
            >
              ยกเลิก
            </BenefitSelectButton>
            <BenefitSelectButton onClick={onChangeInsurance}>ยืนยัน</BenefitSelectButton>
          </FooterSubmitInsuranceModal>
        }
      >
        <ContentSubmitInsuranceModal>
          <Text size={32} type="Bold">
            คุณยืนยันแผนประกันภัยสำหรับปี 2566 หรือไม่ ?
          </Text>
          <div>
            <Text>แผนประกันชีวิต: แผน 1</Text>
            <Text>แผนประกันสุขภาพ: แบบผู้ป่วยในแผน 1 , แบบผู้ป่วยนอกแผน 1, แบบทันตกรรมแผน 1</Text>
          </div>
        </ContentSubmitInsuranceModal>
      </Modal>
    </Layout>
  )
}

export default Benefits
