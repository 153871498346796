import { CloseOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Modal as AntdModal } from 'antd'
import React, { CSSProperties, ReactNode } from 'react'
import color from '../../constant/colors'
import Button from './Button'
import Text from './Text'

const ModalContentLayout = styled.div`
  border-radius: 8px;
  overflow: hidden;
  .ant-modal-close-x {
    width: unset;
    height: unset;
  }
`

const BaseModalFooterLayout = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
`
const ButtonStyle = styled(Button)`
  width: 100%;
  margin: 0 20px;
`
type BaseModalFooterProps = {
  onOk?: () => void
  onCancel?: () => void
  onOkText?: string
  onCancelText?: string
}
const BaseModalFooter = (props: BaseModalFooterProps) => {
  const { onOkText = 'ตกลง', onCancelText = 'ยกเลิก', onOk, onCancel } = props
  return (
    <BaseModalFooterLayout>
      {onOk && (
        <ButtonStyle onClick={onOk} style={{ background: color.PRIMARY }}>
          <Text size={20} type="SemiBold" color={color.FONT_BLACK}>
            {onOkText}
          </Text>
        </ButtonStyle>
      )}
      {onCancel && (
        <ButtonStyle
          onClick={onCancel}
          style={{ background: 'transparent', color: color.PRIMARY, borderColor: color.PRIMARY }}
        >
          <Text size={20} type="SemiBold" color={color.FONT_BLACK}>
            {onCancelText}
          </Text>
        </ButtonStyle>
      )}
    </BaseModalFooterLayout>
  )
}
export type ModalProps = {
  width?: number
  visible: boolean
  children?: ReactNode
  onOk?: () => void
  onCancel: () => void
  onOkText?: string
  onCancelText?: string
  hideFooter?: boolean
  hideCloseIcon?: boolean
  closeIconSize?: number
  headerComponent?: ReactNode
  footerComponent?: ReactNode
  disableCloseOnClickOutside?: boolean
  style?: CSSProperties
  iconStyle?: Pick<CSSProperties, 'fontSize' | 'color' | 'margin' | 'padding'>
  className?: string
  isTermAndCondition?: boolean
}
export const Modal = (props: ModalProps) => {
  const {
    children,
    visible,
    onOk,
    onCancel,
    onOkText = 'ตกลง',
    onCancelText = 'ยกเลิก',
    hideFooter,
    hideCloseIcon,
    closeIconSize,
    headerComponent,
    footerComponent,
    disableCloseOnClickOutside = false,
    style,
    iconStyle,
    className,
    isTermAndCondition = false,
  } = props

  return (
    <AntdModal
      centered
      destroyOnClose
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={
        footerComponent ? (
          footerComponent
        ) : !hideFooter ? (
          <BaseModalFooter
            onOk={onOk}
            onCancel={onCancel}
            onOkText={onOkText}
            onCancelText={onCancelText}
          />
        ) : (
          false
        )
      }
      title={headerComponent ? headerComponent : null}
      closeIcon={
        !hideCloseIcon ? (
          <CloseOutlined
            style={{
              color: isTermAndCondition === false ? '#363640' : color.WHITE,
              fontSize: closeIconSize || 32,
              verticalAlign: 'middle',
              margin: 16,
              ...iconStyle,
            }}
            onClick={onCancel}
          />
        ) : (
          false
        )
      }
      closable={!hideCloseIcon}
      maskClosable={!disableCloseOnClickOutside}
      modalRender={(children) => (
        <ModalContentLayout className={`modal-content-layout ${className}`}>
          {children}
        </ModalContentLayout>
      )}
      bodyStyle={{
        ...style,
      }}
      width={style?.width || 'fit-content'}
      style={{
        height: 'fit-content',
        ...style,
      }}
    >
      {children}
    </AntdModal>
  )
}

export default Modal
