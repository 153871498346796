import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { ContentType } from '../../utils/api/tools'
import { GET_USER_COIN_URL, GET_USER_URL } from '../user/user-query'
import {
  AllBenefit,
  CreateRelative,
  GetAllRelativeBenefitsResponse,
  GetAllSelfBenefitsResponse,
  GetBenefitsByCategoryResponse,
  GetEnrollmentPeriodResponse,
  GetRelativeResponse,
  GetRelativesResponse,
  LeaveBenefitParams,
  RelativeBenefitsSendParams,
  RelativeInfo,
  SelfBenefitsSendParams,
  GetBenefitsResponse,
  DeleteRelativeBenefitsParams,
} from './enrollment-types'

export const APP = '/app'
export const MANAGE = '/manage'
export const ENROLLMENTS = `enrollments`
export const ENROLLMENTS_PERIOD = `${APP}/${ENROLLMENTS}/period`
export const ENROLLMENTS_NEXT_PERIOD = `${APP}/${ENROLLMENTS}/next-period`

export const SELF_BENEFITS = `${APP}/${ENROLLMENTS}/self-benefits`
export const SELF_BENEFITS_SEND = `${APP}/${ENROLLMENTS}/self-benefits/send`
export const SELF_BENEFITS_CATEGORY = `${MANAGE}/${ENROLLMENTS}/self-benefits/category`
export const ALL_BENEFITS = `${APP}/${ENROLLMENTS}/benefits`

export const RELATIVE = `${APP}/${ENROLLMENTS}/relative`
export const RELATIVE_BENEFITS = `${APP}/${ENROLLMENTS}/relative-benefits`
export const RELATIVE_BENEFITS_SEND = `${APP}/${ENROLLMENTS}/relative-benefits/send`
export const RELATIVE_BENEFITS_DELETE = `${APP}/${ENROLLMENTS}/relative-benefits/remove-all`

export const USER_CLAIM = 'userClaim'
export const E_CLAIM_LIST = `${APP}/${USER_CLAIM}/getClaimList`
export const USER_LEAVE_COIN = `${APP}/userLeaveCoin/createUserLeaveCoin`

export const BENEFITS = `${APP}/${ENROLLMENTS}/benefits`

export const useGetEnrollmentPeriod = () => {
  return useQuery(
    [ENROLLMENTS, ENROLLMENTS_PERIOD],
    () => {
      return api.belive.get<GetEnrollmentPeriodResponse>(`${ENROLLMENTS_PERIOD}`)
    },
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  )
}

export const useGetEnrollmentNextPeriod = () => {
  return useQuery(
    [ENROLLMENTS, ENROLLMENTS_NEXT_PERIOD],
    () => {
      return api.belive.get<GetEnrollmentPeriodResponse>(`${ENROLLMENTS_NEXT_PERIOD}`)
    },
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  )
}

export const useGetAllSelfBenefits = (benefitYearId?: number) => {
  return useQuery(
    [ENROLLMENTS, SELF_BENEFITS, { benefitYearId }],
    () => {
      return api.belive.get<GetAllSelfBenefitsResponse>(`${SELF_BENEFITS}/${benefitYearId}`)
    },
    {
      enabled: !!benefitYearId,
    },
  )
}

export const useGetBenefitsByCategory = (benefitsCategoryId: number) => {
  return useQuery([ENROLLMENTS, SELF_BENEFITS_CATEGORY, { benefitsCategoryId }], () => {
    return api.belive.get<GetBenefitsByCategoryResponse>(
      `${SELF_BENEFITS_CATEGORY}/${benefitsCategoryId}`,
    )
  })
}

export const useGetAllBenefits = (benefitYearId: number) => {
  return useQuery([ENROLLMENTS, ALL_BENEFITS, { benefitYearId }], () => {
    return api.belive.get<AllBenefit>(`${ALL_BENEFITS}/${benefitYearId}`)
  })
}

export const useSelfBenefitsSend = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (params: SelfBenefitsSendParams) => {
      return api.belive.post(`${SELF_BENEFITS_SEND}`, params, { contentType: ContentType.JSON })
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([ENROLLMENTS])
        queryClient.invalidateQueries(GET_USER_URL)
        queryClient.invalidateQueries(GET_USER_COIN_URL)
      },
    },
  )
}

export const useRelativeBenefitsSend = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (params: RelativeBenefitsSendParams) => {
      return api.belive.post(`${RELATIVE_BENEFITS_SEND}`, params, { contentType: ContentType.JSON })
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([ENROLLMENTS])
        queryClient.invalidateQueries(GET_USER_URL)
        queryClient.invalidateQueries(GET_USER_COIN_URL)
      },
    },
  )
}

export const useGetRelatives = () => {
  return useQuery([ENROLLMENTS, RELATIVE], () => {
    return api.belive.get<GetRelativesResponse>(`${RELATIVE}`)
  })
}

export const useGetRelative = (relativeId?: number) => {
  return useQuery(
    [ENROLLMENTS, RELATIVE, { relativeId }],
    () => {
      return api.belive.get<GetRelativeResponse>(`${RELATIVE}/${relativeId}`)
    },
    {
      enabled: !!relativeId,
    },
  )
}

export const useCreateRelative = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (params: CreateRelative) => {
      return api.belive.post(`${RELATIVE}`, params, { contentType: ContentType.JSON })
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([ENROLLMENTS])
      },
    },
  )
}

export const useDeleteRelative = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (relativeId: number) => {
      return api.belive.delete(`${RELATIVE}/${relativeId}`, { contentType: ContentType.JSON })
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([ENROLLMENTS])
      },
    },
  )
}

export const useCreateLeaveCoin = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (params: LeaveBenefitParams) => {
      return api.belive.post(`${USER_LEAVE_COIN}`, params, { contentType: ContentType.JSON })
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([ENROLLMENTS])
      },
    },
  )
}

export const useGetAllRelativeBenefits = (benefitYearId?: number, relativeId?: number) => {
  return useQuery(
    [ENROLLMENTS, RELATIVE_BENEFITS, { benefitYearId, relativeId }],
    () => {
      return api.belive.get<GetAllRelativeBenefitsResponse>(
        `${RELATIVE_BENEFITS}/${benefitYearId}/${relativeId}`,
      )
    },
    {
      enabled: !!benefitYearId && !!relativeId,
    },
  )
}

export const useGetAllSelectedBenefits = (benefitYearId?: number) => {
  return useQuery(
    [ENROLLMENTS, BENEFITS, { benefitYearId }],
    () => {
      return api.belive.get<GetBenefitsResponse>(`${BENEFITS}/${benefitYearId}`)
    },
    {
      enabled: !!benefitYearId,
    },
  )
}

export const useDeleteRelativeBenefits = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (params: DeleteRelativeBenefitsParams) => {
      const { benefitYearId, relativeId } = params

      return api.belive.post(`${RELATIVE_BENEFITS_DELETE}/${benefitYearId}/${relativeId}`, {
        contentType: ContentType.JSON,
      })
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([ENROLLMENTS])
      },
    },
  )
}
