import { Button, Result } from 'antd'
import { useCallback } from 'react'
import { useHistory } from 'react-router'
import * as paths from '../../constant/path'

const PageNotFound = () => {
  const history = useHistory()

  const onBackToHomeClick = useCallback(() => {
    history.push(paths.home())
  }, [history])
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={onBackToHomeClick}>
          Back Home
        </Button>
      }
    />
  )
}

export default PageNotFound
