import { Link, useHistory } from 'react-router-dom'
import Text from '../../components/common/Text'
import { home, enrollment, eClaimList } from '../../constant/path'
// import { enrollmentDetail } from '../../constant/path'
import { useGetEnrollmentPeriod } from '../../services/enrollment/enrollment-query'
import { useGetEClaimList } from '../../services/e-claim/e-claim-query'
import styled from '@emotion/styled'
import { mobile, useScreen, mobileVerySmall } from '../../utils/responsive-helper'

import Card from '../../components/common/Card'
import { Layout } from './Benefits/EditBenefits/shared'
import Button from '../../components/common/Button'
import Image from '../../components/common/Image'
import Breadcrumb from '../../components/common/Breadcrumb'
import dayjs from 'dayjs'
import Countdown from 'react-countdown'
import { useCallback, useMemo } from 'react'
import { PointCard } from '../../components/PointCard'
import { convertDateFormatTH, DateFormat } from '../../utils/dateFormat'
import color from '../../constant/colors'
import { isScg } from '../../utils/helper'
import { Collapse } from 'antd'
import EClaimListComponent from './EClaimListComponent'
import EClaimListComponentScg from './ECalimListComponentScg'

const WellBeingContainer = styled.div<{ isMobile: boolean }>`
  /* border: 1px solid #000000; */
  margin-left: 0px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 267px);
  grid-gap: 24px;
  justify-content: ${(isMobile) => (isMobile ? 'center' : 'flex-start')};
  margin-bottom: 15px;
  ${mobile} {
    grid-template-columns: 50% 50%;
    grid-gap: 8px;
  }
  ${mobileVerySmall} {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 32px;

  ${mobile} {
    margin: 32px 16px 0px;
  }
`
const TextDate = styled(Text)`
  padding-top: 16px;
  padding-right: 16px;

  ${mobile} {
    padding-right: 2px;
  }
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const FlexCard = styled(FlexRow)`
  ${mobile} {
    flex-direction: column;
  }
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ImageStyle = styled(Image)`
  margin-left: 8px;
`

const CustomCard = styled(Card)`
  padding-top: 40px;
  padding-left: 97px;
  padding-right: 97px;
  padding-bottom: 40px;
  margin-top: 24px;
  ${mobile} {
    padding: 16px;
    text-align: center;
  }
`
const TextTitleSecond = styled(Text)`
  margin-top: 32px;
  text-align: center;
`
const CustomButton = styled(Button)`
  margin-top: 16px;
`
const BreadcrumbContainer = styled.div`
  ${mobile} {
    margin: 0px 16px;
  }
`

export const useGetEnrollmentYear = (startDateProp: number = 0) => {
  const { data: enrollmentPeriod } = useGetEnrollmentPeriod()

  return useMemo(() => {
    let startDate
    if (startDateProp) {
      startDate = startDateProp
    } else {
      startDate = enrollmentPeriod?.startDate
    }
    const enrollmentYear = dayjs(startDate).add(543, 'year').year()
    return {
      enrollmentYear: enrollmentYear || '',
    }
  }, [enrollmentPeriod, startDateProp])
}

const Enrollment = () => {
  const { isMobile } = useScreen()
  const { data: enrollmentPeriod } = useGetEnrollmentPeriod()
  const { enrollmentYear } = useGetEnrollmentYear()
  const { data: eclaimList } = useGetEClaimList()

  const { isActive, startDate, endDate } = enrollmentPeriod || {}
  let eventDate = dayjs(endDate)
  const currentDate = dayjs()
  const startDateText = convertDateFormatTH(
    dayjs(enrollmentPeriod?.startDate).toDate(),
    DateFormat.FORMAT_1TH,
  )
  const endDateText = convertDateFormatTH(
    dayjs(enrollmentPeriod?.endDate).toDate(),
    DateFormat.FORMAT_1TH,
  )

  const renderer = ({ hours, minutes, days }: any) => {
    if (currentDate < dayjs(startDate) || currentDate > dayjs(endDate)) {
      // Render a completed state
      return <div></div>
    } else if (currentDate >= dayjs(startDate) && currentDate <= dayjs(endDate)) {
      // Render a countdown
      return (
        <FlexCard>
          <TextDate size={22}>เหลือเวลาอีก</TextDate>
          <FlexRow>
            <TextDate size={32} color={color.SECONDARY} type="Bold">
              {days}
            </TextDate>
            <TextDate size={22}>วัน</TextDate>
            <TextDate size={32} color={color.SECONDARY} type="Bold">
              {hours}
            </TextDate>
            <TextDate size={22}>ชั่วโมง</TextDate>
            <TextDate size={32} color={color.SECONDARY} type="Bold">
              {minutes}
            </TextDate>
            <TextDate size={22}>นาที</TextDate>
          </FlexRow>
        </FlexCard>
      )
    }
  }
  const history = useHistory()

  const onClickEnrollment = useCallback(() => {
    // history.push(
    //   enrollmentDetail({
    //     routeParam: {
    //       benefitYearId: enrollmentPeriod?.benefitYearId!,
    //     },
    //   }),
    // )
  }, [enrollmentPeriod?.benefitYearId, history])
  const { Panel } = Collapse

  return (
    <>
      <Layout style={{ paddingTop: '24px' }}>
        <BreadcrumbContainer>
          <Breadcrumb
            overide={(paths) => {
              paths[0] = {
                label: 'Home',
                path: home(),
              }
              paths[1] = {
                label: 'ปรับเปลี่ยนสวัสดิการ',
                path: enrollment(),
              }
              return paths
            }}
          />
        </BreadcrumbContainer>

        {isActive === true && !isScg ? (
          <CustomCard>
            <FlexColumn>
              <Text size={28} type="Bold" /* color={isScg === true ? color.PRIMARY : ''} */>
                วางแผนสวัสดิการยืดหยุ่นประจำปี {enrollmentYear}
              </Text>
              <Text size={28} type="Bold" /* color={isScg === true ? color.PRIMARY : ''} */>
                {startDateText} - {endDateText}
              </Text>
              <Text size={22} style={{ paddingTop: '16px' }}>
                เลือกสวัสดิการใน life style แบบของคุณเอง
              </Text>
              <Countdown date={eventDate.toDate()} renderer={renderer} />
              <Text size={22} style={{ paddingTop: '16px' }}>
                ถึงวันที่ {endDateText}
              </Text>
              <CustomButton onClick={() => onClickEnrollment()}>
                ดูสวัสดิการปี {enrollmentYear}{' '}
              </CustomButton>
            </FlexColumn>
          </CustomCard>
        ) : (
          !isScg && (
            <CustomCard>
              <FlexColumn>
                <ImageStyle
                  width={64}
                  height={64}
                  imageName={'calendarIcon'}
                  style={{ marginBottom: '24px' }}
                />
                <Text type="Bold" size={28} color={color.PRIMARY}>
                  สิ้นสุดการวางแผนสวัสดิการยืดหยุ่นประจำปี {enrollmentYear}
                </Text>
              </FlexColumn>
            </CustomCard>
          )
        )}
        <TextTitleSecond type="Bold" size={26}>
          คะแนนปีปัจจุบัน ({enrollmentYear})
        </TextTitleSecond>
        <PointCard />
        <TitleContainer>
          <Text type="Bold" size={isMobile ? 28 : 32} style={{ marginRight: isMobile ? '64px' : '38px' }}>
            สวัสดิการเพื่อไลฟ์สไตล์ของคุณ
          </Text>
          <Link to={eClaimList()}>
            <Image
              imageName={'chartIcon'}
              width={38}
              height={38}
              style={{ position: 'absolute', right: '12px', cursor: 'pointer' }}
            />
          </Link>
        </TitleContainer>
        <div style={{ height: '24px' }}></div>
        {/* {isScg === false ? <EClaimListComponent /> : <EClaimListComponentScg />} */}
      </Layout>
    </>
  )
}
export default Enrollment
