import styled from '@emotion/styled'
import { Image, Upload } from 'antd'
import { RcFile, UploadChangeParam } from 'antd/lib/upload'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconFile } from '../../assets/images/images-scg'
import iconClose from '../../assets/images/images-scg/icons-close-green-18-px.svg'
import color from '../../constant/colors'
import { useVisibility } from '../../utils/custom-hook'
import { mobile, useScreen } from '../../utils/responsive-helper'
import Button from './Button'
import IconImage, { ImageNameKeys } from './Image'
import Modal from './Modal'
import OutlineButton from './OutlineButton'
import Text from './Text'

const Layout = styled.div`
  background-color: WHITE;
  height: 100%;
  width: 100%;
`

const ImagePreviewPanel = styled.div<{ isMobile: boolean }>`
  padding: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const FilePreview = styled.div`
  padding: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const CommentImage = styled(Image)`
  border: 0px solid black;
  height: 100%;
  object-fit: contain;
  flex-direction: column;
  display: flex;
  justify-content: center;
  
  ${mobile} {
    object-fit: cover;
  }
`

const LayoutRowButtonUpload = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  ${mobile} {
    padding-left: 0px;
    padding-right: 0px;
    span {
      width: 100%;
    }
  }

  .ant-upload {
    width: 300px;
    ${mobile} {
      width: 100%;
    }
  }
`

const LayoutRowPreview = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 200px;
  background: #ECECEC;
  margin: 16px;
  border-radius: 7px;

  ${mobile} {
    padding-left: 0px;
    padding-right: 0px;
    span {
      width: 100%;
    }
  }

  .ant-upload {
    width: 300px;
    ${mobile} {
      width: 100%;
    }
  }
`

const LayoutModal = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LayoutButtonModal = styled(Button)`
  width: 300px;
  margin: 50px 0 0 0;
`
const LayoutBodyColumn = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 16px 0 16px;

  ${mobile} {
    padding: 12px;
  }
`

const LayoutPreviewColumn = styled.div`
  display: flex;
  flex-direction: row;
`

const LayoutBodyRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const LayoutTitleRow = styled.div`
  display: 'flex';
  flex-direction: 'column';
  padding-left: 16px;
  padding-top: 7px;
`

export type UploadFileProps = {
  icon: ImageNameKeys
  title: string
  desc: string
  value?: string
  onChange?: (v: any) => void
  isPreview?: boolean
  disabled?: boolean
  accept?: string
  displayError?: boolean
  /**
   * maximumFileSize in MB, default 2 MB
   * */
  maximumFileSize?: number
}

const UploadFile = (props: UploadFileProps) => {
  const {
    icon,
    title,
    desc,
    onChange,
    value,
    isPreview = false,
    maximumFileSize = 3,
    disabled = false,
    accept
  } = props
  const [selectedFile, setSelectedFile] = useState<any>(value || '')
  const [selectedFileName, setSelectedFileName] = useState('')
  const [preview, setPreview] = useState('')
  const { isMobile } = useScreen()
  const [modalVisible, modalAction] = useVisibility()
  const [errorMessage, setErrorMessage] = useState('')
  const [isFile, setIsFile] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!selectedFile) {
      setPreview('')
      return
    }

    setPreview(selectedFile)

    if (!selectedFile.includes('data:image') && selectedFileName === '' && selectedFile.substr(0, 8) !== 'https://') {
      setSelectedFileName(`file: ${selectedFile.split('/')[1].split(';')[0]}`)
    }

    if (['pdf'].includes(selectedFile.split('.').pop())) {
      setIsFile(true)
    }
  }, [selectedFile, selectedFileName])

  const isCheckType = useCallback((image) => {
    if (!accept) return true;

    const typeImg = accept.split(',')
    return typeImg.find((val) => image.name.search(val) > -1)
  }, [accept])

  const isLarge = useCallback((image) => {
    return image.size / 1024 / 1024 > maximumFileSize
  }, [maximumFileSize])

  const onRemoveClick = useCallback(
    (e) => {
      if (!disabled) {
        e.preventDefault()
        setPreview('')
        setSelectedFile('')
        onChange?.('')
      }
    },
    [onChange],
  )

  const getBase64 = useCallback(async (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])

  const onSelectedFile = useCallback(
    async (params: UploadChangeParam) => {
      const { file: image } = params

      if (!isLarge(image) && isCheckType(image)) {
        if (image) {
          const imageBase64 = (await getBase64(image)) as string
          onChange?.(imageBase64)
          setSelectedFile(imageBase64)

          if (!image.type.includes('image'))
            setSelectedFileName(image?.name)
          else
            setSelectedFileName('')
        }
      }
    },
    [getBase64, onChange, maximumFileSize],
  )

  const validateUploadFile = useCallback(
    (image: RcFile) => {
      if (isLarge(image)) {
        modalAction.show()
        setPreview('')
        setSelectedFile('')
        onChange?.('')
        setSelectedFileName('')
        setErrorMessage('ขนาด ภาพ/ไฟล์ ใหญ่เกินไป')
      } else if (!isCheckType(image)) {
        modalAction.show()
        setPreview('')
        setSelectedFile('')
        onChange?.('')
        setSelectedFileName('')
        setErrorMessage(`ไม่สามารถแนบไฟล์ได้ สามารถแนบไฟล์ประเภท ${accept} เท่านั้น`)
      }
    },
    [accept, isCheckType, isLarge, modalAction, onChange],
  )

  const beforeUpload = useCallback(
    (image: RcFile) => {
      validateUploadFile(image)
      return false
    },
    [validateUploadFile],
  )

  return (
    <Layout className="layout-upload">
      <Modal visible={modalVisible} onCancel={modalAction.hide} hideFooter>
        <LayoutModal>
          <Text>{errorMessage}</Text>
          <LayoutButtonModal onClick={modalAction.hide}>Cancel</LayoutButtonModal>
        </LayoutModal>
      </Modal>
      <LayoutBodyColumn>
        <LayoutBodyRow>
          <LayoutTitleRow>
            <Text size={24} type={'Medium'}>
              {title}
            </Text>
            <Text size={22} type={'Medium'} color={color.FONT_LIGHT_GRAY}>
              {desc}
            </Text>
          </LayoutTitleRow>
        </LayoutBodyRow>

        <LayoutRowButtonUpload>
          {(!selectedFile && !isPreview) && (
            <Upload
              action=""
              listType="text"
              accept={accept || ""}
              onChange={onSelectedFile}
              style={{ width: '100% ' }}
              beforeUpload={beforeUpload}
              showUploadList={false}
              disabled={disabled}
            >
              <OutlineButton disabled={disabled}>+ {t('eClaim.field.addFile')} </OutlineButton>
            </Upload>
          )}
        </LayoutRowButtonUpload>
      </LayoutBodyColumn>

      <LayoutPreviewColumn>
        <LayoutRowPreview>
          {selectedFile && (
            <ImagePreviewPanel isMobile={isMobile}>
              {selectedFileName ? (
                <FilePreview>
                  <div>
                    <Image src={IconFile} width={48} height={48} />
                  </div>
                  <div>
                    {selectedFileName}
                  </div>
                </FilePreview>
              ) :
                (
                  <FilePreview>
                    {
                      isFile ? <div><a href={preview} target="_blank" rel="noopener noreferrer">Click to Watch File</a></div> : (<CommentImage height={'100%'} src={preview} alt="" />)
                    }
                  </FilePreview>
                )
              }
              {isPreview === false && (
                <IconImage src={iconClose} width={24} height={24} onClick={onRemoveClick} />
              )}
            </ImagePreviewPanel>
          )}
        </LayoutRowPreview>
      </LayoutPreviewColumn>
    </Layout>
  )
}
export default UploadFile
