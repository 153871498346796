export const subHeaderTh = 'กรุณาอ่านข้อกำหนดและเงื่อนไขเหล่านี้อย่างละเอียดก่อนเข้าใช้เว็บไซต์นี้'
export const subHeaderEn = 'กรุณาอ่านข้อกำหนดและเงื่อนไขเหล่านี้อย่างละเอียดก่อนเข้าใช้เว็บไซต์นี้'

export const webAccessTextTh =
  'เราไม่รับประกันว่าเว็บไซต์หรือเนื้อหาใดๆ ที่มีอยู่ของเราจะพร้อมใช้งานตลอดเวลาหรือไม่มีการหยุดชะงัก การอนุญาตให้เข้าใช้เว็บไซต์ของเราเป็นแบบชั่วคราวเราอาจระงับ ถอน ยกเลิก หรือเปลี่ยนแปลงบางส่วน หรือทั้งหมดของเว็บไซต์ของเราโดยไม่ต้องแจ้งให้ทราบล่วงหน้า เราจะไม่รับผิดชอบใดๆ ต่อคุณ หากเว็บไซต์ของเราไม่สามารถให้บริการได้ตลอดเวลาหรือในช่วงเวลาใด ๆ คุณมีหน้าที่รับผิดชอบในการทำข้อตกลงที่จำเป็นทั้งหมดสำหรับคุณในการเข้าใช้เว็บไซต์ของเรา คุณมีหน้าที่รับผิดชอบในการตรวจสอบให้แน่ใจว่าบุคคลใดก็ตามที่เข้าใช้เว็บไซต์ของเราผ่านทางอินเทอร์เน็ตของคุณทราบถึงข้อกำหนดในการให้บริการและข้อกำหนดและเงื่อนไขอื่นๆ ที่ระบุไว้ข้างต้นและสอดคล้องกับข้อกำหนดเหล่านี้'
export const webAccessTextEn =
  'เราไม่รับประกันว่าเว็บไซต์หรือเนื้อหาใดๆ ที่มีอยู่ของเราจะพร้อมใช้งานตลอดเวลาหรือไม่มีการหยุดชะงัก การอนุญาตให้เข้าใช้เว็บไซต์ของเราเป็นแบบชั่วคราวเราอาจระงับ ถอน ยกเลิก หรือเปลี่ยนแปลงบางส่วน หรือทั้งหมดของเว็บไซต์ของเราโดยไม่ต้องแจ้งให้ทราบล่วงหน้า เราจะไม่รับผิดชอบใดๆ ต่อคุณ หากเว็บไซต์ของเราไม่สามารถให้บริการได้ตลอดเวลาหรือในช่วงเวลาใด ๆ คุณมีหน้าที่รับผิดชอบในการทำข้อตกลงที่จำเป็นทั้งหมดสำหรับคุณในการเข้าใช้เว็บไซต์ของเรา คุณมีหน้าที่รับผิดชอบในการตรวจสอบให้แน่ใจว่าบุคคลใดก็ตามที่เข้าใช้เว็บไซต์ของเราผ่านทางอินเทอร์เน็ตของคุณทราบถึงข้อกำหนดในการให้บริการและข้อกำหนดและเงื่อนไขอื่นๆ ที่ระบุไว้ข้างต้นและสอดคล้องกับข้อกำหนดเหล่านี้'

export const changeTermTextTh =
  'เราอาจแก้ไขข้อกำหนดเหล่านี้ได้ตลอดเวลาโดยการแก้ไขที่หน้านี้ เราจะใช้วิธีการที่เหมาะสม เช่น การประกาศในสิ่งที่เกี่ยวข้องบนเว็บไซต์ของเราเพื่อแจ้งให้คุณทราบเกี่ยวกับการแก้ไขดังกล่าว อย่างไรก็ตาม เราขอให้คุณตรวจสอบหน้านี้เป็นครั้งคราว เพื่อแจ้งให้ทราบถึงการเปลี่ยนแปลงใดๆ ที่เราทำขึ้น เนื่องจากคุณจะต้องปฏิบัติตามข้อกำหนดในการให้บริการขณะที่คุณใช้งานเว็บไซต์ของเรา หากคุณไม่เห็นด้วยกับการเปลี่ยนแปลงคุณต้องหยุดใช้งานเว็บไซต์'
export const changeTermTextEn =
  'เราอาจแก้ไขข้อกำหนดเหล่านี้ได้ตลอดเวลาโดยการแก้ไขที่หน้านี้ เราจะใช้วิธีการที่เหมาะสม เช่น การประกาศในสิ่งที่เกี่ยวข้องบนเว็บไซต์ของเราเพื่อแจ้งให้คุณทราบเกี่ยวกับการแก้ไขดังกล่าว อย่างไรก็ตาม เราขอให้คุณตรวจสอบหน้านี้เป็นครั้งคราว เพื่อแจ้งให้ทราบถึงการเปลี่ยนแปลงใดๆ ที่เราทำขึ้น เนื่องจากคุณจะต้องปฏิบัติตามข้อกำหนดในการให้บริการขณะที่คุณใช้งานเว็บไซต์ของเรา หากคุณไม่เห็นด้วยกับการเปลี่ยนแปลงคุณต้องหยุดใช้งานเว็บไซต์'

export const lawTextTh =
  'หากคุณเป็นผู้บริโภค ข้อกำหนดการใช้งาน สาระสำคัญ และการพัฒนาเหล่านี้ (และข้อพิพาทหรือสิทธิเรียกร้องใดๆ ที่ไม่อยู่ในสัญญา) จะอยู่ภายใต้กฎหมายอังกฤษ และคุณและเราทั้งสองเห็นพ้องกันว่าศาลอังกฤษและเวลส์จะมีอำนาจที่ศาล อย่างไรก็ตามข้อใดในมาตราข้อ 17 นี้จะทำให้คุณไม่สามารถยื่นคำร้องต่อศาลของประเทศที่คุณพำนักอยู่ภายใต้กฎหมายที่บังคับใช้ในประเทศของคุณ ในสถานการณ์ที่คุณมีสิทธิในการทำเช่นนั้นภายใต้กฎหมายที่บังคับใช้ในท้องถิ่น ถ้าคุณเป็นนักธุรกิจ ข้อกำหนดการใช้งาน สาระสำคัญ และการพัฒนาเหล่านี้ (และข้อพิพาทหรือสิทธิเรียกร้องใดๆ ที่ไม่อยู่ในสัญญา) จะอยู่ภายใต้กฎหมายอังกฤษ เราทั้งสองตกลงที่จะอยู่ภายใต้อำนาจที่ไม่ใช่เฉพาะศาลของอังกฤษและเวลส์เท่านั้น'
export const lawTextEn =
  'หากคุณเป็นผู้บริโภค ข้อกำหนดการใช้งาน สาระสำคัญ และการพัฒนาเหล่านี้ (และข้อพิพาทหรือสิทธิเรียกร้องใดๆ ที่ไม่อยู่ในสัญญา) จะอยู่ภายใต้กฎหมายอังกฤษ และคุณและเราทั้งสองเห็นพ้องกันว่าศาลอังกฤษและเวลส์จะมีอำนาจที่ศาล อย่างไรก็ตามข้อใดในมาตราข้อ 17 นี้จะทำให้คุณไม่สามารถยื่นคำร้องต่อศาลของประเทศที่คุณพำนักอยู่ภายใต้กฎหมายที่บังคับใช้ในประเทศของคุณ ในสถานการณ์ที่คุณมีสิทธิในการทำเช่นนั้นภายใต้กฎหมายที่บังคับใช้ในท้องถิ่น ถ้าคุณเป็นนักธุรกิจ ข้อกำหนดการใช้งาน สาระสำคัญ และการพัฒนาเหล่านี้ (และข้อพิพาทหรือสิทธิเรียกร้องใดๆ ที่ไม่อยู่ในสัญญา) จะอยู่ภายใต้กฎหมายอังกฤษ เราทั้งสองตกลงที่จะอยู่ภายใต้อำนาจที่ไม่ใช่เฉพาะศาลของอังกฤษและเวลส์เท่านั้น'
