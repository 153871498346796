import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import EClaimForm from '../EClaimDetail/EClaimForm'
import { RootState } from '../../redux-store'
import styled from '@emotion/styled'
import Breadcrumb from '../../components/common/Breadcrumb'
import EClaimInfo from '../EClaimDetail/EClaimInfo'
import { useRouter } from '../../utils/helper'
import { useGetEClaimById, useGetClaimById } from '../../services/e-claim/e-claim-query'
import { home } from '../../constant/path'
import { pullAt } from 'lodash'
import { useScreen } from '../../utils/responsive-helper'

const BreadcrumbContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`

const EClaimSummary = () => {
  const { query } = useRouter<{ eClaimId: number }>()
  const { isMobile } = useScreen()
  const { data: eClaimItem } = useGetEClaimById({ claimId: query.eClaimId })
  const { data: claimItem, isLoading } = useGetClaimById({ claimId: query.eClaimId })

  const eClaimFormValue = useSelector((state: RootState) => state.eClaim.claimInfo)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <EClaimForm
        eClaimItem={claimItem}
        claimItem={claimItem}
        eClaimFormValue={eClaimFormValue}
        editable={false}
        isPreview={true}
      />
    </>
  )
}

export default EClaimSummary
