import { api } from '../../utils/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  AppShortcut,
  UserAppShortcut,
  AllAppShortcut,
  GetAppShortcutParams,
  SetAppShortcutProps,
} from './app-shortcut-types'

const ALL_APP_URL = '/web/appShortcut/getAppShortcutList'
const GET_APP_SHORTCUT_URL = '/app/appShortcut/getUserAppShortcut'
const SET_APP_SHORTCUT_URL = '/app/appShortcut/setUserAppShortcut'

export const useGetAllApps = () => {
  return useQuery(ALL_APP_URL, async () => {
    const response = await api.belive.get<AllAppShortcut>(ALL_APP_URL)
    return response
  })
}

export const useGetUserApps = (props: GetAppShortcutParams) => {
  const { userId } = props
  return useQuery([GET_APP_SHORTCUT_URL, userId], async () => {
    const response = await api.belive.get<Array<UserAppShortcut>>(GET_APP_SHORTCUT_URL, {
      user_id: userId,
    })

    return response
  })
}

export const useChangeAppShortcut = () => {
  const queryClient = useQueryClient()

  const mutate = useMutation(
    async (data: SetAppShortcutProps) => {
      return api.belive.post<Array<AppShortcut>>(SET_APP_SHORTCUT_URL, {
        appShortcutList: data.activeAppIdStr,
      })
    },
    {
      onSuccess: (data) => {
        console.log('Update Shortcut Success', data)
        queryClient.invalidateQueries(GET_APP_SHORTCUT_URL)
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}
