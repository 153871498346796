import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export type EClaimInf = {
  claimInfo: {
    id?: number
    name: string
    paymentDate: moment.Moment
    amount: any
    paymentDate_2: moment.Moment
    amount_2: any
    paymentDate_3: moment.Moment
    amount_3: any
    paymentDate_4: moment.Moment
    amount_4: any
    remark: string
    relativeId: string
    allpayRef?: string
    allpayReason?: string
    allpayRemark?: string
    files?: any
    description?: string
    isAccept?: boolean
    isAccept2?: boolean
    isAccept3?: boolean
    isAccept4?: boolean
    subClaimId?: number
    userRemark?: string
    EReceiptList?: any
  }
}

export const initState = {
  claimInfo: {
    name: '',
    paymentDate: moment(),
    amount: 0,
    paymentDate_2: moment(),
    amount_2: 0,
    paymentDate_3: moment(),
    amount_3: 0,
    paymentDate_4: moment(),
    amount_4: 0,
    remark: '',
    relativeId: '',
  },
}

const eClaimSlice = createSlice({
  name: 'eClaim',
  initialState: initState,
  reducers: {
    setClaimInfo: (state, { payload }: PayloadWithType<EClaimInf['claimInfo']>) => {
      state.claimInfo = payload
    },
    clearClaimInfo: (state) => {
      state.claimInfo = initState.claimInfo
    },
  },
})

export const eClaimReducer = eClaimSlice.reducer

export const { setClaimInfo, clearClaimInfo } = eClaimSlice.actions
