import React, { useMemo } from 'react'
import styled from '@emotion/styled'

import MyApp from './MyApp'
import News from './News'
import { mobile, tablet } from '../../utils/responsive-helper'
import { useGetEnrollmentPeriod } from '../../services/enrollment/enrollment-query'
import { isScg } from '../../utils/helper'
import MyAppScg from './MyAppScg'
import Profile from './Profile'
import NewsSlide from './NewsSlide'

const Layout = styled.div`
  padding-bottom: 40px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  background: #FAE4D3;

  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
  }
  
  ${mobile} {
    padding: 0px 16px 40px;
  }
`
const BackgroundHeader = styled.div`
  width: 100%;
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 850px;
  margin: 0 auto;
  z-index: 1;
  
  ${mobile} {
    width: 100%;
  }
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 20px;
  > *:not(:first-of-type) {
    margin-top: 24px;
  }
  ${tablet} {
    max-width: calc(100% - 324px);
  }
  ${mobile} {
    max-width: unset;
  }
`

const Home = () => {
  const { data: enrollmentPeriod } = useGetEnrollmentPeriod()

  return (
    <Layout>
      <BackgroundHeader />
      <FlexRow style={{ marginTop: 22 }}>
        <Profile isShow={true} />
        <NewsSlide />
        <FlexColumn>
          {isScg ? <MyAppScg /> : <MyApp />}
        </FlexColumn>
        <News />
      </FlexRow>
    </Layout>
  )
}

export default Home
