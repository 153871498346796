import styled from '@emotion/styled'
import Breadcrumb from '../../../components/common/Breadcrumb'
import Card from '../../../components/common/Card'
import Text from '../../../components/common/Text'
import { mobile, useScreen } from '../../../utils/responsive-helper'
import Button from '../../../components/common/Button'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import color from '../../../constant/colors'
import MainPanel from '../../../components/common/MainPanel'
import EClaimListComponentScg from '../ECalimListComponentScg'
import Profile from './Profile'

import { Collapse } from 'antd'
import Image from '../../../components/common/Image'
import { useGetUser } from '../../../services/user/user-query'
import { useRouter } from '../../../utils/helper'
import { enrollment } from '../../../constant/path'
import { useMemo } from 'react'

const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin: 0 auto;
  z-index: 1;
  width: 100%;
  
  ${mobile} {
    width: 100%;
  }
`

const ConditionLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1600px;
  margin: 0 auto;
`

const FlexColumn = styled.div`
  display: flex;
  text-align: initial;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin-bottom: 20px;
  > *:not(:first-of-type) {
    margin-top: 24px;
  }
  ${mobile} {
    max-width: unset;
  }
`

const EnrollmentDetail = () => {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const { data: userClaim } = useGetUser()
  const { query } = useRouter<{ claimTypeId: number, relativeId?: number }>()
  const { claimTypeId, relativeId } = query


  const userBudget = userClaim?.userBudget
  const budget = userBudget?.budget || 0
  const budgetRemain = userBudget?.budgetRemain || 0

  const userBudgeDate = useMemo(() => {
    return userClaim?.userBudget?.benefitYear?.endPeriodDate
  }, [userClaim])

  const relativeInfo = useMemo(() => {
    return userClaim?.relatives?.find((item) => item.id === Number(relativeId))
  }, [relativeId, userClaim?.relatives])

  const relativeFullName = useMemo(() => {
    if (language === 'th') {
      return `${relativeInfo?.firstnameTh} ${relativeInfo?.lastnameTh}`
    } else {
      return `${relativeInfo?.firstnameEn} ${relativeInfo?.lastnameEn}`
    }
  }, [relativeInfo, language])

  const relativeBudgetInfo = useMemo(() => {
    return userClaim?.relatives?.find((item) => item.id === Number(relativeId))?.relativeBudget
  }, [relativeId, userClaim?.relatives])

  const relativeBudget = useMemo(() => {
    return relativeBudgetInfo?.budget || 0
  }, [relativeBudgetInfo])

  const relativeBudgetRemain = useMemo(() => {
    return relativeBudgetInfo?.budgetRemain || 0
  }, [relativeBudgetInfo])

  const relativeBudgetDate = useMemo(() => {
    return relativeBudgetInfo?.benefitYear?.endPeriodDate
  }, [relativeBudgetInfo])

  const budgetDate = useMemo(() => {
    if (relativeId) {
      return relativeBudgetDate
    } else {
      return userBudgeDate
    }
  }, [relativeBudgetDate, userBudgeDate, relativeId])

  const budgetUsed = useMemo(() => {
    if (relativeId) {
      return relativeBudgetRemain === relativeBudget ? 0 : relativeBudget - relativeBudgetRemain
    } else {
      return budgetRemain === budget ? 0 : budget - budgetRemain
    }
  }, [relativeBudget, relativeBudgetRemain, budget, budgetRemain, relativeId])

  const budgetRemainAmount = useMemo(() => {
    if (relativeId) {
      return relativeBudgetRemain
    } else {
      return budgetRemain
    }
  }, [relativeBudgetRemain, budgetRemain, relativeId])

  return (
    <>
      <ConditionLayout>
        {
          claimTypeId.toString() === '1' ? (
            <MainPanel
              id={1}
              key={1}
              badge={true}
              imagePath={'imagePath'}
              title={`${relativeId ? `${t('enrollment.fsaBudget')}${relativeFullName}` : t('enrollment.fsaTitle')}`}
              date={budgetDate}
              used={budgetUsed || 0}
              remain={budgetRemainAmount || 0}
              fullBudget={relativeId ? relativeBudget : budget}
              url={enrollment({
                routeParam: {
                  claimTypeId: 1
                },
                queryParam: {
                  relativeId: relativeId || undefined
                }
              })}
            />
          ) :

            <FlexRow style={{ marginTop: 22 }}><Profile /></FlexRow>
        }

        <FlexColumn>
          <EClaimListComponentScg typeId={claimTypeId} relativeId={relativeId} />
        </FlexColumn>
      </ConditionLayout>
    </>
  )
}

export default EnrollmentDetail
