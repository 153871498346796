import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import Card from '../../../components/common/Card'
import Text from '../../../components/common/Text'
import Image, { ImageNameKeys } from '../../../components/common/Image'
import IconCoinBlue from '../../../assets/images/icons-coin-blue-24-px.png'
import IconCoinRed from '../../../assets/images/icons-coin-red-24-px.png'
import IconCoinBlack from '../../../assets/images/icons-coin-black-24-px.png'
import { mobile } from '../../../utils/responsive-helper'
import c from '../../../constant/colors'
import { Space } from 'antd'
import { isScg, numberWithCommas } from '../../../utils/helper'

const LayoutCard = styled(Card)`
  padding: 32px;
  text-align: end;
  ${mobile} {
    padding: 8px;
  }
  .icon-enroll {
    margin-right: 16px;
  }
`

const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`

const Width40 = styled.div`
  width: 40%;
`

const SpaceStyled = styled(Space)`
  min-width: 115px;
  justify-content: space-between;
`

const LayoutLastSummaryExchange = styled.div`
  padding-left: 32;
  border-top: solid 1px;
  border-color: ${c.BACKGROUND};
`

type RowSummaryExchangeProps = {
  title: string
  value?: number
  color: string
  icon: ImageNameKeys
}

const RowSummaryExchange = (props: RowSummaryExchangeProps) => {
  const { title, value, color, icon } = props
  const actuallyValue = value === undefined || value === 0 ? '0' : numberWithCommas(value)
  const minusSymbol = color === c.INPUT_ERROR && actuallyValue !== '-' ? '-' : undefined

  return (
    <RowSpaceBetween>
      <Width40>
        <Text size={22} type={'Bold'}>
          {title}
        </Text>
      </Width40>
      <SpaceStyled>
        <Image
          imageName={icon}
          width={24}
          height={24}
          style={{ objectFit: isScg === true ? 'none' : 'cover' }}
        />
        <Text size={22} type={'Bold'} color={color} style={{ whiteSpace: 'nowrap' }}>
          {minusSymbol}
          {actuallyValue}
        </Text>
      </SpaceStyled>
    </RowSpaceBetween>
  )
}

type SummaryExchangeBenefitsProps = {
  totalAvailableCoins?: number
  totalUsedCoins?: number
  totalEarnedCoins?: number
  totalRemainingCoins?: number
}

const SummaryExchangeBenefits = (props: SummaryExchangeBenefitsProps) => {
  const { totalAvailableCoins, totalUsedCoins, totalEarnedCoins, totalRemainingCoins } = props
  const { t } = useTranslation()
  return (
    <LayoutCard>
      {/* <RowSummaryExchange
        title={'เหรียญที่มีอยู่'}
        value={totalAvailableCoins}
        color={c.BLACK}
        icon={'iconCoin'}
      />
      <RowSummaryExchange
        title={'ใช้ไป'}
        value={totalUsedCoins}
        color={c.INPUT_ERROR}
        icon={'iconCoin'}
      />
      {isScg === false && (
        <RowSummaryExchange
          title={'แลกเป็นเหรียญ'}
          value={totalEarnedCoins}
          color={c.PRIMARY}
          icon={'iconCoin'}
        />
      )} */}

      <LayoutLastSummaryExchange>
        <RowSummaryExchange
          title={Number(totalRemainingCoins) >= 0 ? t('app.coinRemain') : t('app.payAdditional')}
          value={Number(totalRemainingCoins) >= 0 ? totalRemainingCoins : Number(totalRemainingCoins) * -1}
          color={c.PRIMARY}
          icon={'iconCoin'}
        />
      </LayoutLastSummaryExchange>
    </LayoutCard>
  )
}

export default SummaryExchangeBenefits
