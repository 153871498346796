import { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { EClaimHistoryCard } from './component'
import Headbar from '../../components/common/HeadBar'
import Text from '../../components/common/Text'
import Card from '../../components/common/Card'
import * as paths from '../../constant/path'
// import Tabs from '../../components/common/Tabs'
import color from '../../constant/colors'
import { useGetUser } from '../../services/user/user-query'
import { mobile, useScreen } from '../../utils/responsive-helper'
import { numberWithCommas } from '../../utils/helper'

import { useGetEClaimListHistory } from '../../services/e-claim/e-claim-query'
import { ECliamHistoryPageListResponse, EClaimHistory as EClaimHistoryType } from '../../services/e-claim/e-claim-types'
import { Link } from 'react-router-dom'
import { Tabs } from 'antd'
import Modal from '../../components/common/Modal'
import { useVisibility } from '../../utils/custom-hook'
import { Select } from 'antd'
import { IconFilter } from '../../assets/images/images-scg'
import Image from '../../components/common/Image'
import { useTranslation } from 'react-i18next'

const Layout = styled.div`
  margin-bottom: 40px;
`
const PanelConatiner = styled.div`
  padding: 16px;
`
const CustomStyleCard = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding:16px;
  cursor: pointer;
`

const ContentLayout = styled.div`
  background-color: ${color.WHITE};
  padding: 16px;
  border-radius: 16px;
  min-height: 500px;
`
const EClaimCardLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`

const TabsLayout = styled.div`
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 16px;
    font-weight: 600;
    color: ${color.PRIMARY};
  }

  .ant-tabs-tab {
    font-size: 16px;
    font-weight: 600;
  }
 
  .ant-tabs-nav {
    margin-bottom: 24px;
    margin: 0 60px;
  }

  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
    margin: 0;
  }

  .ant-tabs-ink-bar {
    background: ${color.PRIMARY} !important;
  }
`

const LayoutContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: left;
  margin-top: 22px;
`
const LayoutBodyBottom = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 24px;

  ${mobile} {
    max-width: 150px;
  }
`

const LayoutDate = styled.div`
  margin-right: 24px;
  display: flex;
  flex-direction: column;

  ${mobile} {
    margin-top: 5px;
  }
`

const LayoutFilter = styled.div`
  margin-right: 24px;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 12px;
  cursor: pointer;

  ${mobile} {
    margin-top: 5px;
  }
`

const RowTop = styled.div`
display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #E5E5E5;
`
const RowBottom = styled.div`
display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 16px;
`

const LayoutBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 0 12px 24px;

  ${mobile} {
    max-width: 150px;
  }
`
const SelectContainer = styled.div`
    border-radius: 6px;
    width: 400px;
`

const Icon = styled(Image)`
  margin-top: 4px;
  padding-right: 6px;
  height: 16px;
`


type EClaimHistoryPageProps = {
  data: ECliamHistoryPageListResponse
  status: string
}
const EClaimHistoryPage = (props: Partial<EClaimHistoryPageProps>) => {
  let data = props.status !== "" ? props.data?.filter((item: EClaimHistoryType) => item.claimStatus === props.status) : props.data
  return (
    <EClaimCardLayout>
      {data?.map((item: EClaimHistoryType) => (
        <Link
          key={item.id}
          to={paths.eClaimHistoryDetail({ routeParam: { id: item.id } })}
        >
          <EClaimHistoryCard data={item} />
        </Link>
      ))}
    </EClaimCardLayout>
  )
}

const EClaimHistory = () => {
  const { isMobile } = useScreen()
  const { data: EClaimHistoryList, isLoading } = useGetEClaimListHistory()
  const { data: userClaim } = useGetUser()
  const userBudget = userClaim?.userBudget
  const budget = userBudget?.budget || 0
  const budgetRemain = userBudget?.budgetRemain || 0
  const relatives = userClaim?.relatives
  const [visible, modalAction] = useVisibility()
  const { t, i18n } = useTranslation()
  const [status, setStatus] = useState("")
  const listCountry = [
    { label: "All", value: "" },
    { label: "Approved", value: "AP" },
    { label: "Pending", value: "PD" },
    { label: "Rejected", value: "RE" },
    { label: "Cancelled", value: "CC" },
  ]

  const statusText = useMemo(() => {
    let statusText = ''
    switch (status) {
      case 'AP':
        statusText = 'Approved'
        break;
      case 'RE':
        statusText = 'Rejected'
        break;
      case 'PD':
        statusText = 'Pending'
        break;
      case 'CC':
        statusText = 'Cancelled'
        break;
      case '':
        statusText = 'All'
        break;
      default: statusText = 'All'
        break;
    }
    return statusText
  }, [status])

  const handleSelectCountry = (selected: string) => {
    setStatus(selected)
  }

  return (
    <>
      <Layout style={{ paddingTop: '24px' }}>
        <Modal
          visible={visible}
          onCancel={() => {
            modalAction.hide()
          }}
          onCancelText={t('newsDetail.modalButtonText')}
          hideCloseIcon
        >
          <SelectContainer>
            {/* <div style={{ width: 400 }}> */}
            <Select
              placeholder={t('eClaim.field.status')}
              options={listCountry}
              defaultValue=""
              onChange={(value: any) =>
                handleSelectCountry(value)
              }
              style={{ width: "100%" }}
            >
              {listCountry.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.value}>
                    <Text size={18} type="Medium">
                      {item.label}
                    </Text>
                  </Select.Option>
                )
              })}
            </Select>
            {/* </div> */}
          </SelectContainer>
        </Modal>
        <Headbar title={t('main.history')} backWard={paths.main()} />
        <ContentLayout>
          <TabsLayout>
            <Tabs>
              <Tabs.TabPane tab={'FSA Claim (Employee)'} key="item-1">
                <LayoutFilter onClick={() => modalAction.show()}>
                  <Text maxLine={2} size={16} type="Medium">
                    {statusText}
                  </Text>
                  <Icon src={IconFilter} />
                </LayoutFilter>
                <PanelConatiner>
                  <CustomStyleCard>
                    <RowTop>
                      <LayoutBody>
                        <Text ellipsis maxLine={1} size={18} type="Bold">
                          {t('enrollment.fsaTitle')}
                        </Text>

                      </LayoutBody>
                    </RowTop>
                    <LayoutContent>
                      <LayoutBodyBottom>
                        <Text ellipsis maxLine={1} size={16} type="Medium">
                          {t('home.spendingBudget')}
                        </Text>
                        <Text ellipsis maxLine={2} size={14} type="Medium" color={color.LIGHT_GRAY}>
                          {numberWithCommas(budgetRemain === budget ? 0 : budget - budgetRemain) || 0} ฿
                        </Text>
                      </LayoutBodyBottom>
                      <LayoutDate>
                        <Text maxLine={2} size={18} type="Medium">
                          {t('home.remainingBudget')}
                        </Text>
                        <Text ellipsis maxLine={1} size={14} type="Medium" color={color.PRIMARY}>
                          {numberWithCommas(userClaim?.userBudget?.budgetRemain || 0)} ฿
                        </Text>
                      </LayoutDate>
                    </LayoutContent>
                  </CustomStyleCard>
                </PanelConatiner>

                {relatives?.map((relative: any, index: any) =>
                  <PanelConatiner>
                    <CustomStyleCard>
                      <RowTop>
                        <LayoutBody>
                          <Text ellipsis maxLine={1} size={18} type="Bold">
                            {`${t('enrollment.fsaBudget')}${i18n.language === 'en' ? `${relative.firstnameEn} ${relative.lastnameEn}` : `${relative.firstnameTh} ${relative.lastnameTh}`}`} ({relative.relativeType.name})
                          </Text>

                        </LayoutBody>
                      </RowTop>
                      <LayoutContent>
                        <LayoutBodyBottom>
                          <Text ellipsis maxLine={1} size={16} type="Medium">
                            {t('home.spendingBudget')}
                          </Text>
                          <Text ellipsis maxLine={2} size={14} type="Medium" color={color.LIGHT_GRAY}>
                            {numberWithCommas(relative.relativeBudget.budgetRemain === relative.relativeBudget.budget ? 0 : relative.relativeBudget.budget - relative.relativeBudget.budgetRemain) || 0} ฿
                          </Text>
                        </LayoutBodyBottom>
                        <LayoutDate>
                          <Text maxLine={2} size={16} type="Medium">
                            {t('home.remainingBudget')}
                          </Text>
                          <Text ellipsis maxLine={1} size={14} type="Medium" color={color.PRIMARY}>
                            {numberWithCommas(relative.relativeBudget.budgetRemain)} ฿
                          </Text>
                        </LayoutDate>
                      </LayoutContent>
                    </CustomStyleCard>
                  </PanelConatiner>
                )}

                <EClaimHistoryPage data={EClaimHistoryList?.claims?.fsa} status={status} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={'Cash and Gift as per life event'} key="item-2">
                <LayoutFilter onClick={() => modalAction.show()}>
                  <Text maxLine={2} size={16} type="Medium">
                    {statusText}
                  </Text>
                  <Icon src={IconFilter} />
                </LayoutFilter>
                <EClaimHistoryPage data={EClaimHistoryList?.claims?.giftLifeEvent} status={status} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={'Natural Disaster'} key="item-3">
                <LayoutFilter onClick={() => modalAction.show()}>
                  <Text maxLine={2} size={16} type="Medium">
                    {statusText}
                  </Text>
                  <Icon src={IconFilter} />
                </LayoutFilter>
                <EClaimHistoryPage data={EClaimHistoryList?.claims?.naturalDisaster} status={status} />
              </Tabs.TabPane>
            </Tabs>
          </TabsLayout>
        </ContentLayout>
      </Layout >
    </>
  )
}
export default EClaimHistory 
