import React, { ReactNode, CSSProperties } from 'react'
import styled from '@emotion/styled'
import { Badge as AntdBadge } from 'antd'
import { BadgeProps } from 'antd/lib/badge'

const CustomAntBadge = styled(AntdBadge)`
  .ant-badge-dot {
    width: 20px;
    height: 20px;
  }
`

type CustomBadge = {
  count?: string
  children?: ReactNode
  style?: CSSProperties
} & Pick<
  BadgeProps,
  | 'color'
  | 'count'
  | 'dot'
  | 'offset'
  | 'overflowCount'
  | 'showZero'
  | 'size'
  | 'status'
  | 'text'
  | 'title'
>

const Badge = (props: CustomBadge) => {
  const { count, children, style, ...restProps } = props
  return (
    <CustomAntBadge count={count} style={style} {...restProps}>
      {children}
    </CustomAntBadge>
  )
}

export default Badge
