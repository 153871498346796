import styled from '@emotion/styled'
import { Col, Row } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
// import IconSuccess from '../../assets/images/base/infographic-reward-success.svg'
import IconSuccess from '../../assets/images/base/reward-success.png'
import FooterContent from '../../components/FooterContent'
import Button from '../../components/common/Button'
import Card from '../../components/common/Card'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import { main } from '../../constant/path'
import { numberWithCommas, useRouter } from '../../utils/helper'
import { mobile, useScreen } from '../../utils/responsive-helper'

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const RowCustom = styled.div`
  display: flex;
  flex-direction: row;
`

const RowCenter = styled(RowCustom)`
  text-align: center;
  justify-content: center;
`

const RowSpaceBetween = styled(RowCustom)`
  margin-top: 24px;
  justify-content: space-between;
`

const LayoutImage = styled(RowCenter)`
  width: 100%;
  height: 120px;

  ${mobile} {
    height: 100px;
  }
`

const LayoutCard = styled(Card)`
  padding: 32px;
  margin-bottom: 80px;

  ${mobile} {
    padding: 16px;
    margin-bottom: 40px;
  }
`

const PaddingText = styled.div`
  padding-top: 8px;
  padding-bottom: 16px;
`
const PaddingSubText = styled.div`
  padding-top: 24px;
  padding-bottom: 32px;
`

const PaddingImage = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`

const TextNotWrap = styled(Text)`
  white-space: nowrap;
  float: right;
`

const MarginTop = styled.div`
  margin-top: 64px;

  ${mobile} {
    margin-top: 24px;
  }
`

const ButtonStyle = styled(Button)`
  width: 267px;
`

const EClaimComplete = () => {
  const { t, i18n } = useTranslation()
  const { eClaimName, eClaimPrice, eClaimNumber,
    eClaimName_2, eClaimPrice_2, eClaimNumber_2,
    eClaimName_3, eClaimPrice_3, eClaimNumber_3,
    eClaimName_4, eClaimPrice_4, eClaimNumber_4

  } = useRouter().query
  const { isMobile } = useScreen()
  const history = useHistory()
  const onClickBackToEnrollment = useCallback(() => {
    history.push(main())
  }, [history])


  const multiName = useCallback((value) => {
    let multiName = ''
    if (i18n.language === 'en') {
      switch (value) {
        case 'เงินช่วยเหลือจำเป็นเร่งด่วน':
          multiName = 'Emergency Allowance'
          break;
        case 'ค่าที่พักอาศัยชั่วคราว':
          multiName = 'Temporary accommodation fee'
          break;
        case 'ค่าเดินทางตามความจำเป็นเร่งด่วน':
          multiName = 'Travel expenses as needed'
          break;
        case 'เงินช่วยเหลือค่าความเสียหายของบ้าน และ/หรือ สิ่งของเครื่องใช้ภายในบ้าน ตามนโยบาย':
          multiName = 'Damage aid to houses and/or home appliances according to policy'
          break;
        default: multiName = value
          break;
      }
    } else {
      multiName = value
    }

    return multiName
  }, [eClaimName])

  return (
    <>
      <Column>
        <MarginTop />
        <RowCenter>
          <Text size={26} type={'Bold'} color={color.FONT_BLACK}>
            {' '}
            {t('eClaim.info.submitted')}
            {' '}
          </Text>
        </RowCenter>

        <PaddingImage>
          <LayoutImage>
            <Image src={IconSuccess} />
          </LayoutImage>
        </PaddingImage>
        <PaddingSubText>
          <RowCenter>
            <Text size={20} color={color.PRIMARY}>
              {t('eClaim.context.fifth')} <br />
              {t('eClaim.context.sixth')}
            </Text>
          </RowCenter>
        </PaddingSubText>
        <LayoutCard>
          <Column>
            {/* <Row>
              <Text size={24} type={'Bold'} color={color.FONT_BLACK}>
                รายละเอียดสวัสดิการที่ยื่นคำขอ{' '}
              </Text>
            </Row> */}

            <Row>
              <Col span={8}>
                <Text size={24} type={'Bold'} color={color.FONT_BLACK}>
                  {t('app.titleEclaim')}{' '}
                </Text>
              </Col>

              <Col span={8}>
                <TextNotWrap size={20} type={'Bold'} color={color.FONT_BLACK}>
                  {t('app.orderNumber')}
                </TextNotWrap>
              </Col>

              <Col span={8} >
                <TextNotWrap size={20} type={'Bold'} color={color.FONT_BLACK}>
                  {t('app.amount')}
                </TextNotWrap>
              </Col>
            </Row>

            {/* <RowSpaceBetween>
              <Text size={24} type={'Bold'} color={color.FONT_BLACK}>
                รายละเอียดสวัสดิการที่ยื่นคำขอ{' '}
              </Text>
              <Text size={20} type={'Bold'} color={color.FONT_BLACK}>
                รายการหมายเลข
              </Text>
              <TextNotWrap size={20} type={'Bold'} color={color.FONT_BLACK}>
                จำนวนเงิน
              </TextNotWrap>
            </RowSpaceBetween> */}


            {
              eClaimName && (
                <Row>
                  <Col span={8}>
                    <Text size={20} type={'Medium'} color={color.FONT_BLACK}>
                      {multiName(eClaimName)}
                    </Text>
                  </Col>

                  <Col span={8}>
                    <TextNotWrap size={20} type={'Medium'} color={color.FONT_BLACK}>
                      {eClaimNumber}
                    </TextNotWrap>
                  </Col>

                  <Col span={8}>
                    <TextNotWrap size={20} type={'Bold'} color={color.FONT_BLACK}>
                      {numberWithCommas(eClaimPrice)} ฿
                    </TextNotWrap>
                  </Col>
                </Row>
              )
            }

            {
              eClaimName_2 && (
                <Row>
                  <Col span={8}>
                    <Text size={20} type={'Medium'} color={color.FONT_BLACK}>
                      {multiName(eClaimName_2)}
                    </Text>
                  </Col>

                  <Col span={8}>
                    <TextNotWrap size={20} type={'Medium'} color={color.FONT_BLACK}>
                      {eClaimNumber_2}
                    </TextNotWrap>
                  </Col>

                  <Col span={8}>
                    <TextNotWrap size={20} type={'Bold'} color={color.FONT_BLACK}>
                      {numberWithCommas(eClaimPrice_2)} ฿
                    </TextNotWrap>
                  </Col>
                </Row>
              )
            }

            {
              eClaimName_3 && (
                <Row>
                  <Col span={8}>
                    <Text size={20} type={'Medium'} color={color.FONT_BLACK}>
                      {multiName(eClaimName_3)}
                    </Text>
                  </Col>

                  <Col span={8}>
                    <TextNotWrap size={20} type={'Medium'} color={color.FONT_BLACK}>
                      {eClaimNumber_3}
                    </TextNotWrap>
                  </Col>

                  <Col span={8}>
                    <TextNotWrap size={20} type={'Bold'} color={color.FONT_BLACK}>
                      {numberWithCommas(eClaimPrice_3)} ฿
                    </TextNotWrap>
                  </Col>
                </Row>
              )
            }

            {
              eClaimName_4 && (
                <Row>
                  <Col span={8}>
                    <Text size={20} type={'Medium'} color={color.FONT_BLACK}>
                      {multiName(eClaimName_4)}
                    </Text>
                  </Col>

                  <Col span={8}>
                    <TextNotWrap size={20} type={'Medium'} color={color.FONT_BLACK}>
                      {eClaimNumber_4}
                    </TextNotWrap>
                  </Col>

                  <Col span={8}>
                    <TextNotWrap size={20} type={'Bold'} color={color.FONT_BLACK}>
                      {numberWithCommas(eClaimPrice_4)} ฿
                    </TextNotWrap>
                  </Col>
                </Row>
              )
            }

          </Column>
        </LayoutCard>
      </Column>
      <FooterContent
        style={{
          height: 80,
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'flex-end',
          alignItems: 'center',
        }}
      >
        {<ButtonStyle onClick={onClickBackToEnrollment}>{t('eClaim.homeButton')}</ButtonStyle>}
      </FooterContent>
    </>
  )
}

export default EClaimComplete
