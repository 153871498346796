export const PRIMARY = '#E87722'
export const SHOP_PRIMARY = '#55B6E3'
export const SECONDARY = '#B1C800'

export const WHITE = '#FFFFFF'
export const BACKGROUND = '#F5F7FA'
export const BACKGROUND_LIGHT_GRAY = '#F4F4F4'
export const BACKGROUND_LIGHT = '#F2F2F2'

export const DARK_RED = '#A61C1A'

export const BLACK = '#183028'
export const FONT_BLACK = '#363640'

export const LIGHT_GRAY = '#C1C1C1'
export const MEDIUM_GRAY = '#9F9F9F'
export const DARK_GRAY = '#4D4D4D'
export const FONT_LIGHT_GRAY = '#8C8C8C'
export const FONT_DARK_GRAY = '#636363'

export const LIGHT_BLUE = '#45BFF9'

export const ORANGE = '#FE7339'
export const LIGHT_ORANGE = '#FFF1EB'

export const YELLOW_STAR = '#FFCA27'

export const GREEN = '#50B948'

export const BORDER = '#E5E5E5'
export const INPUT_LABLE = '#3F3D56'
export const INPUT_ERROR = '#C44536'

export const ADD_APP_ICON = '#DADFE3'
export const HORIZONTAL_BAR = '#E1F1FE'

export const FONT_GRAY = '#8c8c8c'
