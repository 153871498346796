import qs from 'querystring'
import { EClaimEnllomentItem } from '../services/e-claim/e-claim-types'

type ParamType = { [p: string]: any } | undefined
type Option<T extends ParamType, V extends ParamType> = {
  routeParam?: T
  queryParam?: V
}

const generate =
  <T extends ParamType = undefined, V extends ParamType = undefined>(url: string) =>
    (option?: Option<T, V>) => {
      const { routeParam, queryParam } = option || {}
      let newQueryParam = ''
      if (queryParam) {
        newQueryParam = `?${qs.stringify(queryParam)}`
      }

      let newUrl = url
      if (routeParam) {
        const urls = url.split('/')
        newUrl = urls
          .map((u) => {
            if (/:.+/.test(u)) {
              return routeParam[u.slice(1)]
            }
            return u
          })
          .join('/')
      }

      return `${newUrl}${newQueryParam}`
    }

export const notFound = generate('/404')
export const login = generate('/login')
export const authCallback = generate<{ token: string }>('/saml/callback')
export const home = generate('/home')
export const main = generate('/main')
export const news = generate<undefined, { type: string }>('/news')
export const newsDetails = generate<{ newsId: number }>('/news/:newsId')
export const play = generate('/play')
export const playDetail = generate<{ playId: number }>('/play/:playId')

export const profile = generate(`${home()}/profile`)
export const about = generate(`${home()}/about`)
export const contact = generate(`${home()}/contact`)
export const termAndCondition = generate(`${home()}/term_and_condition`)
export const shop = generate('/shop')
export const shopUser = generate('/shop_user')
export const shopCart = generate(`${shopUser()}/cart`)
export const shopUserCoin = generate(`${shopUser()}/coin`)
export const promotion = generate('/promotion')
export const promotionCategory = generate('/promotion_categories')
export const promotionCategoryDetail = generate<{ promotionId: number }>(
  '/promotion_categories/:promotionId',
)
export const coupon = generate('/coupon')
export const couponDetail = generate<{ couponId: number }, { page: string }>('/coupon/:couponId')
export const products = generate('/products')
export const product = generate('/product')
export const productDetail = generate<{ productId: number }, { page: string }>(
  `/product/:productId`,
)
export const productDetailDescription = generate<{ productId: number }>(
  `/product/:productId/description`,
)
export const order = generate('/order')
export const shopBrand = generate<{ brandId: number }, { title: string }>('/brands/:brandId')
export const shopCategory = generate<{ categoryId: number }, { title: string }>(
  '/shop_categories/:categoryId',
)
export const categoryReward = generate<{ categoryId: number; rewardId: number }, { title: string }>(
  '/shop_categories/:categoryId/rewards/:rewardId',
)
export const brandReward = generate<{ brandId: number; rewardId: number }, { title: string }>(
  '/brands/:brandId/rewards/:rewardId',
)
export const claimCategoryReward = generate<{ categoryId: number; rewardId: number }>(
  '/shop_categories/:categoryId/rewards/:rewardId/claim_reward',
)
export const claimBrandReward = generate<{ brandId: number; rewardId: number }>(
  '/brands/:brandId/rewards/:rewardId/claim_reward',
)
export const notification = generate(`${home()}/notifcations`)
export const app = generate(`${home()}/app`)
export const pointHistory = generate(`${home()}/point_history`)

// My Benefit Page
export const enrollment = generate<{ claimTypeId: number }, { relativeId?: number }>(`/enrollment/:claimTypeId`)
export const benefits = generate<{ benefitYearId: number }>(`${enrollment()}/self`)
export const editSelfBenefits = generate<{ benefitYearId: number }, { benefitsName: string }>(
  `${benefits()}/edit`,
)
export const editRelativeBenefits = generate<
  { benefitYearId: number; relativeId: number },
  { benefitsName: string }
>(`${benefits()}/relatives/:relativeId/edit`)
export const enrollmentLeave = generate<{ benefitYearId: number }>(`${benefits()}/leave`)
export const enrollmentInsuranceFamily = generate<{ benefitYearId: number }>(
  `${benefits()}/insurance_family`,
)
export const insuranceFamilyForm = generate<
  { benefitYearId: number },
  { relativeTypeId: number; relativeId?: number; typeName: string }
>(`${benefits()}/insurance_family/form`)
export const enrollmentWellBeing = generate<{ benefitYearId: number }>(`${benefits()}/well-being`)
export const enrollmentWellBeingEdit = generate<{ benefitYearId: number; claimTypeId: string }>(
  `${benefits()}/well-being/:claimTypeId/edit`,
)

// E-Claim Move to Enrollment
export const eClaim = generate(`/e_claim`)
export const eClaimList = generate(`/e_claimlist`)
export const eClaimHistoryDetail = generate<{ id: number }>(`${eClaimList()}/history/:id`)
export const eClaimDetail = generate<{ eClaimId: number }, { relativeId?: number }>(`${eClaim()}/:eClaimId`)
export const eClaimSummary = generate<{ eClaimId: number }>(`${eClaimDetail()}/summary`)
export const eClaimCompleted = generate<
  { eClaimId: number },
  { eClaimName: string; eClaimPrice: number; eClaimNumber: string; eClaimNameEn: string }
>(`${eClaimDetail()}/completed`)


export const eClaimDetailDisaster = generate<{ eClaimId: number }>(`${eClaim()}_d`)
export const eClaimSummaryDisaster = generate<{ eClaimId: number }>(`${eClaim()}_d/summary`)
export const eClaimCompletedDisaster = generate<
  { eClaimId: number },
  {
    eClaimName?: string; eClaimPrice?: number; eClaimNumber?: string; eClaimNameEn?: string
    eClaimName_2?: string; eClaimPrice_2?: number; eClaimNumber_2?: string; eClaimNameEn_2?: string
    eClaimName_3?: string; eClaimPrice_3?: number; eClaimNumber_3?: string; eClaimNameEn_3?: string
    eClaimName_4?: string; eClaimPrice_4?: number; eClaimNumber_4?: string; eClaimNameEn_4?: string
  }
>(`${eClaim()}_d/completed`)


export const eClaimHistory = generate(`${eClaimList()}/history`)