import { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import color from '../../constant/colors'
import { mobile, useScreen } from '../../utils/responsive-helper'
import { numberWithCommas } from '../../utils/helper'

import Card from './Card'
import Image from './Image'
import Text from './Text'
import Badge from './Badge'
import { Tooltip } from 'antd'
import * as paths from '../../constant/path'
import { useHistory } from 'react-router'
import config from '../../config'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const CustomStyleCard = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  padding:16px;
  cursor: pointer;
`

const LayoutImage = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 24px;
  margin-right: 24px;

  .ant-badge-dot {
    top: 8px;
    right: 8px;
  }

  ${mobile} {
    margin-top: 32px;
  }
`

const LayoutContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: left;
  margin-top: 22px;
`

const LayoutBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 0 16px 24px;

  ${mobile} {
    max-width: 150px;
  }
`

const LayoutBodyBottom = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 24px;
  ${mobile} {
    max-width: 150px;
  }
`

const LayoutDate = styled.div`
  padding-top:  40px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;

  ${mobile} {
    margin-top: 5px;
  }
`

const CustomStyleImage = styled(Image)`
  border-radius: 50%;
  width: 72px;
  height: 72px;
`

const RowTop = styled.div`
display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #E5E5E5;
`
const RowBottom = styled.div`
display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 16px;
`
const FullBudgetLayout = styled.div`
  padding-bottom: 16px;
`


type NotificationCardProps = {
  id: number
  badge: boolean
  imagePath: string
  title: string
  date?: string
  used: number
  remain: number
  url: string
  fullBudget: number
  // onCardClick: () => void
}

const MainPanel = (props: NotificationCardProps) => {
  const { t } = useTranslation()
  const { isMobile } = useScreen()
  const { badge, imagePath = '', title, used, remain, url, date, fullBudget } = props
  const history = useHistory()
  const onClickItem = useCallback(() => {
    history.push(url)
  }, [history, url])

  return (
    <>
      <CustomStyleCard onClick={onClickItem}>
        <RowTop>
          {/* <LayoutImage>
            <Badge dot={!badge}>
              <CustomStyleImage src={`${config.apiHost}${imagePath}`} />
            </Badge>
          </LayoutImage> */}
          <LayoutContent>
            <LayoutBody>
              <Text ellipsis maxLine={1} size={18} type="Medium">
                {title}
              </Text>
              {/* <Text ellipsis maxLine={2} size={16} type="Medium" color={color.LIGHT_GRAY}>
                {t('home.validUntil')} {moment(date).format('DD MMM YYYY')}
              </Text> */}
            </LayoutBody>
          </LayoutContent>
        </RowTop>

        <RowBottom>
          <LayoutContent>
            <LayoutBodyBottom>
              <FullBudgetLayout>
                <Text>
                  {t('home.annualBudget')} {numberWithCommas(fullBudget)} ฿
                </Text>
              </FullBudgetLayout>
              <Text ellipsis maxLine={1} size={18} type="Medium">
                {t('home.spendingBudget')}
              </Text>
              <Text ellipsis maxLine={2} size={16} type="Medium" color={color.LIGHT_GRAY}>
                {numberWithCommas(used)} ฿
              </Text>
            </LayoutBodyBottom>
            <LayoutDate>
              <Text maxLine={2} size={18} type="Medium">
                {t('home.remainingBudget')}
              </Text>
              <Text ellipsis maxLine={1} size={16} type="Medium" color={color.PRIMARY}>
                {numberWithCommas(remain)} ฿
              </Text>
            </LayoutDate>
          </LayoutContent>
        </RowBottom>


      </CustomStyleCard>
    </>
  )
}

export default MainPanel
