import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import CategoryGroup from '../../components/common/CategoryGroup'
import Text from '../../components/common/Text'
import Image from '../../components/common/Image'
import Button from '../../components/common/Button'
import color from '../../constant/colors'
import { useCallback } from 'react'
import { useHistory } from 'react-router'
import MaskImage from '../../assets/images/base/mask@2x.jpg'
import { IconHealth, IconNew, IconPlay, IconInsurance, IconCheckup } from '../../assets/images/images-scg'
import { tablet } from '../../utils/responsive-helper'
import MainPanel from '../../components/common/MainPanel'
import { enrollment } from '../../constant/path'
import { UserType } from '../../services/user/user-typed'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  border: 0.5px solid #DDDDDD;
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom:16px;
  user-select: none;
  :hover {
    cursor: pointer;
  }

  ${tablet} {
    width: 100%;
  }
`
const CustomImage = styled(Image)`
  width: 22px;
  height: 22px;
`
const AddAppIconLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 83px;
  height: 83px;
  border-radius: 20px;
  border: solid 2px ${color.ADD_APP_ICON};
`

type ItemProps = {
  src: string
  label: string
  url: string
  type?: string
  description: string
}

const Item = (props: ItemProps) => {
  const { src, label, url, type, description, ...rest } = props
  const history = useHistory()
  const onClickItem = useCallback(() => {
    history.push(url)
  }, [history, url])
  return (
    <>
      <Layout {...rest}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: 8,
        }} onClick={onClickItem}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 8,
          }}>
            <CustomImage src={src || MaskImage} />
            <Text size={16} type="Bold" style={{ textAlign: 'center', marginTop: 8, whiteSpace: 'nowrap' }}>
              {label}
            </Text>
          </div>
          <Text size={10}>{description}</Text>
          {/* <Text size={16} style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            {labelEn}
          </Text> */}
        </div>
      </Layout>
    </>
  )
}

type EClaimListProps = {
  userClaim: UserType
}
const EclaimList = (props: EClaimListProps) => {
  const { t, i18n } = useTranslation()
  const { userClaim } = props
  const { relatives } = userClaim

  return (
    <>
      {relatives !== null && relatives?.length !== 0 && (
        <CategoryGroup label={t('home.category.myApp.eclaim.mainTitle')} style={{ marginBottom: 16 }}>
          {relatives?.map((relative, index) =>
            <MainPanel
              id={index}
              key={index}
              badge={true}
              imagePath={'imagePath'}
              title={i18n.language === 'en' ? `${relative.firstnameEn} ${relative.lastnameEn}` : `${relative.firstnameTh} ${relative.lastnameTh}`}
              date={relative.relativeBudget.benefitYear.endPeriodDate}
              used={(relative.relativeBudget.budgetRemain === relative.relativeBudget.budget ? 0 : relative.relativeBudget.budget - relative.relativeBudget.budgetRemain) || 0}
              remain={relative.relativeBudget.budgetRemain}
              fullBudget={relative.relativeBudget.budget}
              url={enrollment({
                routeParam: {
                  claimTypeId: 1
                },
                queryParam: {
                  relativeId: relative.relativeBudget.relativeId
                }
              })}
            />
          )}
        </CategoryGroup>
      )}

    </>
  )
}

export default EclaimList
