import styled from '@emotion/styled'
import { DatePicker as AntdDatePicker } from 'antd'
import { DatePickerProps as AntdDatePickerProps } from 'antd/lib/date-picker'
import moment from 'moment'
import locale from 'antd/lib/date-picker/locale/th_TH'
import color from '../../constant/colors'
import { CSSProperties, useCallback } from 'react'
import { InputLabel } from './Input'
import dayjs, { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'

const DatePickerLayout = styled.div``

const CustomAntdDatePicker = styled(AntdDatePicker)`
  padding: 13px 17px;
  border-radius: 4px;
  border-color: ${color.LIGHT_GRAY};
  font-family: NotoSansThai-Medium;
  font-size: 16px;
  line-height: 18px;
  height: 47px;
  width: 100%;
`

const dateFormat = 'DD/MM/YYYY'

export type DatePickerProps = {
  label?: string
  style?: CSSProperties
  datePickerStyle?: CSSProperties
  minDate?: Dayjs
  maxDate?: Dayjs
} & Omit<AntdDatePickerProps, 'disabledDate'>

const DatePicker = (props: DatePickerProps) => {
  const {
    value,
    onChange,
    label,
    style,
    datePickerStyle,
    minDate,
    maxDate,
    onBlur,
    className,
    ...restProps
  } = props
  const { t } = useTranslation()

  const disabledDate = useCallback(
    (current) => {
      const currentDate = dayjs(current).endOf('day')
      if (
        (current && minDate && currentDate < minDate.endOf('day')) ||
        (maxDate && currentDate > maxDate.endOf('day'))
      ) {
        return true
      } else {
        return false
      }
    },
    [maxDate, minDate],
  )
  return (
    <DatePickerLayout style={{ ...style }} className={className}>
      {label && <InputLabel size={18}>{label}</InputLabel>}
      <CustomAntdDatePicker
        value={moment(value).isValid() ? moment(value) : undefined}
        onChange={onChange}
        // locale={locale}
        format={dateFormat}
        placeholder={t('app.selectDate')}
        style={datePickerStyle}
        disabledDate={disabledDate}
        {...restProps}
      />
    </DatePickerLayout>
  )
}

export default DatePicker
