import Breadcrumb from '../../components/common/Breadcrumb'
import styled from '@emotion/styled'
import EClaimForm from './EClaimDisasterForm'
import { useRouter } from '../../utils/helper'
import { useGetClaimById } from '../../services/e-claim/e-claim-query'
import EClaimInfo from './EClaimDisasterInfo'

import Loading from '../../components/common/Loading'
import { eClaimDetail, enrollment, home } from '../../constant/path'
import { mobile, useScreen } from '../../utils/responsive-helper'
import { useMemo } from 'react'
import Point from '../../components/common/Point'

const BreadcrumbContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  
  ${mobile} {
    margin: 24px 16px;
  }
`

const ProfileLayout = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: inherit;
  padding-right: 16px;
  
  .left {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
  }
`

const EClaimDetail = () => {
  const { isMobile } = useScreen()
  const { data: claimItem, isLoading } = useGetClaimById({ claimId: 23 })

  const initialValue = useMemo(() => {
    return {
      relativeId: '0',
    }
  }, [])

  return (
    <Loading loading={isLoading}>
      <ProfileLayout>
        <div className='left' />
        <div className='right'>
          {/* <Point /> */}
        </div>
      </ProfileLayout>
      <EClaimForm claimItem={claimItem} initialValues={initialValue} />
    </Loading>
  )
}

export default EClaimDetail
