import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useGetUserApps } from '../../services/app/app-shortcut-query'
import CategoryGroup from '../../components/common/CategoryGroup'
import Text from '../../components/common/Text'
import Image from '../../components/common/Image'
import AddAppImage from '../../assets/images/base/icons-miscellaneous-circle-plus-24-px.svg'
import color from '../../constant/colors'
import { MouseEventHandler, useCallback, useState } from 'react'
import { useGetUser } from '../../services/user/user-query'
import { UserAppShortcut } from '../../services/app/app-shortcut-types'
import { useHistory } from 'react-router'
import * as paths from '../../constant/path'
import MaskImage from '../../assets/images/base/mask@2x.jpg'
import { isMobile, isIOS, isAndroid } from 'react-device-detect'
import Modal from '../../components/common/Modal'
import Button from '../../components/common/Button'
import { useVisibility } from '../../utils/custom-hook'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 83px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`
const CustomImage = styled(Image)`
  width: 83px;
  height: 83px;
  border-radius: 20px;
`
const AddAppIconLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 83px;
  height: 83px;
  border-radius: 20px;
  border: solid 2px ${color.ADD_APP_ICON};
`

const FooterSubmitInsuranceModal = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  padding: 24px;
`

const BenefitSelectButton = styled(Button)`
  width: min-content;
  border-color: ${color.PRIMARY};
  min-width: 165px;
`

const ContentSubmitInsuranceModal = styled.div`
  padding: 60px 0px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`

type ItemProps = {
  src: string
  label: string
  url: string
  type?: string
}

const Item = (props: ItemProps) => {
  const { src, label, url, type, ...rest } = props
  const [modalVisible, modalAction] = useVisibility()

  let path = ''
  if (type === '0' || type === '2') {
    path = url
  } else if (type === '1') {
    let [iosPath, androidPath] = url.split(',')
    androidPath = androidPath.replace('android;', '')
    iosPath = iosPath.replace('ios;', '')
    if (isMobile) {
      path = isIOS ? iosPath : ''
      path = isAndroid ? androidPath : ''
    } else {
      path = ''
    }
  }

  const onNotSupportAppClick = useCallback(() => {
    modalAction.show()
  }, [modalAction])

  return (
    <>
      <Modal visible={modalVisible} onCancel={modalAction.hide} hideFooter>
        <div>
          <Text size={30} type="Bold">
            Cannot Open
          </Text>
          <Text>Cannot Open this app on Browser</Text>
          <Button onClick={modalAction.hide}>OK</Button>
        </div>
      </Modal>
      {path !== '' ? (
        <Layout {...rest}>
          <a href={`${path}`} target="_blank" rel="noreferrer">
            <CustomImage src={src || MaskImage} />
            <Text size={16} style={{ textAlign: 'center', marginTop: 8 }}>
              {label}
            </Text>
          </a>
        </Layout>
      ) : (
        <Layout {...rest}>
          <div onClick={onNotSupportAppClick}>
            <CustomImage src={src || MaskImage} />
            <Text size={16} style={{ textAlign: 'center', marginTop: 8 }}>
              {label}
            </Text>
          </div>
        </Layout>
      )}
    </>
  )
}

const MyApp = () => {
  const { data: user } = useGetUser()
  const { data } = useGetUserApps({ userId: user?.userId })
  const { t } = useTranslation()
  const history = useHistory()
  const [showPrivacy, setShowPrivacy] = useState(false)

  const onAddAppClick = useCallback(() => {
    history.push(paths.app())
  }, [history])


  const handlePrivacy = () => {
    setShowPrivacy(false)
  }
  return (
    <CategoryGroup label={t('home.category.myApp.title')}>
      {data?.map((item: UserAppShortcut) => {
        return (
          <Item
            key={item.id}
            src={item.appShortcut.logoPath}
            label={item.appShortcut.name}
            url={item.appShortcut.url}
            type={item.appShortcut.type}
          />
        )
      })}

    </CategoryGroup>
  )
}

export default MyApp
