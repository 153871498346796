import { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios'

export enum ContentType {
  XFORM = 'application/x-www-form-urlencoded',
  JSON = 'application/json',
  FORMDATA = 'multipart/form-data',
}

const customClient = (
  method: any, // รอเช็ค type อีกที
  url: string,
  data: any,
  option?: AxiosRequestConfig & { contentType: ContentType },
) => {
  const { contentType = ContentType.XFORM, ...restOption } = option || {}
  return method(url, data, {
    headers: {
      'Content-Type': contentType,
    },
    ...restOption,
    withCredentials: true
  })
}

export const createMethod = (
  client: AxiosInstance,
  apiWraper: (method: Promise<AxiosResponse>) => Promise<any>,
) => ({
  get: <T extends unknown>(url: string, param?: any): Promise<T> =>
    apiWraper(client.get(url, { params: param })),
  put: <T extends unknown>(
    url: string,
    data?: any,
    option?: AxiosRequestConfig & { contentType: ContentType },
  ): Promise<T> => apiWraper(customClient(client.put, url, data, option)),
  post: <T extends unknown>(
    url: string,
    data?: any,
    option?: AxiosRequestConfig & { contentType: ContentType },
  ): Promise<T> => apiWraper(customClient(client.post, url, data, option)),
  patch: <T extends unknown>(
    url: string,
    data?: any,
    option?: AxiosRequestConfig & { contentType: ContentType },
  ): Promise<T> => apiWraper(customClient(client.patch, url, data, option)),
  delete: <T extends unknown>(url: string, param?: any): Promise<T> =>
    apiWraper(client.delete(url, { params: param })),
})
