import React, { ReactNode, useMemo, useState, useCallback, useEffect } from 'react'
import { Tabs } from 'antd'
import styled from '@emotion/styled'

import Text from './Text'
import Select from './Select'
import color from '../../constant/colors'
import Image from '../../components/common/Image'
import { isNumber } from 'lodash'
import config from '../../config'

const TabbarSelect = styled(Select)`
  margin-bottom: 16px;
`
const TabsLayout = styled.div`
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-nav {
    margin-bottom: 24px;
  }

  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
  }

  .ant-tabs-ink-bar {
    background: ${color.PRIMARY} !important;
  }
`

interface SubTabProps {
  obj: any
  currentActiveKey: string | number
}
const SubTab = ({ obj, currentActiveKey }: SubTabProps) => {
  const { icon, title, name, titleSize = 20, imageIcon } = obj
  const isActive = useMemo(() => {
    if (obj.key === currentActiveKey) {
      return true
      // { type: 'Bold', color: PRIMARY }
    } else {
      return false
      // { type: 'Medium' }
    }
  }, [obj.key, currentActiveKey])
  const tabWithIcon = useMemo(() => {
    return imageIcon ? (
      <Text
        size={titleSize}
        type={isActive ? 'Bold' : 'Medium'}
        color={isActive ? color.PRIMARY : undefined}
      >
        <span>
          <Image
            src={`${config.apiHost}${imageIcon}`}
            style={{ width: 40, height: 40, marginRight: 10 }}
          />
          {name}
        </span>
      </Text>
    ) : (
      <Text
        size={titleSize}
        type={isActive ? 'Bold' : 'Medium'}
        color={isActive ? color.PRIMARY : undefined}
      >
        <span>
          {icon ? icon : null}
          {name}
        </span>
      </Text>
    )
  }, [icon, imageIcon, isActive, name, titleSize])
  return (
    <Tabs.TabPane key={obj.key} tab={tabWithIcon}>
      {obj.page}
    </Tabs.TabPane>
  )
}

interface TabsProps {
  dataSource: {
    title: string
    page?: ReactNode
    key: number | string
    icon?: ReactNode
    imageIcon?: string
  }[]
  mode?: 'select'
  activeKey?: string | number
  onChange?: (key: string) => void
}
const BaseTabs = (props: TabsProps) => {
  const { dataSource = [], mode = 'default', activeKey, onChange, ...restProps } = props
  dataSource.forEach((obj) => (obj.key = obj.key.toString()))
  const [currentActiveKey, setCurrentActiveKey] = useState(
    isNumber(activeKey) ? activeKey : dataSource[0].key,
  )
  const onTabChange = useCallback(
    (key) => {
      setCurrentActiveKey(key)
      onChange && onChange(key)
    },
    [onChange],
  )

  const SelectModeTabBar = useMemo(() => {
    if (mode === 'select') {
      console.log('currentActiveKey', currentActiveKey)
      return () => (
        <TabbarSelect
          options={dataSource.map((data) => ({ label: data.title, value: data.key }))}
          // value={currentActiveKey}
          value={dataSource[Number(currentActiveKey)].title}
          onSelect={onTabChange}
          style={{ marginBottom: 16 }}
        />
      )
    }
  }, [mode, currentActiveKey, onTabChange, dataSource])

  useEffect(() => {
    setCurrentActiveKey((prevValue) => {
      return activeKey && activeKey !== prevValue ? activeKey : prevValue
    })
  }, [activeKey])

  return (
    <TabsLayout>
      <Tabs
        activeKey={currentActiveKey.toString()}
        onChange={onTabChange}
        renderTabBar={SelectModeTabBar}
        centered
        tabBarGutter={24}
        {...restProps}
      >
        {dataSource.map((obj) => SubTab({ obj, currentActiveKey }))}
      </Tabs>
    </TabsLayout>
  )
}

export default BaseTabs
