import dayjs, { Dayjs } from 'dayjs'
import { ReactNode } from 'react'
import { UserType } from '../user/user-typed'

export type DigitalNews = {
  id: number
  title: string
  content: any
  thumbnailPath: string
  isHighLight: boolean
  digitalNewsPublishDate: number
  digitalNewsExpireDate: number
  createDate: number
  createBy: string
  updateDate: string
  updateBy: string
  type: TypeNews
  digitalNewsType: 'KM' | 'Corporate' | 'DIGITAL' | string
  rewardCoin: number
  rewardCoinExpireDate: number
  rewardCoinAmountLeft: any
  attachFilesPath: any
  isDelete: false
  isPinned: true
  subType: string
  maximumRegister: any
  link: string
  contentName: any
  digitalNewsHeaderImagesList: []
  digitalNewsVoteList: any
  digitalNewsQuizList: any
}

export enum TypeNews {
  NORMAL = 'NORMAL',
  INFORM = 'INFORM',
  QUIZ = 'QUIZ',
  VOTE = 'VOTE',
  REGISTER = 'REGISTER',
}

export type HeaderImage = {
  id: number
  digitalNewsId: number
  imagePath: string
  createDate: Dayjs
}

export type NewsType = {
  digitalNews: DigitalNews
  isRead: false
  isInform: false

  // mock images
  newsImages: {
    src: string
    title?: string
    onClick?: () => void
  }[]
}

export type CommentParam = {
  userId: number
  digitalNewsId: number
  isInputComment?: boolean
}

export type CommentType = {
  id: number
  digitalNewsId: number
  userId: number
  comment: string
  imagePath: string
  createDate: Dayjs
  user: Pick<
    UserType,
    | 'userId'
    | 'empId'
    | 'email'
    | 'profileImagePath'
    | 'position'
    | 'firstName'
    | 'lastName'
    | 'firstNameTH'
    | 'lastNameTH'
    | 'phoneNumber'
    | 'point'
    | 'unavailable'
    | 'isRequirePassword'
    | 'coverImagePath'
  >
}

export type MockCommentWithPaging = ResponseWithPaging<CommentType[]>

type CommentPage = Array<CommentType>[]

export type CommentListType = Array<CommentPage>[]

export type CreateCommentParams = {
  digitalNewsId: number
  userId: number
  comment: string
  image?: FormDataValue
}

export type CreateCommentResponse = CommentType
export interface GetNewsDetailProps {
  id: string | number
  userId: string | number | undefined
}

export interface ReadNewsDetailProps {
  userId: number | undefined
  digitalNewsId: string
  subType: string
}

export interface SentQuizAnswerProps {
  userId: number | undefined
  digitalNewsId: string
  id: any
}

export interface VoteProps {
  digitalNewsId: string
  userId: number | undefined
  voteIds: any
}

export type NewsWithPaging = ResponseWithPaging<NewsType[]>

export type HilightNewsProps = {
  userId?: number
}

export type GetNewsProps = {
  size: number
  userId?: number
  category: string
  subCategory: string
}

export type NewsCategoryType = {
  id: number
  name: string
  logoPath?: string
  createDate?: Dayjs
  createBy?: string
  updateDate?: Dayjs
  updateBy?: string
  icon?: ReactNode
}

export type GetNewsDetailResponse = {
  isRead: boolean
  isInform: boolean
  digitalNews: DigitalNews
}

export type NewsDetailResponse = {
  id: number
  userId: number
  isRead: boolean
  createDate: number
  refId: number
  type: string
  isInform: boolean
  isCorrect: boolean
  user: {
    userId: number
    empId: string
    email: string
    profileImagePath: string
    position: string
    firstName: string
    lastName: string
    firstNameTH: string
    lastNameTH: string
    phoneNumber: string
    point: number
    unavailable: boolean
    isRequirePassword: boolean
    coverImagePath: string
  }
}

export type GetRecommendNews = {
  digitalNews: DigitalNews
  isRead: boolean
  isInform: boolean
}[]
