import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useGetUser } from '../../../services/user/user-query'
import { mobile, tablet, useScreen } from '../../../utils/responsive-helper'
import Text from '../../../components/common/Text'
import Image from '../../../components/common/Image'
import PointLabel from '../../../components/common/PointLabel'
import Point from '../../../components/common/Point'
import color from '../../../constant/colors'

const ProfileLayout = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: inherit;
  margin-bottom: 32px;

  .left {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
  }
`

const Profile = () => {
  const { t } = useTranslation()
  const { data: user } = useGetUser()
  const { isMobile } = useScreen()

  return (
    <ProfileLayout>
      <div className='left'>
        <Text size={32} type="Bold">{`${user?.firstName} ${user?.lastName}`}</Text>
      </div>
      <div className='right'>
        {/* <Point /> */}
      </div>
    </ProfileLayout>
  )
}

export default Profile