import { Redirect, Route, Switch } from 'react-router'

import * as paths from '../constant/path'
import AppLayout from '../components/app-layout'
import Home from '../pages/Home'
import Main from '../pages/Main'
import News from '../pages/News'
import NewsDetail from '../pages/NewsDetail'
import About from '../pages/About'
import TermAndCondition from '../pages/TermAndCondition'
import Contact from '../pages/Contact'
import Profile from '../pages/Profile'
import AppShortcut from '../pages/AppShortcut'
import Notification from '../pages/Notification'
import EnrollmentDetail from '../pages/Enrollment/EnrollmentDetail'
import EClaimDetail from '../pages/EClaimDetail'
import EClaimSummary from '../pages/EClaimDetail/EClaimSummary'
import EClaimComplete from '../pages/EClaimDetail/EClaimComplete'


import EClaimDetailDisaster from '../pages/EClaimDetailDisaster'
import EClaimSummaryDisaster from '../pages/EClaimDetailDisaster/EClaimDisasterSummary'
import EClaimCompleteDisaster from '../pages/EClaimDetailDisaster/EClaimDisasterComplete'

import { isScg } from '../utils/helper'
import EClaimHistoryDetail from '../pages/EclaimDetailHistory'
import EClaimHistory from '../pages/EClaimHistory'

import ComingSoon from '../pages/ComingSoon'

export const ProtectedRoute = () => {
  return (
    <AppLayout>
      <Switch>
        <Redirect exact from="/" to={paths.main()} />
        {/* เพิ่ม route ตรงนี้ ไม่ต้องใส่ exact */}
        <Route path={paths.notification()} component={Notification} />
        <Route path={paths.app()} component={AppShortcut} />
        <Route path={paths.profile()} component={Profile} />
        <Route path={paths.about()} component={About} />
        <Route path={paths.contact()} component={Contact} />
        <Route path={paths.termAndCondition()} component={TermAndCondition} />
        <Route path={paths.app()} component={AppShortcut} />
        <Route path={paths.home()} component={Home} />
        <Route path={paths.newsDetails()} component={NewsDetail} />
        <Route path={paths.news()} component={News} />

        {/* E-Claim */}
        <Route path={paths.eClaimCompleted()} component={EClaimComplete} />
        <Route path={paths.eClaimSummary()} component={EClaimSummary} />
        <Route path={paths.eClaimDetail()} component={EClaimDetail} />

        <Route path={paths.eClaimCompletedDisaster()} component={EClaimCompleteDisaster} />
        <Route path={paths.eClaimSummaryDisaster()} component={EClaimSummaryDisaster} />
        <Route path={paths.eClaimDetailDisaster()} component={EClaimDetailDisaster} />

        <Route path={paths.eClaimHistoryDetail()} component={EClaimHistoryDetail} />

        <Route path={paths.eClaimHistory()} component={EClaimHistory} />
        {/* Enrollment */}
        <Route path={paths.enrollment()} component={EnrollmentDetail} />

        <Route path={paths.home()} component={Home} />
        <Route path={paths.main()} component={Main} />
        <Redirect to={paths.notFound()} />
      </Switch>
    </AppLayout>
  )
}
