import styled from '@emotion/styled'
import Breadcrumb from '../../../components/common/Breadcrumb'
import Card from '../../../components/common/Card'
import iconUser from '../../../assets/images/icons-user-green-32-px.svg'
import iconUserGroup from '../../../assets/images/icons-user-group-green-32-px.svg'
import iconChange from '../../../assets/images/icons-change-green-24-px.svg'
import Text from '../../../components/common/Text'
import Welfare from './Welfare'
import FooterContent from '../../../components/FooterContent'
import { useScreen } from '../../../utils/responsive-helper'
import Button from '../../../components/common/Button'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as paths from '../../../constant/path'
import { useHistory } from 'react-router'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import {
  useGetEnrollmentPeriod,
  useGetEnrollmentNextPeriod,
} from '../../../services/enrollment/enrollment-query'
import { useGetUser } from '../../../services/user/user-query'
import { convertDateFormatTH, DateFormat } from '../../../utils/dateFormat'
import { useGetEnrollmentYear } from '..'
import { isScg, useRouter, useCanEditable } from '../../../utils/helper'
import color from '../../../constant/colors'
import { PeriodTabsLayout, PeriodTabButton } from '../Benefits/EditBenefits/shared'
import EnrollmentDetailRoute from './EnrollmentDetailRoute'
import { useDispatch } from 'react-redux'
import { setEnrollment } from '../../../redux-store/enrollment-reducer'

const Layout = styled.div`
  > * {
    margin-top: 24px;
  }
  text-align: center;
`

const EnrollmentDetailLayout = () => {
  const dispatch = useDispatch()
  const { query } = useRouter()
  const history = useHistory()
  // const { data: user } = useGetUser()
  // const { lastEnrollDate: lastEnrollDateUser = 0 } = user || {}
  const { data: enrollmentPeriod, isLoading: isLoadingPresentPeriod } = useGetEnrollmentPeriod()
  const {
    startDate: presentStartDate = 0,
    endDate: presentEndDate = 0,
    benefitYearId: benefitYearPresentId,
    benefitYear: benefitYearPresent = 0,
  } = enrollmentPeriod || {}
  // const {
  //   data: enrollmentNextPeriod,
  //   isLoading: isLoadingNextPeriod,
  //   isError: isErrorNextPeriod,
  // } = useGetEnrollmentNextPeriod()
  // const {
  //   startDate: nextStartDate = 0,
  //   endDate: nextEndDate = 0,
  //   benefitYearId: benefitYearNextId = 0,
  //   benefitYear: benefitYearNext = 0,
  // } = enrollmentNextPeriod || {}

  // const [benefitYearId, setBenefitYearId] = useState(Number(query?.benefitYearId))
  const [benefitYearId, setBenefitYearId] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const startDate = useMemo(() => {
    let startDate = 0
    switch (benefitYearId) {
      case benefitYearPresentId:
        startDate = presentStartDate
        break
      // case benefitYearNextId:
      //   startDate = nextStartDate
      //   break
      default:
        startDate = presentStartDate
        break
    }

    return startDate
  }, [benefitYearId, benefitYearPresentId, presentStartDate])

  const endDate = useMemo(() => {
    let endDate = 0
    switch (benefitYearId) {
      case benefitYearPresentId:
        endDate = presentEndDate
        break
      // case benefitYearNextId:
      //   endDate = nextEndDate
      //   break
      default:
        endDate = presentEndDate
        break
    }

    return endDate
  }, [benefitYearId, benefitYearPresentId, presentEndDate])

  const benefitYear = useMemo(() => {
    let benefitYear = 0
    switch (benefitYearId) {
      case benefitYearPresentId:
        benefitYear = benefitYearPresent
        break
      // case benefitYearNextId:
      //   benefitYear = benefitYearNext
      //   break
      default:
        benefitYear = benefitYearPresent
        break
    }

    return benefitYear + 543
  }, [benefitYearId, benefitYearPresentId, benefitYearPresent])

  const { enrollmentYear } = useGetEnrollmentYear(startDate)

  const canEditable = useCanEditable(startDate, endDate)

  useEffect(() => {
    if (benefitYearPresentId) {
      setBenefitYearId(benefitYearPresentId)
    }
  }, [benefitYearPresentId])

  useEffect(() => {
    dispatch(
      setEnrollment({
        benefitYearId: benefitYearId,
        enrollmentYear: enrollmentYear.toString(),
        startDate: startDate,
        endDate: endDate,
        benefitYear: benefitYear,
      }),
    )
    setIsLoading(true)
  }, [benefitYearId, benefitYear, startDate, endDate, enrollmentYear, dispatch])

  const onChangeBenefitYear = useCallback(
    (e) => {
      if (canEditable) {
        history.push(
          paths.enrollment(),
        )
      }
      setBenefitYearId(e.target.value)
    },
    [benefitYearId, endDate, history],
  )

  return (
    <>
      {!isLoadingPresentPeriod && isLoading && (
        <Layout>
          {/* <PeriodTabsLayout
            defaultValue={benefitYearId}
            buttonStyle="solid"
            onChange={onChangeBenefitYear}
            isNextPeriod={false}
          > */}
          {/* <PeriodTabButton value={benefitYearPresentId}>
              แผนประกันปี {benefitYearPresent + 543}
            </PeriodTabButton> */}
          {/* {!isErrorNextPeriod && (
              <PeriodTabButton value={benefitYearNextId}>
                แผนประกันปี {benefitYearNext + 543}
              </PeriodTabButton>
            )} */}
          {/* </PeriodTabsLayout> */}
          <EnrollmentDetailRoute />
        </Layout>
      )}
    </>
  )
}

export default EnrollmentDetailLayout
