import styled from '@emotion/styled'
import ImageForeground from '../../assets/images/base/undraw-social-sharing-ibwq.svg'
import ImageBackground from '../../assets/images/base/free-i-phone-xr-fill-1-mask.svg'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import LoginForm from './LoginForm'
import LoginAD from './LoginAD'
import { mobile, iphoneXLandscape, tablet, useScreen } from '../../utils/responsive-helper'
import { useState } from 'react'
import { UserLoginParam } from '../../services/auth/auth-types'
import { useLogin, useLoginAD, useLoginType } from '../../services/auth/auth-query'
import { useTranslation } from 'react-i18next'
import { useVisibility } from '../../utils/custom-hook'
import Modal from '../../components/common/Modal'
import Button from '../../components/common/Button'
import { AccountInfo } from '@azure/msal-browser'
import DebugLogin from './DebugLogin'
import config from '../../config'
import { Divider } from 'antd'
import { WHITE, PRIMARY } from '../../constant/colors/scg-colors'

const ssoLogin: any = config.ssoLogin

const Layout = styled.div`
  display: flex;
  height: 100vh;
  > div {
    width: 50%;
  }

  ${mobile}, ${iphoneXLandscape} {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`
const FormLayout = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile}, ${iphoneXLandscape} {
    margin-top: 102px;
  }
`
const ImageLayout = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${ImageBackground});
  background-size: cover;
  ${mobile}, ${iphoneXLandscape} {
    background: unset;
    margin: 48px 0 102px;
  }
  ${tablet} {
    > .text {
      zoom: 0.8;
    }
  }
`
const Image = styled.img`
  width: 100%;
  max-width: 574px;
  margin-bottom: 48px;
`
const ButtonHide = styled.button`
  cursor: pointer;
  height: 10px;
  border: none;
  background: none;
`

// const Logo = styled.img`
//   width: calc(100% - 22px);
// `

const initialValues = {
  username: 'admin@beliveasia.com',
  password: '123456',
}

const Login = (props: any) => {
  const { isMobile, isIphoneXLandscape } = useScreen()
  const [modalVisible, modalAction] = useVisibility()
  const [modalLoginVisible, modalLoginAction] = useVisibility()
  const [errorMessage, setErrorMessage] = useState('')
  const [currentUser, setCurrentUser] = useState<AccountInfo>()
  // const { data } = useLoginType()
  const { t } = useTranslation()

  const { mutate: login } = useLoginAD()

  // env http://52.237.99.252:8080
  // const onSubmitAD = useCallback(
  //   (token, user) => {
  //     const dataNew: UserLoginParamAD = {
  //       username: user,
  //       tokenId: token,
  //       app: 'IPOWER',
  //     }
  //     login(dataNew)
  //   },
  //   [login],
  // )

  const { mutate: loginDebug, isLoading } = useLogin({
    onError: (e) => {
      if (e.message) {
        setErrorMessage(e.message)
        modalAction.show()
      }
    },
  })

  return (
    <Layout>
      <Modal visible={modalVisible} onCancel={modalAction.hide} hideFooter>
        <div
          style={{
            marginTop: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text>{errorMessage}</Text>
          <Button style={{ width: 300, margin: '50px 0 0 0' }} onClick={modalAction.hide}>
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal visible={modalLoginVisible} onCancel={modalLoginAction.hide} hideFooter>
        <LoginForm
          onSubmit={(form: UserLoginParam) => {
            const { username, password } = form
            loginDebug({
              username,
              password,
            })
          }}
          loading={isLoading}
          // initialValues={['development'].includes(config.appMode) ? initialValues : undefined}
          error={{ password: 'error' }}
        />
      </Modal>

      <FormLayout>
        <div>
          {/* <LoginAD loading={true} onSubmit={onSubmitAD} /> */}
          <Button style={{ width: 300, margin: '50px 0 0 0' }} onClick={() => window.open(ssoLogin, "_self")}>
            {t('login.button.submitDentsu')}
          </Button>
          {/* <Divider plain style={{ marginBottom: 0 }}>หรือ</Divider>
          <Button fontSize={14} fontColor={PRIMARY} background={WHITE} style={{ width: 300, border: 'none', boxShadow: 'none' }} onClick={modalLoginAction.show}>
            เข้าสู่ระบบโดยใช้ Email
          </Button> */}
        </div>
        {
          // ['https://app-main-1630.azurewebsites.net', 'https://app-main-1631.azurewebsites.net'].includes(config.apiHost) ? (
          // ['http://uatapi.dentsujoy.com', 'https://app-main-1630.azurewebsites.net', 'https://app-main-1631.azurewebsites.net'].includes(config.apiHost) ? (

          // ) : (
          //   <Button style={{ width: 300, margin: '50px 0 0 0' }} onClick={() => window.open(ssoLogin, "_self")}>
          //     {t('login.button.submit')}
          //   </Button>
          // )
        }


        {/* {['development'].includes(config.appMode) && <DebugLogin />} */}
      </FormLayout>
      <ImageLayout>
        <Image src={ImageForeground} />
        {!isMobile && !isIphoneXLandscape && (
          <>
            <Text className="text" type="Bold" size={56} color={color.SECONDARY}>
              {t('login.content.flexBenefits')}
            </Text>
            <Text className="text" type="Bold" size={26} color={color.FONT_BLACK}>
              {t('login.content.content1')}
            </Text>
            <Text className="text" type="Bold" size={26} color={color.FONT_BLACK}>
              {t('login.content.content2')}
            </Text>
            <Text className="text" type="Bold" size={26} color={color.FONT_BLACK}>
              {t('login.content.content3')}
            </Text>
            <ButtonHide onClick={modalLoginAction.show}>{' '}</ButtonHide>
          </>
        )}
      </ImageLayout>
    </Layout>
  )
}

export default Login
