import React from 'react'
import ReactDOM from 'react-dom'
import { CSSProperties, useEffect } from 'react'

type FooterContentProps = {
  style?: CSSProperties
  className?: string
}

const FooterContent: React.FC<FooterContentProps> = (props) => {
  useEffect(() => {
    const footerElement = document.getElementById('footer-content')
    if (footerElement) {
      const { children, ...rest } = props
      const node = React.createElement('div', rest, children)
      ReactDOM.render(node, footerElement)
    }
    return () => {
      const footerElement = document.getElementById('footer-content')
      if (footerElement) {
        ReactDOM.render(React.createElement('div', {}), footerElement)
      }
    }
  })
  return null
}

export default FooterContent
