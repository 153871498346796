import { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import color from '../../constant/colors'
import { mobile, useScreen } from '../../utils/responsive-helper'
import Card from '../../components/common/Card'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import Badge from '../../components/common/Badge'
import { Tooltip } from 'antd'
import * as paths from '../../constant/path'
import { useHistory } from 'react-router'
import { useReadNotification } from '../../services/notification/notification-query'
import config from '../../config'

const CustomStyleCard = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;
  max-height: 125px;
`

const LayoutImage = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 24px;
  margin-right: 24px;

  .ant-badge-dot {
    top: 8px;
    right: 8px;
  }

  ${mobile} {
    margin-top: 32px;
  }
`

const LayoutContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: left;
  margin-top: 22px;
`

const LayoutBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${mobile} {
    max-width: 150px;
  }
`

const LayoutDate = styled.div`
  margin-right: 24px;
  justify-content: flex-end;

  ${mobile} {
    margin-top: 5px;
  }
`

const CustomStyleImage = styled(Image)`
  border-radius: 50%;
  width: 72px;
  height: 72px;
`

type NotificationCardProps = {
  id: number
  badge: boolean
  imagePath: string
  title: string
  description: string
  date: string
  newsId: number
  onCardClick: () => void
}

const NotificationCard = (props: NotificationCardProps) => {
  const { isMobile } = useScreen()
  const { onCardClick, badge, imagePath = '', title, description, date, newsId } = props

  return (
    <CustomStyleCard onClick={onCardClick}>
      <LayoutImage>
        <Badge dot={!badge}>
          <CustomStyleImage src={`${config.apiHost}${imagePath}`} />
        </Badge>
      </LayoutImage>
      <LayoutContent>
        <LayoutBody>
          <Text ellipsis maxLine={1} size={20} type="Medium">
            {title}
          </Text>
          <Tooltip title={description}>
            <Text ellipsis maxLine={2} size={18} type="Medium" color={color.LIGHT_GRAY}>
              {description}
            </Text>
          </Tooltip>
        </LayoutBody>
        <LayoutDate>
          {isMobile ? (
            <Text ellipsis maxLine={1} size={18} type="Medium" color={color.LIGHT_GRAY}>
              {date}
            </Text>
          ) : (
            <Text size={18} type="Medium" color={color.LIGHT_GRAY}>
              {date}
            </Text>
          )}
        </LayoutDate>
      </LayoutContent>
    </CustomStyleCard>
  )
}

export default NotificationCard
