import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import Text from '../../components/common/Text'
import color from '../../constant/colors'

const PointLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  border: 2px solid #E87722;
  border-radius: 6px;
  .top {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
`
const PointCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  background: ${color.WHITE};
  padding: 4px 8px;
  border-radius: 8px;
`

const Point = () => {
  const { t, i18n } = useTranslation()

  return (
    <PointLayout>
      <div className='top'>
        <PointCard>
          <Text size={18} type="SemiBold" color='#E87722'>{t('main.history')}</Text>
        </PointCard>
      </div>
    </PointLayout>
  )
}

export default Point